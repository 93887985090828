<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-alert type="error" dense v-if="errorAlertText">
        {{ errorAlertText }}
      </v-alert>
      <v-alert type="success" dense v-if="successAlertText">
        {{ successAlertText }}
      </v-alert>
      <v-alert type="warning" dense v-if="is_exchange">
        This is an exchanging package. You may modify only the 'Description' and
        'Item estimated value' for the packages being exchanged.
      </v-alert>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="backToList"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Back To List</v-btn
          >
        </v-col>
      </v-row>
      <v-form @submit.prevent lazy-validation autocomplete="off" ref="form">
        <v-card>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Package Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col cols="12" sm="12">
                          <label for="pickup-city"
                            >Pickup City
                            <span class="text-danger">*</span></label
                          >
                          <v-combobox
                            :disabled="disableAllFields"
                            id="pickup-city"
                            v-model="pickup_city"
                            autocomplete="nope"
                            :items="pickupCities"
                            item-text="name"
                            item-value="id"
                            label="PickUp City ( required )"
                            solo
                            dense
                            @change="setPickCityId()"
                            :rules="[(v) => !!v || 'Pick up city is required']"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="delivery-city"
                            >Delivery City
                            <span class="text-danger">*</span></label
                          >
                          <v-combobox
                            id="delivery-city"
                            :disabled="disableAllFields"
                            v-model="delivery_city"
                            :items="deliveryCities"
                            autocomplete="nope"
                            item-text="name"
                            item-value="id"
                            label="Delivery City ( required )"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Delivery city is required']"
                            @change="setDeliveryCityId()"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6" class="pb-0">
                          <label for="weight"
                            >Estimated Weight (Kg)
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            ref="weightField"
                            id="weight"
                            step="1"
                            min="1"
                            type="number"
                            label="Estimated Weight (kg) ( required )"
                            v-model="form.weight"
                            @change="weightChange()"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Weight is required',
                              (v) =>
                                (v && v >= 1) || 'Minimum weight should be 1Kg',
                              (v) =>
                                Number.isInteger(Number(v)) ||
                                'Decimal places are not allowed',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                          <label for="pickup-date"
                            >Pickup Date
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="pickup-date"
                            label="Date ( required )"
                            type="date"
                            v-model="form.package_date"
                            @input="dateChange"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Date is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" class="pt-0">
                          <v-checkbox
                            :disabled="disableAllFields"
                            v-model="form.same_day"
                            label="Request for same day delivery"
                            color="blue"
                            :value="1"
                            hide-details
                            @change="calTotal"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="description">Description </label>
                          <v-textarea
                            id="description"
                            v-model="form.description"
                            solo
                            name="input-7-4"
                            label="Items Description ( Optional )"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Payment Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <label for="pay-by"
                            >Pay By <span class="text-danger">*</span></label
                          >
                          <v-select
                            :disabled="disableAllFields"
                            id="pay-by"
                            v-model="form.pay_by"
                            :items="payBy"
                            label="Pay By"
                            item-value="id"
                            item-text="name"
                            @change="changeValuePayBy()"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Pay By is required']"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <label for="payment-type"
                            >Payment Type
                            <span class="text-danger">*</span></label
                          >
                          <v-select
                            :disabled="disableAllFields"
                            id="payment-type"
                            v-model="form.payment_type"
                            :items="paymentTypes"
                            label="Payment Type"
                            item-value="id"
                            item-text="name"
                            @input="calTotal"
                            dense
                            solo
                            :rules="[(v) => !!v || 'Payment Type is required']"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row v-if="form.pay_by == 2">
                        <v-col cols="12" sm="12">
                          <label for="package-total"
                            >Package Net Total
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            type="number"
                            @input="changePackageTotal()"
                            id="package-total"
                            label="Package Total ( required )"
                            v-model="form.total_amount"
                            clearable
                            solo
                            :rules="[
                              (v) =>
                                !!v ||
                                is_exchange ||
                                'Package total is required',
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <label for="estimated-value"
                            >Item Estimated Value
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            type="number"
                            id="estimated-value"
                            label="Item Estimated Value ( required )"
                            v-model="form.item_value"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Estimated value is required',
                              (v) =>
                                (v && v > 0) || 'Estimated value is invalid',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <label for="invoice-number"
                            >Customer Invoice Number</label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            type="text"
                            id="invoice-number"
                            label="Invoice Number ( optional )"
                            v-model="form.invoice_number"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          v-if="form.payment_type == '3'"
                          cols="12"
                          sm="12"
                        >
                          <h4>Bank Details</h4>
                          <p class="address_line">
                            Bank &nbsp;&nbsp;&nbsp;&nbsp;: Sampath Bank
                            <button @click="copyText('Sampath Bank')">
                              &nbsp; &nbsp;<v-icon>mdi-content-copy</v-icon>
                            </button>
                          </p>
                          <p class="address_line">
                            Acc &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 1069 1402
                            9547
                            <button @click="copyText('106914029547')">
                              &nbsp; &nbsp;<v-icon>mdi-content-copy</v-icon>
                            </button>
                          </p>
                          <p class="address_line">
                            Branch : Homagama branch
                            <button @click="copyText('Homagama')">
                              &nbsp; &nbsp;<v-icon>mdi-content-copy</v-icon>
                            </button>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="disableAllFields"
                        align="center"
                        class="summery-card"
                      >
                        <v-col cols="6" sm="6">
                          <h4>Courier Charges : 0</h4>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <h4>Net Total : 0</h4>
                        </v-col>
                      </v-row>
                      <v-row v-else align="center" class="summery-card">
                        <v-col cols="6" sm="6">
                          <h4>Courier Charges : {{ totals.courier_charge }}</h4>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <h4>Net Total : {{ totals.total_amount }}</h4>
                        </v-col>

                        <v-col
                          v-if="form.pay_by == 2"
                          cols="12"
                          sm="12"
                          class="earning-text"
                        >
                          <div>
                            <h4
                              :class="[
                                totals.balance > 0
                                  ? 'text-success'
                                  : 'earning-text-danger',
                              ]"
                            >
                              Your Earning : {{ totals.balance }}
                            </h4>
                            <small class="earning-text-small"
                              >*
                              <span v-if="totals.balance < 0"
                                >This amount will be credited to your
                                account.</span
                              >
                              You can adjust "Your Earning" by changing the
                              <label class="mount-clickable" for="package-total"
                                ><b>"Package Net Total"</b></label
                              >
                              field.</small
                            >
                            <br />
                            <small class="earning-text-small"
                              >* Courier Charges may change when we figure out
                              the actual weight of the package.</small
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12">
                      <v-card elevation="2">
                        <v-container
                          :class="[form.has_exchange ? 'exchange-card' : '']"
                          fluid
                        >
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-checkbox
                                :disabled="disableAllFields"
                                v-model="form.has_exchange"
                                label="Has Exchange Package"
                                color="blue"
                                @change="changeHasExchange"
                                :value="1"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row v-if="form.has_exchange">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <label for="exchanging-item-value"
                                    >Exchanging Item Value
                                    <span class="text-danger">*</span></label
                                  >
                                  <v-text-field
                                    :disabled="disableAllFields"
                                    type="number"
                                    id="exchanging-item-value"
                                    label="Item Estimated Value ( required )"
                                    v-model="form.exchange_item_value"
                                    clearable
                                    solo
                                    :rules="[
                                      (v) =>
                                        !!v ||
                                        !form.has_exchange ||
                                        'Item value is required',
                                      (v) =>
                                        (v && v > 0) || 'Item value is invalid',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <label for="exchange-description"
                                    >Exchange Description
                                  </label>
                                  <v-textarea
                                    :disabled="disableAllFields"
                                    id="exchange-description"
                                    v-model="form.exchange_description"
                                    solo
                                    name="input-7-4"
                                    label="Exchange Description ( Optional )"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Receiver Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="receiver-name"
                            >Receiver Name
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="receiver-name"
                            label="Receiver Name ( required )"
                            v-model="form.receiver_name"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'First name is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="receiver-address"
                            >Receiver Address
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="receiver-address"
                            label="Receiver address ( required )"
                            v-model="form.receiver_address"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Address is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="receiver-contact"
                            >Receiver Contact Numbers
                            <span class="text-danger">*</span></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            class="first-contact"
                            id="receiver-contact"
                            label="SMS Contact ( required )"
                            v-model="form.receiver_contact"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Contact number is required',
                            ]"
                          ></v-text-field>
                          <v-text-field
                            :disabled="disableAllFields"
                            id="receiver-additional-contact"
                            label="Additional Contact ( optional )"
                            v-model="form.receiver_additional_contact"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <span
                            class="additional-toggle-btn"
                            v-if="showReceiverAdditional"
                            @click="toggleReceiverAdditional"
                            ><v-icon color="blue" small
                              >mdi mdi-minus-circle-outline</v-icon
                            >
                            Hide additional fields</span
                          >
                          <span
                            class="additional-toggle-btn"
                            v-else
                            @click="toggleReceiverAdditional"
                            ><v-icon color="blue" small
                              >mdi mdi-plus-circle-outline</v-icon
                            >
                            Show additional fields</span
                          >
                          <small
                            class="additional-btn-small"
                            v-if="!receiverAdditionalEmpty"
                          >
                            (Can not hide when values are not empty)</small
                          >
                        </v-col>
                      </v-row>
                      <v-row class="additional-card">
                        <v-col cols="12" sm="12" v-show="receiver_additional">
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="receiver-landmark"
                                >Receiver Landmark</label
                              >
                              <v-text-field
                                :disabled="disableAllFields"
                                id="receiver-landmark"
                                label="Receiver Landmark ( optional )"
                                v-model="form.receiver_landmark"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="receiver-fax">Receiver Fax</label>
                              <v-text-field
                                :disabled="disableAllFields"
                                id="receiver-fax"
                                label="Receiver Fax ( optional )"
                                v-model="form.receiver_fax"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="receiver-email">Receiver Email</label>
                              <v-text-field
                                :disabled="disableAllFields"
                                id="receiver-email"
                                label="Receiver email ( optional )"
                                v-model="form.receiver_email"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Sender Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col cols="12" sm="12">
                          <label for="sender-name"
                            >Sender Name<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="sender-name"
                            label="Sender Name ( required )"
                            v-model="form.sender_name"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Name is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="pickup-address"
                            >Pickup Address<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="pickup-address"
                            v-model="form.sender_address"
                            label="Pick up address ( required )"
                            clearable
                            solo
                            :rules="[(v) => !!v || 'Address is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <label for="sender-contact"
                            >Sender Contact Numbers<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <v-text-field
                            :disabled="disableAllFields"
                            id="sender-contact"
                            label="SMS Contact ( required )"
                            v-model="form.sender_contact"
                            clearable
                            solo
                            :rules="[
                              (v) => !!v || 'Contact number is required',
                            ]"
                          ></v-text-field>
                          <v-text-field
                            :disabled="disableAllFields"
                            id="sender-additional-contact"
                            label="Sender Additional Contact ( optional )"
                            v-model="form.sender_additional_contact"
                            clearable
                            solo
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12">
                          <span
                            class="additional-toggle-btn"
                            v-if="showSenderAdditional"
                            @click="toggleSenderAdditional"
                            ><v-icon color="blue" small
                              >mdi mdi-minus-circle-outline</v-icon
                            >
                            Hide additional fields
                          </span>
                          <span
                            class="additional-toggle-btn"
                            v-else
                            @click="toggleSenderAdditional"
                            ><v-icon color="blue" small
                              >mdi mdi-plus-circle-outline</v-icon
                            >
                            Show additional fields</span
                          >
                          <small
                            class="additional-btn-small"
                            v-if="!senderAdditionalEmpty"
                          >
                            (Can not hide when values are not empty)</small
                          >
                        </v-col>
                      </v-row>
                      <v-row class="additional-card">
                        <v-col cols="12" sm="12" v-show="sender_additional">
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="sender-landmark"
                                >Sender Landmark</label
                              >
                              <v-text-field
                                :disabled="disableAllFields"
                                id="sender-landmark"
                                v-model="form.sender_landmark"
                                label="Sender Landmark ( optional )"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="sender-fax">Sender Fax</label>
                              <v-text-field
                                :disabled="disableAllFields"
                                id="sender-fax"
                                label="Sender Fax ( optional )"
                                v-model="form.sender_fax"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <label for="sender-email">Sender E-mail</label>
                              <v-text-field
                                :disabled="disableAllFields"
                                id="sender-email"
                                label="Sender E-mail ( optional )"
                                v-model="form.sender_email"
                                clearable
                                solo
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="d-flex align-right mb-2"
                          cols="12"
                          sm="12"
                        >
                          <v-btn
                            v-if="forEdit"
                            depressed
                            :disabled="calculating || packageOverlay"
                            @click="updatePackagesData()"
                            color="primary"
                            >Update Package</v-btn
                          >
                          <v-btn
                            v-else
                            depressed
                            :disabled="calculating || packageOverlay"
                            @click="addPackagesData()"
                            color="primary"
                            >Add Package</v-btn
                          >
                          <v-btn
                            color="#ff0000"
                            class="ml-4 cancel__btn"
                            depressed
                            @click="backToList()"
                            >Cancel</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12">
            <v-row>
               
            </v-row>
          </v-col> -->
          </v-row>
        </v-card>
      </v-form>
      <TodayPackages v-if="!forEdit" ref="todayPackages" class="mt-3" />
    </v-container>

    <v-overlay :value="packageOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      content-class="text-center"
      origin="overlap"
      timeout="3000"
      v-model="snackbar"
    >
      Text copied to clipboard

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
import Form from "vform";
import TodayPackages from "./TodayPackages.vue";

export default {
  name: "AddPackages",
  components: {
    AppBar,
    FooterView,
    TodayPackages,
  },

  data() {
    return {
      id: null,
      snackbar: false,
      calculating: false,
      sender_additional: false,
      receiver_additional: false,
      debounceTimer: null,
      totals: {
        total_amount: 0,
        courier_charge: 0,
        balance: 0,
        package_type: { id: 1, name: "Document" },
      },
      serverErrors: "df",
      pickupCities: [],
      deliveryCities: [],
      allCities: [],
      is_corporate: 1,
      payBy: [
        {
          id: 1,
          name: "Sender",
        },
        {
          id: 2,
          name: "Receiver",
        },
      ],
      form: new Form({
        pay_by: 1,
        has_exchange: false,
        weight: "",
        description: "",
        payment_type: 1,
        receiver_fax: "",
        package_date: "",
        pickup_city_id: "",
        receiver_email: "",
        delivery_city_id: "",
        receiver_contact: "",
        receiver_address: "",
        sender_name: "",
        receiver_landmark: "",
        receiver_name: "",
        receiver_additional_contact: "",
        sender_contact: "",
        sender_additional_contact: "",
        item_value: "",
        invoice_number: "",
        sender_fax: "",
        sender_email: "",
        sender_address: "",
        sender_landmark: "",
        same_day: null,
        total_amount: 0,
      }),
      is_exchange: false,
      editPackageData: null,
      pricing: {
        same_day: 0,
        cod_commission: 0,
        card: 0.03,
      },
      pickup_city: null,
      delivery_city: null,
      packageOverlay: false,
      errorAlertText: "",
      successAlertText: "",
    };
  },
  created() {
    this.setToken();
    this.getCity();
    this.getCurrentUserDetails();
  },
  mounted() {
    window.scrollTo(0, 0);
    this.id = this.$route.query?.id;
    if (this.id != null) {
      this.getPackageForEdit();
    }
    this.getPricingSettings();
  },
  computed: {
    forEdit() {
      return this.id != null;
    },
    disableAllFields() {
      return this.is_exchange;
    },
    senderAdditionalEmpty() {
      return (
        !this.form.sender_landmark &&
        !this.form.sender_fax &&
        !this.form.sender_email
      );
    },
    receiverAdditionalEmpty() {
      return (
        !this.form.receiver_landmark &&
        !this.form.receiver_fax &&
        !this.form.receiver_email
      );
    },
    showReceiverAdditional() {
      return this.receiver_additional || !this.receiverAdditionalEmpty;
    },
    showSenderAdditional() {
      return this.sender_additional || !this.senderAdditionalEmpty;
    },
    paymentTypes() {
      let array = [
        {
          id: 1,
          name: "Cash",
        },

        {
          id: 3,
          name: "Bank transfer",
        },
      ];
      if (this.form.pay_by == 1) {
        array.push({
          id: 2,
          name: "Card",
        });
      }
      if (this.is_corporate == 1) {
        array.push({
          id: 4,
          name: "Credit",
        });
      }
      return array;
    },
  },
  methods: {
    changeHasExchange() {
      this.form.exchange_item_value = null;
      this.form.exchange_description = null;
    },
    toggleReceiverAdditional() {
      if (!this.receiverAdditionalEmpty) return (this.receiver_additional = 1);
      this.receiver_additional = !this.receiver_additional;
    },
    toggleSenderAdditional() {
      if (!this.senderAdditionalEmpty) return (this.sender_additional = 1);
      this.sender_additional = !this.sender_additional;
    },
    goPrevious() {
      return this.$router.push({ name: this.prevRoute.name });
    },
    getPricingSettings() {
      axios
        .get(this.$hostname + "/api/get-pricing-settings")
        .then((response) => {
          const data = response.data.data;
          const sameDay = data.filter((obj) => {
            return obj.name == "same_day";
          })[0];
          const cod = data.filter((obj) => {
            return obj.name == "cod_commission";
          })[0];
          const card = data.filter((obj) => {
            return obj.name == "card_payment";
          })[0];
          let obj = {
            same_day: sameDay.current_value / 100,
            cod_commission: cod.current_value / 100,
            card: card.current_value / 100,
          };
          this.pricing = obj;
        });
    },
    backToList() {
      return this.$router.push({ name: "CustomerPackages" });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },

    getCity() {
      axios
        .get(this.$hostname + "/api/client/get-cities")
        .then((response) => {
          this.allCities = response.data.data;
          this.setPickupCities();
          this.setDeliveryCities();
          this.setPackageCities();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    correctData() {
      if (this.form.weight < 1) {
        this.form.weight = 1;
      }
    },
    calTotal() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        this.correctData();
        this.calculating = true;
        let user = localStorage.getItem("userDetails");
        user = JSON.parse(user);
        const packageData = {
          weight: this.form.weight,
          total_amount: this.form.total_amount,
          delivery_city_id: this.form.delivery_city_id,
          pickup_city_id: this.form.pickup_city_id,
          same_day: this.form.same_day == null ? 0 : this.form.same_day,
          payment_type: this.form.payment_type,
          pay_by: this.form.pay_by,
          user_id: user.user.id,
        };

        if (this.id != null) {
          packageData.id = this.id;
        }
        axios
          .post(this.$hostname + "/api/client/cal-total", packageData)
          .then((response) => {
            this.totals = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.calculating = false;
          });
      }, 1500); // Adjust the debounce delay as needed
    },
    setPickupCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 3) {
          array.push(item);
        }
      });
      this.pickupCities = array;
      this.clearForm();
    },
    setDeliveryCities() {
      let array = [];
      this.allCities.forEach((item) => {
          array.push(item); // get all cities as delivery cities
      });
      this.deliveryCities = array;
      this.clearForm();
    },
    setPackageCities() {
      if (
        this.pickup_city == null &&
        this.pickupCities.length > 0 &&
        this.form.pickup_city_id != null
      ) {
        this.pickup_city = this.getRecordById(
          this.pickupCities,
          this.form.pickup_city_id
        )[0];
      }

      if (
        this.delivery_city == null &&
        this.deliveryCities.length > 0 &&
        this.form.delivery_city_id != null
      ) {
        this.delivery_city = this.getRecordById(
          this.deliveryCities,
          this.form.delivery_city_id
        )[0];
      }
    },
    getRecordById(array, id) {
      return array.filter((item) => {
        return item.id == id;
      });
    },
    getPackageForEdit() {
      this.packageOverlay = true;
      axios
        .get(this.$hostname + "/api/client/package/" + this.id)
        .then((response) => {
          this.editPackageData = response.data;
          this.setPackageDataForEdit();
        })
        .catch((error) => {
          this.showError(error.response.data.message);
        })
        .finally(() => {
          this.packageOverlay = false;
        });
    },
    setPackageDataForEdit() {
      if (this.editPackageData == null) return;
      this.package_id = this.editPackageData.package.id;
      this.form.pickup_city_id = this.editPackageData.package.pickup_city_id;
      this.form.delivery_city_id =
        this.editPackageData.package.delivery_city_id;
      this.form.package_date = this.editPackageData.package.package_date;
      this.form.weight = this.editPackageData.package.weight;
      this.form.description = this.editPackageData.package.description;
      this.form.same_day = this.editPackageData.package.same_day;
      this.form.invoice_number = this.editPackageData.package.invoice_number;
      this.form.item_value = this.editPackageData.package.item_value;
      this.payment_type_text = this.editPackageData.package.payment_type_text;
      this.form.exchange_item_value =
        this.editPackageData.package.exchange_item_value;
      this.form.exchange_description =
        this.editPackageData.package.exchange_description;
      this.payment_type = this.editPackageData.package.payment_type;
      this.form.receiver_name =
        this.editPackageData.package.package_person.receiver_name;
      this.form.receiver_name =
        this.editPackageData.package.package_person.receiver_name;
      this.form.receiver_contact =
        this.editPackageData.package.package_person.receiver_contact;
      this.form.receiver_additional_contact =
        this.editPackageData.package.package_person.receiver_additional_contact;
      this.form.receiver_fax =
        this.editPackageData.package.package_person.receiver_fax;
      this.form.receiver_address =
        this.editPackageData.package.package_person.receiver_address;
      this.form.receiver_email =
        this.editPackageData.package.package_person.receiver_email;
      this.form.receiver_landmark =
        this.editPackageData.package.package_person.receiver_landmark;
      this.form.sender_fax =
        this.editPackageData.package.package_person.sender_fax;
      this.form.sender_name =
        this.editPackageData.package.package_person.sender_name;
      this.form.sender_contact =
        this.editPackageData.package.package_person.sender_contact;
      this.form.sender_additional_contact =
        this.editPackageData.package.package_person.sender_additional_contact;
      this.form.sender_email =
        this.editPackageData.package.package_person.sender_email;
      this.form.sender_address =
        this.editPackageData.package.package_person.sender_address;
      this.form.sender_landmark =
        this.editPackageData.package.package_person.sender_landmark;
      this.form.pay_by = this.editPackageData.package.pay_by;
      this.form.payment_type = this.editPackageData.package.payment_type;
      this.setPackageCities();

      this.form.total_amount = this.editPackageData.package.total_amount;

      this.totals.total_amount = this.editPackageData.package.total_amount;
      this.totals.balance = this.editPackageData.package.cod;
      this.totals.courier_charge = this.editPackageData.package.courier_charge;
      if (this.editPackageData.package.exchange) {
        this.is_exchange = true;
      }
      if (this.editPackageData.package.child_exchanges.length > 0) {
        const exchangePackage = this.editPackageData.package.child_exchanges[0];
        this.form.has_exchange = 1;
        this.form.exchange_item_value = exchangePackage.item_value;
        this.form.exchange_description = exchangePackage.description;
      }
      if (!this.receiverAdditionalEmpty) {
        this.toggleReceiverAdditional();
      }
      if (!this.senderAdditionalEmpty) {
        this.toggleSenderAdditional();
      }
    },
    changePackageTotal() {
      if (this.form.total_amount < 0 || !this.form.total_amount == null)
        this.form.total_amount = 0;
      this.calTotal();
    },
    weightChange() {
      this.calTotal();
    },
    changeValuePayBy() {
      this.form.payment_type = 1;
      this.calTotal();
    },
    fillSenderDetails() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user).user;
      this.form.sender_name = user.name;
      this.form.sender_address = user.address;
      this.form.sender_contact = user.phone;
      this.form.sender_additional_contact = user.whatsapp;
    },
    addPackagesData() {
      this.errorAlertText = "";
      this.errorAlertText = "";
      this.$refs.weightField.validate();
      if (this.$refs.form.validate()) {
        this.packageOverlay = true;

        this.form
          .post(this.$hostname + "/api/client/package")
          .then((response) => {
            console.log(response);
            this.showSuccess(response.data.message);
            this.clearForm();
            this.$refs.todayPackages.getPackages();
          })
          .catch((error) => {
            console.log(error);
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.packageOverlay = false;
          });
      }
    },
    updatePackagesData() {
      if (this.$refs.form.validate()) {
        this.packageOverlay = true;
        this.form
          .put(
            this.$hostname + "/api/client/package/" + this.package_id,
            this.form
          )
          .then((response) => {
            this.showSuccess(response.data.message);
            this.$router.push({ name: "CustomerPackages" });
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.packageOverlay = false;
            this.clearForm();
          });
      }
    },
    clearForm() {
      if (this.id != null && this.editPackageData != null) {
        return this.setPackageDataForEdit();
      }

      this.$refs.form.reset();
      this.$nextTick(() => {
        this.pickup_city = null;
        this.delivery_city = null;
        this.form.pickup_city_id = null;
        this.form.delivery_city_id = null;
        this.form.package_date = null;
        this.form.weight = 1;
        this.form.description = null;
        this.form.pay_by = 1;
        this.form.total_amount = null;
        this.form.payment_type = 1;
        this.form.receiver_name = null;
        this.form.receiver_name = null;
        this.form.receiver_contact = null;
        this.form.receiver_additional_contact = null;
        this.form.receiver_fax = null;
        this.form.receiver_email = null;
        this.form.receiver_address = null;
        this.form.receiver_landmark = null;
        this.form.same_day = null;
        this.calTotal();
        if (this.id == null) {
          this.fillSenderDetails();
        }
      });
    },
    setPickCityId() {
      this.form.pickup_city_id = this.pickup_city.id;
      this.calTotal();
    },
    setDeliveryCityId() {
      this.form.delivery_city_id = this.delivery_city.id;
      this.calTotal();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    dateChange() {
      this.calTotal();
    },
    getCurrentUserDetails() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        this.is_corporate = user.user.is_corporate;
        this.form.sender_name = user.user.name;
        this.form.sender_name = user.user.name;
        this.form.sender_email = user.user.email;
        this.form.sender_address = user.user.address;
        this.form.sender_contact = user.user.phone;
        this.address = user.user.address;
      } else {
        this.$router.push({ name: "LoginScreen" });
      }
    },
    copyText(text) {
      this.snackbar = false;
      setTimeout(() => {
        navigator.clipboard.writeText(text);
        this.snackbar = true;
      }, 100);
    },
  },
};

</script>
<style scoped>
.cancel__btn {
  color: white;
}

.address_line {
  margin-top: 10px;
  line-height: 5px;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

.earning-text-danger {
  color: rgb(189, 8, 8);
}

.mount-clickable {
  cursor: pointer;
}

.earning-text-small {
  font-style: italic;
}

.summery-card {
  background-color: #e5e5e5;
  border-radius: 10px 10px 0 0;
}

.additional-toggle-btn {
  cursor: pointer;
  color: rgb(6, 116, 186);
  user-select: none;
}

.additional-toggle-btn .v-icon {
  margin-bottom: 3px;
}

.additional-card {
  background-color: #f7f4f4;
}

.additional-btn-small {
  user-select: none;
  font-style: italic;
}

.exchange-card {
  outline: double rgb(90 164 237 / 69%) 4px;
}
</style>
