<template>
  <div>
    <AppBar />
    <!-- Desktop start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="faqBackgroundImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">FAQ</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> FAQ
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="faqBackgroundMobileImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">FAQ</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> FAQ
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-5 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 13px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            GET ANSWERS
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: -15px;
            "
          >
            Frequently Asked
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: 10px;
              color: #0674ba;
            "
          >
            Question
          </p>
          <p class="content-text">
            Here are some answers for few questions that would arise to the
            <br />
            customers when working with us.
          </p>

          <v-progress-linear
            color="#0674ba"
            indeterminate
            rounded
            height="6"
            v-if="faqOverlay"
          ></v-progress-linear>

          <p
            v-if="faqOverlay"
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              letter-spacing: 0.8px;
              margin-top: 10px;
            "
          >
            Loading Please Wait...
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-10 mb-5 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 15px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            GET ANSWERS
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: -15px;
            "
          >
            Frequently Asked
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: 10px;
              color: #0674ba;
            "
          >
            Question
          </p>
          <p class="content-text">
            Here are some answers for few questions that <br />
            would arise to the customers when working with us.
          </p>

          <v-progress-linear
            color="#0674ba"
            indeterminate
            rounded
            height="6"
            v-if="faqOverlay"
          ></v-progress-linear>

          <p
            v-if="faqOverlay"
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              letter-spacing: 0.8px;
              margin-top: 10px;
            "
          >
            Loading Please Wait...
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mb-16" style="width: 60%">
      <v-row>
        <v-col cols="12" md="12" v-for="(faq, i) in faqArray" :key="i">
          <v-card
            v-if="showFAQ == i"
            elevation="0"
            class="pa-1"
            style="
              font-family: 'SF-Pro-Display-Semibold';
              font-style: normal;
              font-weight: 200;
              background-color: #0674ba;
              border-radius: 37.5px;
            "
          >
            <p
              class="pl-8 pr-8 pt-3"
              style="color: white; letter-spacing: 0.8px; font-size: 14px"
            >
              {{ i + 1 + ". " + faq.question }}

              <v-btn
                elevation="0"
                x-small
                fab
                class="float-right"
                @click="showFaq(i)"
                ><v-icon color="#0674ba">mdi-minus</v-icon></v-btn
              >
            </p>
          </v-card>
          <v-card
            v-if="showFAQ != i"
            elevation="0"
            class="pa-1"
            style="
              font-family: 'SF-Pro-Display-Semibold';
              font-style: normal;
              font-weight: 200;
              background-color: white;
              border-radius: 37.5px;
              border: 1px solid #0674ba;
            "
          >
            <p
              class="pl-8 pr-8 pt-3"
              style="color: #0674ba; letter-spacing: 0.8px; font-size: 14px"
            >
              {{ i + 1 + ". " + faq.question }}

              <v-btn
                elevation="0"
                x-small
                fab
                class="float-right"
                color="#0674ba"
                @click="showFaq(i)"
                ><v-icon color="white">mdi-plus</v-icon></v-btn
              >
            </p>
          </v-card>

          <p
            v-if="showFAQ == i"
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 12px;
              letter-spacing: 0.8px;
              margin-top: 30px;
              padding-left: 40px;
              padding-right: 40px;
            "
          >
            {{ faq.answer }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mb-16" style="width: 100%">
      <v-row>
        <v-col cols="12" md="12" v-for="(faq, i) in faqArray" :key="i">
          <v-card
            v-if="showFAQ == i"
            elevation="0"
            class="pa-5"
            style="
              font-family: 'SF-Pro-Display-Semibold';
              font-style: normal;
              font-weight: 200;
              background-color: #0674ba;
              border-radius: 16px;
            "
          >
            <v-row>
              <v-col cols="10">
                <p
                  style="
                    color: white;
                    letter-spacing: 0.8px;
                    font-size: 18px;
                    word-break: break-word;
                  "
                >
                  {{ i + 1 + ". " + faq.question }}
                </p>
              </v-col>
              <v-col cols="2" align-self="center">
                <v-btn
                  elevation="0"
                  x-small
                  fab
                  class="float-right"
                  @click="showFaq(i)"
                  ><v-icon color="#0674ba">mdi-minus</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-if="showFAQ != i"
            elevation="0"
            class="pa-5"
            style="
              font-family: 'SF-Pro-Display-Semibold';
              font-style: normal;
              font-weight: 200;
              background-color: white;
              border-radius: 16px;
              border: 1px solid #0674ba;
            "
          >
            <v-row>
              <v-col cols="10">
                <p
                  style="color: #0674ba; letter-spacing: 0.8px; font-size: 18px"
                >
                  {{ i + 1 + ". " + faq.question }}
                </p>
              </v-col>
              <v-col cols="2" align-self="center">
                <v-btn
                  elevation="0"
                  x-small
                  fab
                  class="float-right"
                  color="#0674ba"
                  @click="showFaq(i)"
                  ><v-icon color="white">mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card>

          <p
            v-if="showFAQ == i"
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 15px;
              letter-spacing: 0.8px;
              margin-top: 30px;
              padding-left: 40px;
              padding-right: 40px;
            "
          >
            {{ faq.answer }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
import axios from "axios";
export default {
  name: "FAQDetails",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      faqArray: [],
      showFAQ: 0,
      faqOverlay: false,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getAllFAQDetails();
  },
  methods: {
    showFaq(number) {
      if (this.showFAQ == number) {
        this.showFAQ = -1;
      } else {
        this.showFAQ = number;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getAllFAQDetails() {
      /* eslint-disable */
      this.faqOverlay = true;
      axios
        .get(this.$hostname + "/api/client/faq/get-all")
        .then((response) => {
          this.faqArray = response.data.data;
          this.faqOverlay = false;
          console.log("Responce----", this.faqArray);
        })
        .catch((error) => {
          // this.checkImportationLabReportDialog = false;
        });
    },
  },
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}
.faqBackgroundImg {
  background-image: url("@/assets/image/FAQDetails/cover.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.faqBackgroundMobileImg {
  background-image: url("@/assets/image/FAQDetails/mobile-cover.png");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
</style>
