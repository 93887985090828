<template>
  <div>
     <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.subtitle"
            :to="{
                            path:
                                '/admin/' + item.title + '/' + subItem.routePath,
                                 params: {name: subItem.routePath}
                        }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.subtitle"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="blue" dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="headline" style="margin-right:30px">
        <v-avatar>
          <img src="@/assets/image/falcon-icon-only-white.png" alt="Falcon" width="44px" height="44px" />
        </v-avatar>
        <span class="title white--text">
          <a
            href="/home"
            class="title white--text"
            style="text-decoration:none"
          >Falcon</a>
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title class="headline" style="margin-right:30px">
        <span class="title white--text" v-if="userRoleForAccess == 'admin'">
          User Role - Super Admin
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'PM'">
          User Role - Planing Manager
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'DGMD'">
          User Role - Deputy General Manager (Dev)
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'DGMF'">
          User Role - Deputy General Manager (Finance)
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'MAL'">
          User Role - Manager Assist Loan
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'MAG'">
          User Role - Manager Assist Grant
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'AccountantH'">
          User Role - Accountant Head office
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'AccountantD'">
          User Role - Accountant Dist office
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'Engineer'">
          User Role - Engineer
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'DM'">
          User Role - District Manager
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'DMA'">
          User Role - District Manager Assistant
        </span>
        <span class="title white--text" v-if="userRoleForAccess == 'DDM'">
          User Role - District Development Manager
        </span>
      </v-toolbar-title>
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon class="white--text">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="userInfo()">
            <v-list-item-title>
              <v-icon class="ma-2">mdi-account-card-details</v-icon>User Info
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogLogout = true">
            <v-list-item-title>
              <v-icon class="ma-2">mdi-logout</v-icon>Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- ******************* logout user dialog ********************** -->

    <v-dialog v-model="dialogLogout" max-width="290">
      <v-card>
        <v-card-title class="headline">User Logout?</v-card-title>
        <v-card-text>Do you want to logout ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogLogout = false">Cancel</v-btn>

          <v-btn color="green darken-1" text @click="isLogOut()">Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-content>
      
    </v-content>

  </div>
</template>

<script>
export default {
  name: "AdminDashboard",

  data: () => ({
    showPassword: false,
    drawer: true,
    dialogLogout: false,
    items: [
      {
        action: "mdi-account-tie",
        title: "Operation",
        active: false,
        items: [
          { subtitle: "Pickup", routePath: "pickup" },
          { subtitle: "Transfer", routePath: "transfer" },
          { subtitle: "Deliveries", routePath: "deliveries" },
          { subtitle: "Reroute", routePath: "reroute" },
          { subtitle: "Daily routes", routePath: "daily-routes" },
          { subtitle: "Assigning routes to Couriers", routePath: "assigning-routes-to-couriers" },
            { subtitle: "Courier assigning to Vehicles", routePath: "courier-assigning-to-vehicles" },
            { subtitle: "Operation Reports", routePath: "operation-reports" },
        ],
        url: "",
      },
      {
        action: "mdi-truck-fast",
        title: "Transport",
        active: false,
        items: [
            { subtitle: "Vehicle Register", routePath: "vehicle-register" },
          { subtitle: "Vehicle Attendance", routePath: "vehicle-attendance" },
          { subtitle: "Shuttle Vehicle Management", routePath: "shuttle-vehicle-management" },
          { subtitle: "Driver assigning to Vehicles", routePath: "driver-assigning-to-vehicles" },
          { subtitle: "Fuel Issuing ", routePath: "fuel-issuing " },
          { subtitle: "Reports", routePath: "transport-reports" }
        ],
        url: "",
      },
      {
        action: "mdi-account-network",
        title: "HR / Admin",
        active: false,
        items: [
          { subtitle: "Staff Register", routePath: "staff-register" },
          { subtitle: "Staff attendance", routePath: "staff-attendance" },
          { subtitle: "Staff leave update", routePath: "Staff-leave-update" },
          { subtitle: "User profile", routePath: "user-profile" },
        ],
        url: "",
      },
      {
        action: "mdi-office-building",
        title: "Company Profiles",
        active: false,
        items: [
          { subtitle: "Company Profile", routePath: "company-profile" },
          { subtitle: "Branch Profile", routePath: "branch-profile" }
        ],
      },
      {
        action: "mdi-chart-box",
        title: "Accounts",
        active: false,
        items: [
          { subtitle: "Payment Confirmation", routePath: "payment-confirmation" },
          { subtitle: "Billing", routePath: "billing" },
          { subtitle: "Reports", routePath: "reports" },
        ],
      },
    ],
  }),
  methods:{
      isLogOut(){
          localStorage.setItem("isLogin", false);
          this.$router.push('/');
      },
  }
};
</script>
