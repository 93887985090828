<template>
  <div>
    <v-card rounded dark class="rounded-xl pb-5 mt-10" color="#212529">
      <v-card-title class="font-weight-bold text-h5 ml-2"
        >Downloads</v-card-title
      >
      <v-sheet @click="active = 'profile'" rounded class="ml-2 mr-2 mb-2" :color="active == 'profile' ? '#0674BA' : 'white'">
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Falcon Company Profile.pdf
              </p>
            </v-col>
            <v-col cols="6" md="2">
              <a :href="$hostname + '/api/client/download/get-file/profile'">
              <v-icon color="white" class="float-right mt-5"
                >mdi-download</v-icon
              >
              </a>
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet @click="active = 'pickup'" rounded class="ml-2 mr-2 mb-2 black--text" :color="active == 'pickup' ? '#0674BA' : 'white'">
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Pickup Request.pdf
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <a  :href="$hostname + '/api/client/download/get-file/pickup'">
              <v-icon color="#0674BA" class="float-right  mt-5"
                >mdi-download</v-icon
              >
              </a>
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet @click="active = 'flyer'" rounded class="ml-2 mr-2 mb-2 black--text" :color="active == 'flyer' ? '#0674BA' : 'white'">
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Flyer.jpeg
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <a :href="$hostname + '/api/client/download/get-file/flyer'">
              <v-icon color="white" class="float-right mt-5"
                >mdi-download</v-icon
              >
              </a>
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import social_links from '@/config';

export default {
  name: "DownloadsMobile",

  data() {
    return {
      windowWidth: window.innerWidth,
      selectService: 0,
      active:'profile',
      servicesList: [
        { title: "Documents & Packages Delivery" },
        { title: "Same day Pick up & Delivery Services" },
        { title: "Management of Mail Room" },
        { title: "Cash on Delivery (COD)" },
        { title: "Confidential Documents Delivery" },
        { title: "Confidential Parcel Delivery" },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.selectServicePage(this.$route.name);
  },
  methods: {
    selectServicePage(name) {
      switch (name) {
        case "DocumentPackageDelivery":
          this.selectService = 1;
          break;
        case "SamedayPickupDeliveryServices":
          this.selectService = 2;
          break;
        case "ManagementofMailRoom":
          this.selectService = 3;
          break;
        case "CashOnDelivery":
          this.selectService = 4;
          break;
        case "ConfidentialDocumentsDelivery":
          this.selectService = 5;
          break;
        case "ConfidentialParcelDelivery":
          this.selectService = 6;
          break;

        default:
          break;
      }
    },

    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },

    redirectToHomePage() {
      this.$router.push({ name: "home" });
    },

    redirectToContactUsPage() {
      this.$router.push({ name: "ContactUs" });
    },

    redirectToBranchNetworkPage() {
      this.$router.push({ name: "BranchNetwork" });
    },

    redirectToAboutUsPage() {
      this.$router.push({ name: "AboutUs" });
    },
    redirectToFAQPage() {
      this.$router.push({ name: "FAQDetails" });
    },
    redirectToOrderTrack() {
      this.$router.push({ name: "OrderTrack" });
    },

    redirectToServicePage1() {
      this.$router.push({ name: "DocumentPackageDelivery" });
    },

    redirectToHome() {
      window.location.href = "/";
    },
    onResize(e) {
      this.windowWidth = window.innerWidth;
      console.log(e);
    },
    redirectSocialMedia(number) {
      switch (number) {
        case 1:
          window.open(social_links.facebook, "_blank");
          break;
        case 2:
          window.open("https://www.instagram.com/?hl=en", "_blank");
          break;
        case 3:
          window.open("https://lk.linkedin.com/", "_blank");
          break;
        case 4:
          window.open("https://www.youtube.com/", "_blank");
          break;

        default:
          break;
      }
    },
    redirectMailTo() {
      window.location.href = "mailto:info@falcondelivery.com";
    },
    redirectTel() {
      window.location.href = "tel:0123526589";
    },
  },
};
</script>

<style scoped>
.rubik-fw600-15 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.my-menu {
  margin-top: 30px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 90%;
  left: 2%;
  transform: translateY(-80%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.listItem:hover {
  background-color: #0674ba;
  color: white !important;
}
</style>
