<template>
  <v-app id="inspire">
    <AppBar NavIcon="NavIcon"> </AppBar>
    <div style="background: #e6ebef; text-align: center;">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card elevation="4">
              <v-card-title>
                My Earnings
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent lazy-validation ref="payment">
              <v-card elevation="8">
                <v-card-title>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Invoice Number"
                      clearable
                      solo
                      dense
                      v-model="form.invoice_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="form.start"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="form.end"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex mb-6" cols="3" sm="3">
                    <v-btn depressed @click="search()" color="primary"
                      >Search</v-btn
                    >
                    <v-btn
                      class="ml-5 white--text"
                      depressed
                      @click="clearSearch()"
                      color="red"
                      >Clear</v-btn
                    >
                  </v-col>
                </v-card-title>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="12">
            <v-card >
              <v-row class="totals-wrapper">
                <v-col cols="12" md="3" lg="4" xl="4">
                <p class="totals-text cod-total">Total COD : {{ formatNumbers(totals.cod) }}</p>
                </v-col>
                <v-col cols="12" md="3" lg="4" xl="4">
                <p class="totals-text credit-total">Total Credit : {{ formatNumbers(totals.credit) }}</p>
                </v-col>
                <v-col cols="12" md="3" lg="4" xl="4">
                <p class="totals-text earning-total">Total Earning : {{ formatNumbers(totals.cod - totals.credit) }}</p>
                </v-col>
              </v-row>
              </v-card>
            </v-col>
            </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="8">
              <v-data-table
                :headers="payments_headers"
                :items="records"
                hide-default-footer
                :items-per-page="pagination.meta.per_page"
              >
                <template v-slot:[`item.barcode`]="{ item }">
                  <CodeColumn
                    :barcode="item.barcode"
                    :sender_return="item.returned"
                    :child_exchange="item.exchange"
                  />
                </template>

                <template v-slot:[`item.cod_amount_formatted`]="{ item }">
                  <span v-if="item.cod_amount > 0" class="text-green">
                    {{ item.cod_amount_formatted }}
                  </span>
                  <span v-else>0.00</span>
                </template>

                <template v-slot:[`item.credit_amount_formatted`]="{ item }">
                  <span v-if="item.credit_amount > 0" class="text-red">
                    - {{ item.credit_amount_formatted }}
                  </span>
                  <span v-else>0.00</span>
                </template>
              </v-data-table>
              <v-pagination
                v-if="getPagesCount > 1"
                class="mt-4"
                v-model="pagination.meta.current_page"
                :length="getPagesCount"
                :total-visible="7"
                @input="paginationChanged"
                circle
              ></v-pagination>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
import CodeColumn from '../CodeColumn/Index.vue';

export default {
  name: "CustomerPayments",
  components: {
    AppBar,
    FooterView,
    CodeColumn
  },

  data() {
    return {
      payments_headers: [
        { text: "Barcode", value: "barcode" },
        { text: "Date", value: "package_date" },
        { text: "Receiver", value: "receiver_details" },
        { text: "Current Stage", align: "right", value: "public_status" },
        { text: "Invoice Number", value: "invoice_number" },
        { text: "COD", align: "right", value: "cod_amount_formatted" },
        { text: "Credit", align: "right", value: "credit_amount_formatted" },
      ],
      records: [],
      pagination: {
        meta: {
          current_page: 1,
          per_page:10
        },
      },
      form: {
        invoice_number: null,
        start: null,
        end: null,
      },
      date: null,
      totals: {
        cod:0,
        credit:0
      }
    };
  },
  created() {
    this.setToken();
  },
  computed: {
    getPagesCount() {
      const count = this.pagination.meta.total / this.pagination.meta.per_page;
      return Math.ceil(count);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.search();
  },
  methods: {
    paginationChanged(page) {
      this.search(page);
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    search(page = 1) {
      this.form.page = page
      axios
        .get(this.$hostname + "/api/client/get-earnings", {
          params: this.form,
        })
        .then((response) => {
          // this.records = response.data.packages.filter(element => element.credit_amount > 0 || element.cod_amount > 0);
          this.records = response.data.packages;
          this.totals = response.data.totals;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearSearch() {
      this.form.transaction_id = null;
      this.form.invoice_number = null;
      this.form.start = null;
      this.form.end = null;
      this.search();
    },
  },
};
</script>
<style scoped>
.text-green {
  color: green;
  font-weight: 600;
}

.text-red {
  color: red;
  font-weight: 600;
}

.totals-text{
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cod-total {
}

.credit-total {
}

.totals-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
