import Vue from 'vue';

Vue.mixin({
    methods: {
        ucFirst(string) {
            const lower = string.toLowerCase();
            return lower.charAt(0).toUpperCase() + lower.slice(1);
        },
        formatNumbers(number) {
            const options = {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            };
            return Number(number).toLocaleString('en', options);
        },
        showError(message, title = "Error!") {
            this.$swal.fire({
                title: title,
                text: message,
                icon: "error"
            });
        },
        showInfo(message, title = "Info!") {
            this.$swal.fire({
                title: title,
                text: message,
                icon: "info"
            });
        },
        showSuccess(message, title = 'Success',html = null) {
            this.$swal.fire({
                title: title,
                text: message,
                html:html,
                icon: "success",
                confirmButtonColor: "#28a745",
                confirmButtonText: "OK",
            });
        }
    }
});
