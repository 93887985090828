<template>
    <div>
        <v-row>
        <v-col cols="12"  class="mt-4">
          <h4>Recent packages</h4>
          <v-card elevation="2">
            <v-data-table
              :headers="packges_headers"
              :items="getCustomerPackages"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip color="red" dark x-small v-if="item.status == '0'">
                  Cancelled
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.status == '1'"
                >
                  Active
                </v-chip>
                <v-chip
                  color="yellow"
                  dark
                  x-small
                  v-else-if="item.status == '2'"
                >
                  Pending
                </v-chip>
                <v-chip color="red" dark x-small v-else-if="item.status == '3'">
                  Rejected
                </v-chip>
                <v-chip color="brown" dark x-small v-else> unknown </v-chip>
              </template>
              <template v-slot:[`item.public_status_text`]="{ item }">
                <v-chip
                  color="ash"
                  dark
                  x-small
                  v-if="item.public_status_text == 'Order Placed'"
                >
                Order Placed
                </v-chip>
                <v-chip
                  color="yellow"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Processing'"
                >
                Processing
                </v-chip>
                <v-chip
                  color="blue"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Shipped'"
                >
                  Shipped
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Delivered'"
                >
                  Delivered
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu left bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="viewPackage(item.id)">
                      <v-list-item-title>
                        <v-icon class="mr-3">mdi-eye</v-icon>
                        View
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="print(item)"
                      v-if="item.status != 3"
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-cloud-print </v-icon>
                        Print
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <!--delete modal open-->
    <v-dialog v-model="deleteModal" max-width="250px">
        <v-card>
          <v-card-title class="text-h5"
            >Cancel this order ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteModal = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deletePackage()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import setAuthHeader from "@/utils/setAuthHeader";
    export default {
      name: "TodayPackages",
      data() {
        return {
          windowWidth: window.innerWidth,
          getCustomerPackages: [],
          packges_headers: [
            { text: "Barcode", value: "barcode",sortable:false },
            { text: "Pickup City", value: "pickup_city_name",sortable:false },
            { text: "Delivery City", value: "delivery_city_name",sortable:false },
            { text: "Package Total", value: "formatted_total",align:"right",sortable:false },
            { text: "Courier Charge", value: "courier_charge_formatted",align:"right",sortable:false },
            { text: "Paid Amount", value: "paid_amount" ,align:"right",sortable:false},
            { text: "Pickup Date", value: "package_date" ,sortable:false},
            { text: "Created at", value: "created_at",sortable:false },
            { text: "Status", value: "status",sortable:false },
            { text: "Current Stage", value: "public_status_text",sortable:false },
            { text: "Action", value: "actions",sortable:false },
          ],
          deleteModal: false,
          barcode: null,
          stage: null,
          start: null,
          end: null,
          stage_change: null,
          dialog: false,
          stageItems: [
            { id: "1", name: "Order Placed" },
            { id: "2", name: "Processing" },
            { id: "3", name: "Shipped" },
            { id: "4", name: "Delivered" },
          ]
        };
      },
      created() {
        this.setToken();
        this.getPackages();
      },
      methods: {
        print(item) {
      axios
        .get(this.$hostname + "/api/client/print/" + item.id, {
        responseType: 'blob',
        Accept: 'application/pdf',
      })
        .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            console.log(link);
            link.href = url;
            // link.setAttribute("download", "staffs.pdf"); //or any other extension
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
          console.log(error);
        })
    },
        setToken() {
          let user = localStorage.getItem("userDetails");
          user = JSON.parse(user);
          if (user) {
            setAuthHeader(user.token);
          } else {
            setAuthHeader(false);
          }
        },
        getPackages() {
          axios
            .get(this.$hostname + "/api/client/package/get-all", {
              params: {
                created_date: new Date()
              },
            })
            .then((response) => {
              this.getCustomerPackages = response.data.package;
            })
            .catch((error) => {
              console.log(error);
            });
        },
        OpendeleteDailog(id) {
          this.pacakge_id = id;
          this.deleteModal = true;
        },
        deletePackage() {
          axios
            .delete(this.$hostname + "/api/client/package/" + this.pacakge_id)
            .then((response) => {
              this.deleteModal = false;
              this.getPackages();
              console.log(response.message);
            })
            .catch((error) => {
              console.log(error);
            });
        },
        viewPackage(id) {
          this.$router.push({
            name: "ViewPackage",
            query: { id: id  },
          });
        },
        EditPackage(id) {
          this.$router.push({
            name: "EditPackages",
            params: { id: id  },
          });
        },
      },
    };
    </script>
    <style scoped>
      .card__title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    </style>
    