<template>
  <div style="overflow-x: hidden">
    <AppBar />
    <CarouselView />
    <!-- Track order View Desktop Start -->
    <v-sheet v-if="windowWidth >= 600" max-width="100%" color="#112548">
      <v-container>
        <v-row class="d-flex align-center">
          <v-col cols="12" md="1">
            <v-img
              lazy-src="@/assets/image/Home/track-order-side-img.svg"
              max-height="auto"
              max-width="74%"
              style="background-position: center"
              src="@/assets/image/Home/track-order-side-img.svg"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <p
              class="white--text ma-0 pa-0"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-size: 48px;
                line-height: 120%;
              "
            >
              Track Order
            </p>
            <v-card-text
              class="white--text ma-0 pa-0"
              style="
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 13px;
                letter-spacing: 0.8px;
              "
              >Enter up to 12 tracking numbers, one per line.</v-card-text
            >
          </v-col>
          <v-col cols="12" md="5" class="mt-7">
            <v-text-field
            v-on:keypress.enter="handleEnterPress"
              v-model="track_order_code"
              placeholder="eg: VLKRBEAE11FB"
              solo
              class="rubik-fw600-16-grey"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              @click="orderTrack()"
              color="#0674BA"
              class="white--text"
              large
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 32px 20px;
                gap: 10px;
                width: 100%;
              "
            >
              TRACK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Track order View Desktop End -->

    <!-- Track order View Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      max-width="100%"
      color="#112548"
      style="margin-top: -8px; padding-top: 70px"
    >
      <v-container>
        <v-row>
          <v-col cols="auto" sm="1">
            <v-img
              lazy-src="@/assets/image/Home/track-order-side-img.svg"
              max-height="auto"
              max-width="90%"
              src="@/assets/image/Home/track-order-side-img.svg"
            ></v-img>
          </v-col>
          <v-col cols="auto" sm="11">
            <p
              class="white--text ma-0 pa-0"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-size: 48px;
                line-height: 120%;
              "
            >
              Track Order
            </p>
            <v-card-text
              class="white--text ma-0 pa-0"
              style="
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 13px;
                letter-spacing: 0.8px;
              "
              >Enter up to 12 tracking numbers, <br />
              one per line.</v-card-text
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="mt-1" style="margin-bottom: -40px">
            <v-text-field
              full-width
              placeholder="eg: VLKRBEAE11FB"
              solo
              class="rubik-fw600-16-grey"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" style="margin-bottom: 40px">
            <v-btn
            v-on:keypress.enter="handleEnterPress"
              color="#0674BA"
              @click="orderTrack()"
              class="white--text"
              large
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 18px 32px 20px;
                gap: 10px;
                width: 100%;
              "
            >
              TRACK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Track order View Mobile End -->

    <!-- About Us View Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mb-10">
      <v-row style="padding-top: 80px">
        <v-col cols="12" md="6" style="padding-right: 30px">
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 13px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            ABOUT US
          </p>
          <p
            class="mb-0"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin: 0px;
            "
          >
            WELCOME TO
          </p>
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: -10px;
            "
          >
            FALCON EXPRESS
          </p>
          <v-img
            lazy-src="@/assets/image/Home/header-bar-orange.svg"
            max-height="auto"
            max-width="14%"
            style="background-position: center; margin-bottom: 15px"
            src="@/assets/image/Home/header-bar-orange.svg"
          ></v-img>
          <p
            style="
              font-family: 'Rubik-Regular';
              font-style: normal;
              font-size: 28px;
            "
          >
            Right place for a dream delivery...
          </p>
          <p class="content-text" style="padding-right: 50px">
            Falcon Express Ceylon is one of the trending organization exists in
            the Courier industry of Sri Lanka. We comprised with an experienced
            team of professionals to provide a surpass service to our customers
            every time. Understanding the customer requirements is the key
            element that makes the delivery a success. We believe that we have
            the most convenient solution which is expected and fitted to
            nowadays customers, when satisfying their needs in the service of
            courier. Providing a fast, reliable, accountable and secure service
            to our customers while assuring their satisfaction consistently
            every day and for every delivery is the main objective. Ability of
            sharing the status of the delivery online until it reaches the
            destination is the most important and valuable strength of our
            company.
          </p>
          <v-btn
            @click="redirectToAboutUsPage()"
            color="#0674BA"
            class="white--text rounded-lg"
            x-large
            style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
          >
            READ MORE
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" style="padding-left: 30px">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/Home/welcome-to-falcon-img.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/Home/welcome-to-falcon-img.jpg"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="padding-top: 80px; padding-bottom: 80px" align="center">
        <v-col cols="12" md="6">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/Home/welcome-to-falcon-img-2.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/Home/welcome-to-falcon-img-2.jpg"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" style="padding-left: 100px">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-bottom: 20px;
            "
          >
            PICK UP <span style="color: #0674ba">REQUEST</span>
          </p>
          <v-img
            lazy-src="@/assets/image/Home/header-bar-orange.svg"
            max-height="auto"
            max-width="14%"
            style="background-position: center; margin-bottom: 15px"
            src="@/assets/image/Home/header-bar-orange.svg"
          ></v-img>
          <p class="content-text" style="padding-right: 50px">
            Make an online request to fetch your package straight away by our
            agent from your doorstep. It is important to provide us the
            corrective measures about your packages and location details to
            assign a suitable vehicle for the collection.
          </p>
          <v-btn
            @click="bookMyParcel()"
            color="#0674BA"
            class="white--text rounded-lg"
            x-large
            style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
          >
            BOOK MY PARCEL
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- About Us View Desktop End -->

    <!-- About Us View Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mb-10">
      <v-row style="padding-top: 80px">
        <v-col
          cols="12"
          md="6"
          style="padding-right: 30px; padding-bottom: 80px"
        >
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 14px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            ABOUT US
          </p>
          <p
            class="mb-0"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin: 0px;
            "
          >
            WELCOME TO
          </p>
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: -10px;
            "
          >
            FALCON EXPRESS
          </p>
          <v-img
            lazy-src="@/assets/image/Home/header-bar-orange.svg"
            max-height="auto"
            max-width="24%"
            style="background-position: center; margin-bottom: 15px"
            src="@/assets/image/Home/header-bar-orange.svg"
          ></v-img>
          <p
            style="
              font-family: 'Rubik-Regular';
              font-style: normal;
              font-size: 28px;
            "
          >
            Right place <br />
            for a dream delivery...
          </p>
          <p class="content-text" style="padding-right: 50px">
            Falcon Express Ceylon is one of the trending organization exists in
            the Courier industry of Sri Lanka. We comprised with an experienced
            team of professionals to provide a surpass service to our customers
            every time. Understanding the customer requirements is the key
            element that makes the delivery a success. We believe that we have
            the most convenient solution which is expected and fitted to
            nowadays customers, when satisfying their needs in the service of
            courier. Providing a fast, reliable, accountable and secure service
            to our customers while assuring their satisfaction consistently
            every day and for every delivery is the main objective. Ability of
            sharing the status of the delivery online until it reaches the
            destination is the most important and valuable strength of our
            company.
          </p>
          <v-btn
            @click="redirectToAboutUsPage()"
            color="#0674BA"
            class="white--text rounded-lg"
            x-large
            style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
          >
            READ MORE
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/Home/welcome-to-falcon-img.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/Home/welcome-to-falcon-img.jpg"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="padding-top: 50px; padding-bottom: 90px" align="center">
        <v-col cols="12" md="12" style="padding-bottom: 80px">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-bottom: 20px;
            "
          >
            PICK UP <span style="color: #0674ba">REQUEST</span>
          </p>
          <v-img
            lazy-src="@/assets/image/Home/header-bar-orange.svg"
            max-height="auto"
            max-width="24%"
            style="background-position: center; margin-bottom: 15px"
            src="@/assets/image/Home/header-bar-orange.svg"
          ></v-img>
          <p class="content-text" style="padding-right: 50px">
            Make an online request to fetch your package straight away by our
            agent from your doorstep. It is important to provide us the
            corrective measures about your packages and location details to
            assign a suitable vehicle for the collection.
          </p>
          <v-btn
          @click="bookMyParcel()"
            color="#0674BA"
            class="white--text rounded-lg"
            x-large
            style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
          >
            BOOK MY PARCEL
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/Home/welcome-to-falcon-img-2.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/Home/welcome-to-falcon-img-2.jpg"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- About Us View Mobile End -->

    <!-- Services View Desktop Start -->
    <div
      class="text-center"
      v-if="windowWidth >= 600"
      style="padding: 20px; background-color: #f4f4f4"
    >
      <v-row style="padding-top: 20px">
        <v-col cols="12" md="12" class="mt-16">
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 16px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            OUR SERVICES
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: -10px;
            "
          >
            COMPETITIVE &
            <span style="color: #0674ba">FAST SERVICES FOR YOU</span>
          </p>
          <p
            class="content-text"
            style="
              padding-right: 50px;
              margin: 0 auto;
              text-align: center;
              width: 50%;
            "
          >
            Depending on the service requirements we have clarified our services
            according to their nature as follows. <br />
            This clarification is specially made by us to provide a
            fast,competitive and quality service to our customer all the time.
          </p>
        </v-col>
      </v-row>

      <v-container>
        <v-row class="mt-12 mb-12">
          <v-col cols="12" md="12">
            <v-btn
              small
              @click="showNext"
              class="rounded-lg float-right"
              style="width: 30px; height: 45px; margin-left: 15px"
              color="#F7941D"
              ><v-icon color="white">mdi-arrow-right</v-icon></v-btn
            >
            <v-btn
              small
              @click="showBack"
              class="rounded-lg float-right"
              style="width: 30px; height: 45px"
              color="#0674BA"
              ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" md="12">
            <VueSlickCarousel v-bind="carouselSettings" ref="carousel">
              <div>
                <!-- <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                      rounded
                        class="mx-auto rounded-lg"
                        max-width="90%"
                      >
                        <v-img src="@/assets/image/falcon-about-us.png"></v-img>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            opacity="0"
                            style="background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 5%, #FF8B00 65.03%);"
                          >
                            <v-btn>See more info</v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover> -->
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover1 = true"
                  @mouseleave="imageHover1 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-1.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-1.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover1" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Documents & Packages Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(1)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  We are ready to collect your packages from your doorstep
                  without any charge and make arrangements to deliver them to
                  its specified destination within the time period of 24 hours.
                  For each and every delivery we will update the status of your
                  package online and will provide a track and trace facility
                  through our system until it reaches its destination.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover2 = true"
                  @mouseleave="imageHover2 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-2.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-2.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover2" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Same day Pick up & Delivery Services
                      </p>
                      <v-btn @click="redirectToServicePage(2)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  We do have the same day delivery service for our customers for
                  important and urgent packages. This special delivery option is
                  only offered within the short distance of branch covering
                  areas. In this scenario we will pick up the package from the
                  specified location and we’ll deliver it to the desired
                  location within the given time frame.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover3 = true"
                  @mouseleave="imageHover3 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-3.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-3.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover3" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Management of Mail Room
                      </p>
                      <v-btn @click="redirectToServicePage(3)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  Our main objective is to provide this service to enhance
                  clients’ internal flow of mail and other documents to ensure
                  better efficiency and productivity in the mail management
                  process. This would let the organization meet its
                  communication objectives while ensuring an improved customer
                  service.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover4 = true"
                  @mouseleave="imageHover4 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-4.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-4.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover4" absolute color="orange">
                      <p class="text-h5 font-weight-bold">Cash on Delivery</p>
                      <v-btn @click="redirectToServicePage(4)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  Cash on Delivery (COD) is a type of transaction in which the
                  recipient arranges the payments for purchased goods and the
                  courier charges at the point of delivery. This is a convenient
                  and secure way of selling products to customers and grabbing
                  the market share for businesses. This method will secure the
                  payment and it will make it possible to do transactions with
                  the collaboration with a single representative.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover5 = true"
                  @mouseleave="imageHover5 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-5.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-5.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover5" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Confidential Documents Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(5)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  You can send your confidential and high value documents as
                  credit cards, debit cards, tenders, deeds, Motor Certificates
                  etc. from us. We assure the safety and security of the given
                  packages and take the accountability of it. Our foremost
                  objective of this service is to protect your important and
                  valuable documents without misplacing them elsewhere.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover6 = true"
                  @mouseleave="imageHover6 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-6.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-6.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover6" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Confidential Parcel Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(6)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  There’s a possibility of sending Confidential high value items
                  like electric home appliances, mobile phones, tabs etc. from
                  us securely and safely. Also we are able to send some of the
                  fragile items in a safe and security manner but conditions
                  will be applied depending on the package details given by the
                  customer and how it was packed safely for transportation.
                </p>
              </div>
            </VueSlickCarousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Services View Desktop End -->

    <!-- Services View Mobile Start -->
    <div
      class="text-center"
      v-if="windowWidth <= 600"
      style="padding: 20px; background-color: #f4f4f4"
    >
      <v-row style="padding-top: 20px">
        <v-col cols="12" md="12" class="mt-16">
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 16px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            OUR SERVICES
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: -10px;
            "
          >
            COMPETITIVE &
            <span style="color: #0674ba">FAST SERVICES FOR YOU</span>
          </p>
          <p class="content-text" style="margin: 0 auto; text-align: center">
            Depending on the service requirements we have clarified our services
            according to their nature as follows. <br />
            This clarification is specially made by us to provide a
            fast,competitive and quality service to our customer all the time.
          </p>
        </v-col>
      </v-row>

      <v-container>
        <v-row class="mt-12 mb-12">
          <v-col cols="12" md="12">
            <v-btn
              small
              @click="showNext"
              class="rounded-lg float-right"
              style="width: 30px; height: 45px; margin-left: 15px"
              color="#F7941D"
              ><v-icon color="white">mdi-arrow-right</v-icon></v-btn
            >
            <v-btn
              small
              @click="showBack"
              class="rounded-lg float-right"
              style="width: 30px; height: 45px"
              color="#0674BA"
              ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12" md="12">
            <VueSlickCarousel v-bind="carouselMobileSettings" ref="carousel">
              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover1 = true"
                  @mouseleave="imageHover1 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-1.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-1.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover1" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Documents & Packages Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(1)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  We are ready to collect your packages from your doorstep
                  without any charge and make arrangements to deliver them to
                  its specified destination within the time period of 24 hours.
                  For each and every delivery we will update the status of your
                  package online and will provide a track and trace facility
                  through our system until it reaches its destination.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover2 = true"
                  @mouseleave="imageHover2 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-2.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-2.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover2" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Same day Pick up & Delivery Services
                      </p>
                      <v-btn @click="redirectToServicePage(2)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  We do have the same day delivery service for our customers for
                  important and urgent packages. This special delivery option is
                  only offered within the short distance of branch covering
                  areas. In this scenario we will pick up the package from the
                  specified location and we’ll deliver it to the desired
                  location within the given time frame.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover3 = true"
                  @mouseleave="imageHover3 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-3.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-3.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover3" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Management of Mail Room
                      </p>
                      <v-btn @click="redirectToServicePage(3)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  Our main objective is to provide this service to enhance
                  clients’ internal flow of mail and other documents to ensure
                  better efficiency and productivity in the mail management
                  process. This would let the organization meet its
                  communication objectives while ensuring an improved customer
                  service.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover4 = true"
                  @mouseleave="imageHover4 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-4.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-4.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover4" absolute color="orange">
                      <p class="text-h5 font-weight-bold">Cash on Delivery</p>
                      <v-btn @click="redirectToServicePage(4)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  Cash on Delivery (COD) is a type of transaction in which the
                  recipient arranges the payments for purchased goods and the
                  courier charges at the point of delivery. This is a convenient
                  and secure way of selling products to customers and grabbing
                  the market share for businesses. This method will secure the
                  payment and it will make it possible to do transactions with
                  the collaboration with a single representative.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover5 = true"
                  @mouseleave="imageHover5 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-5.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-5.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover5" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Confidential Documents Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(5)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  You can send your confidential and high value documents as
                  credit cards, debit cards, tenders, deeds, Motor Certificates
                  etc. from us. We assure the safety and security of the given
                  packages and take the accountability of it. Our foremost
                  objective of this service is to protect your important and
                  valuable documents without misplacing them elsewhere.
                </p>
              </div>

              <div>
                <v-card
                  rounded
                  class="mb-5 rounded-xl"
                  elevation="0"
                  @mouseenter="imageHover6 = true"
                  @mouseleave="imageHover6 = false"
                >
                  <v-img
                    lazy-src="@/assets/image/Services/service-c-6.jpg"
                    max-height="auto"
                    max-width="100%"
                    style="background-position: center"
                    src="@/assets/image/Services/service-c-6.jpg"
                  ></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="imageHover6" absolute color="orange">
                      <p class="text-h5 font-weight-bold">
                        Confidential Parcel Delivery
                      </p>
                      <v-btn @click="redirectToServicePage(6)"
                        >View services</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
                <p class="content-text services-text">
                  There’s a possibility of sending Confidential high value items
                  like electric home appliances, mobile phones, tabs etc. from
                  us securely and safely. Also we are able to send some of the
                  fragile items in a safe and security manner but conditions
                  will be applied depending on the package details given by the
                  customer and how it was packed safely for transportation.
                </p>
              </div>
            </VueSlickCarousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Services View Mobile End -->

    <!-- Choose us Desktop Start -->
    <div class="text-center" v-if="windowWidth >= 600">
      <v-row>
        <v-col cols="12" md="12" class="mt-16">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: 50px;
            "
          >
            WHY <span style="color: #0674ba">CHOOSE US ?</span>
          </p>
          <p
            class="content-text"
            style="
              padding-right: 50px;
              margin: 0 auto;
              text-align: center;
              width: 50%;
            "
          >
            Our company is consisting with experienced experts in the field of
            courier and we have an updated online platform to track and trace
            the progress of the package. It make possible to provide a
            convenient service to our customers.
          </p>
        </v-col>
      </v-row>

      <v-container style="margin-top: 30px">
        <v-row>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-1.png"
              max-height="auto"
              max-width="66%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-1.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Flexibility
            </p>
            <p class="content-text">Operating 24 hours for your convenience</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-2.png"
              max-height="auto"
              max-width="66%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-2.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Experts in the field
            </p>
            <p class="content-text">
              Providing the perfect courier solutions for your needs
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-3.png"
              max-height="auto"
              max-width="38%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-3.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Online Platform
            </p>
            <p class="content-text">
              You can track and trace the progress of your package via our
              online platform.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Choose us Desktop End -->

    <!-- Choose us Mobile Start -->
    <div class="text-center" v-if="windowWidth <= 600">
      <v-row>
        <v-col cols="12" md="12" class="mt-10">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 40px;
              margin-top: 50px;
            "
          >
            WHY <br />
            <span style="color: #0674ba">CHOOSE US ?</span>
          </p>
          <p
            class="content-text"
            style="
              margin: 0 auto;
              text-align: center;
              padding-left: 20px;
              padding-right: 20px;
            "
          >
            Our company is consisting with experienced experts in the field of
            courier and we have an updated online platform to track and trace
            the progress of the package. It make possible to provide a
            convenient service to our customers.
          </p>
        </v-col>
      </v-row>

      <v-container style="margin-top: 30px">
        <v-row>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-1.png"
              max-height="auto"
              max-width="66%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-1.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Flexibility
            </p>
            <p class="content-text">Operating 24 hours for your convenience</p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-2.png"
              max-height="auto"
              max-width="66%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-2.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Experts in the field
            </p>
            <p class="content-text">
              Providing the perfect courier solutions for your needs
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/Home/why-choose-3.png"
              max-height="auto"
              max-width="38%"
              style="display: block; margin-left: auto; margin-right: auto"
              src="@/assets/image/Home/why-choose-3.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-top: 20px;
                color: #0674ba;
              "
            >
              Online Platform
            </p>
            <p class="content-text">
              You can track and trace the progress of your package via our
              online platform.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Choose us Mobile End -->

    <!-- Branch network Desktop Start -->
    <div v-if="windowWidth >= 600">
      <v-container class="mt-16 mb-16" style="padding-bottom: 50px">
        <v-row align="center">
          <v-col cols="12" md="6" style="padding-right: 50px">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
              "
            >
              OUR BRANCH
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                color: #0674ba;
                margin-top: -20px;
              "
            >
              NETWORK
            </p>
            <v-img
              lazy-src="@/assets/image/Home/header-bar-orange.svg"
              max-height="auto"
              max-width="14%"
              style="background-position: center; margin-bottom: 15px"
              src="@/assets/image/Home/header-bar-orange.svg"
            ></v-img>
            <p class="content-text">
              Our hubs are placed in strategic locations and our extensive
              agents are equipped with the best technology to offer our
              customers a better service.
            </p>
            <v-btn
              @click="redirectToOurBranchPage()"
              color="#0674BA"
              class="white--text rounded-lg"
              x-large
              style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
            >
              OUR BRANCHES
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-card rounded class="rounded-xl">
              <v-img
                lazy-src="@/assets/image/Home/gis-image.jpg"
                max-height="auto"
                max-width="100%"
                src="@/assets/image/Home/gis-image.jpg"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Branch network Desktop End -->

    <!-- Branch network Mobile Start -->
    <div v-if="windowWidth <= 600">
      <v-container class="mt-10 mb-16" style="padding-bottom: 50px">
        <v-row align="center">
          <v-col
            cols="12"
            md="6"
            style="padding-right: 50px; padding-bottom: 80px"
          >
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
              "
            >
              OUR BRANCH
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                color: #0674ba;
                margin-top: -20px;
              "
            >
              NETWORK
            </p>
            <v-img
              lazy-src="@/assets/image/Home/header-bar-orange.svg"
              max-height="auto"
              max-width="24%"
              style="background-position: center; margin-bottom: 15px"
              src="@/assets/image/Home/header-bar-orange.svg"
            ></v-img>
            <p class="content-text">
              Our hubs are placed in strategic locations and our extensive
              agents are equipped with the best technology to offer our
              customers a better service.
            </p>
            <v-btn
              @click="redirectToOurBranchPage()"
              color="#0674BA"
              class="white--text rounded-lg"
              x-large
              style="font-family: 'Rubik'; font-style: normal; font-size: 14px"
            >
              OUR BRANCHES
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-card rounded class="rounded-xl">
              <v-img
                lazy-src="@/assets/image/Home/gis-image.jpg"
                max-height="auto"
                max-width="100%"
                src="@/assets/image/Home/gis-image.jpg"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Branch network Mobile End -->

    <!-- Testimonials Desktop Start -->
    <!-- <v-sheet
      v-if="windowWidth >= 600"
      class="mt-0 text-center clientSaidAboutUsImg"
      max-width="100%"
      color="#112548"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12" class="mt-16">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                color: #ffffff;
                padding-bottom: 130px;
              "
            >
              WHATS OUR CLIENTS SAID<br />
              ABOUT US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet> -->
    <!-- <v-container>
      <v-window
        v-if="windowWidth >= 600"
        show-arrows
        continuous
        reverse
        style="
          margin-top: -100px;
          padding-bottom: 50px;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            small
            class="rounded-lg"
            style="width: 30px; height: 45px; margin-left: -15px"
            color="#0674BA"
            v-bind="attrs"
            v-on="on"
            ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
          >
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            small
            class="rounded-lg float-right"
            style="width: 30px; height: 45px; margin-right: -15px"
            color="#F7941D"
            v-bind="attrs"
            v-on="on"
            ><v-icon color="white">mdi-arrow-right</v-icon></v-btn
          >
        </template>
        <v-window-item
          v-for="n in 5"
          :key="`card-${n + 1}`"
          style="padding: 0 32px"
        >
          <v-card
            v-if="windowWidth >= 600"
            rounded
            class="mx-auto text-center mb-16 rounded-xl"
            color="#F2F2F2"
          >
            <v-container>
              <v-row>
                <v-col cols="12" md="12" class="mt-16">
                  <v-avatar size="128" color="grey">
                    <v-avatar size="100">
                      <img
                        src="https://cdn.vuetifyjs.com/images/john.jpg"
                        alt="John"
                      />
                    </v-avatar>
                  </v-avatar>
                  <p
                    style="
                      font-family: 'Rubik';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      color: #112548;
                      margin-top: 20px;
                    "
                  >
                    SHEHAN GALAHITIYAWA
                  </p>
                  <p
                    style="
                      font-family: 'Rubik-regular';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 13px;
                      color: #112548;
                    "
                  >
                    FOUNDER / CEO - CENTRAL EXPORT
                  </p>
                  <p
                    class="text-subtitle-2 font-weight-bold"
                    style="color: #112548"
                  >
                    <v-icon color="#F7941D">mdi-star</v-icon>
                    <v-icon color="#F7941D">mdi-star</v-icon>
                    <v-icon color="#F7941D">mdi-star</v-icon>
                    <v-icon color="#F7941D">mdi-star</v-icon>
                    <v-icon color="#F7941D">mdi-star</v-icon>
                  </p>
                  <p
                    style="margin: 0 auto; text-align: center; width: 80%"
                    class="content-text"
                  >
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
                    iriure dolor in hendrerit in vulputate velit esse molestie
                    consequat, vel illum dolore eu feugiat nulla facilisis at
                    vero eros et accumsan et iusto odio dignissim qui blandit
                    praesent luptatum zzril delenit augue duis dolore te feugait
                    nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer
                    adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                    laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                    minim veniam, quis nostrud exerci tation ullamcorper
                    suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-window-item>
      </v-window>
    </v-container> -->
    <!-- Testimonials Desktop End -->

    <!-- Testimonials Mobile Start -->
    <!-- <v-sheet
      v-if="windowWidth <= 600"
      class="mt-0 text-center clientSaidAboutUsImg"
      max-width="100%"
      color="#112548"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12" class="mt-16">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                color: #ffffff;
                padding-bottom: 130px;
              "
            >
              WHATS <br />
              OUR CLIENTS <br />
              SAID<br />
              ABOUT US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-window
      v-if="windowWidth <= 600"
      show-arrows
      continuous
      reverse
      style="margin-top: -100px; padding-bottom: 50px"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          small
          class="rounded-lg"
          style="width: 30px; height: 45px"
          color="#0674BA"
          v-bind="attrs"
          v-on="on"
          ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          small
          class="rounded-lg float-right"
          style="width: 30px; height: 45px"
          color="#F7941D"
          v-bind="attrs"
          v-on="on"
          ><v-icon color="white">mdi-arrow-right</v-icon></v-btn
        >
      </template>
      <v-window-item v-for="n in 5" :key="`card-${n + 1}`">
        <v-card
          v-if="windowWidth <= 600"
          rounded
          class="mx-auto text-center mb-10 rounded-xl"
          max-width="90%"
          color="#F2F2F2"
        >
          <v-container>
            <v-row>
              <v-col cols="12" md="12" class="mt-16">
                <v-avatar size="128" color="grey">
                  <v-avatar size="100">
                    <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                    />
                  </v-avatar>
                </v-avatar>
                <p
                  style="
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    color: #112548;
                    margin-top: 20px;
                  "
                >
                  SHEHAN GALAHITIYAWA
                </p>
                <p
                  style="
                    font-family: 'Rubik-regular';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    color: #112548;
                  "
                >
                  FOUNDER / CEO - CENTRAL EXPORT
                </p>
                <p
                  class="text-subtitle-2 font-weight-bold"
                  style="color: #112548"
                >
                  <v-icon color="#F7941D">mdi-star</v-icon>
                  <v-icon color="#F7941D">mdi-star</v-icon>
                  <v-icon color="#F7941D">mdi-star</v-icon>
                  <v-icon color="#F7941D">mdi-star</v-icon>
                  <v-icon color="#F7941D">mdi-star</v-icon>
                </p>
                <p
                  style="margin: 0 auto; text-align: center; width: 70%"
                  class="content-text"
                >
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velit esse molestie consequat,
                  vel illum dolore eu feugiat nulla facilisis at vero eros et
                  accumsan et iusto odio dignissim qui blandit praesent luptatum
                  zzril delenit augue duis dolore te feugait nulla facilisi.
                  Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-window-item>
    </v-window> -->
    <!-- Testimonials Mobile End -->

    <!-- Final Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="mt-0 catsandstars"
      max-width="100%"
      color="#112548"
    >
      <v-container>
        <v-row class="mb-16">
          <v-col cols="12" md="6" class="mt-16">
            <v-row no-gutters>
              <v-col cols="12" md="2" class="mt-16 mr-5">
                <!-- <v-icon color="#F7941D" size="100">mdi-headset</v-icon> -->
                <v-img
                  lazy-src="@/assets/image/Home/request-callback-icon.svg"
                  max-height="auto"
                  max-width="100%"
                  style="background-position: center"
                  src="@/assets/image/Home/request-callback-icon.svg"
                ></v-img>
                <br />
                <!-- <v-icon size="50" color="#0674BA" class="ml-5"
                  >mdi-color-helper</v-icon
                > -->
                <v-img
                  lazy-src="@/assets/image/Home/request-callback-bar.svg"
                  max-height="auto"
                  max-width="100%"
                  style="background-position: center"
                  src="@/assets/image/Home/request-callback-bar.svg"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6" class="mt-16">
                <p
                  class="text-h3 font-weight-bold float-left"
                  style="color: #ffffff"
                >
                  REQUEST <br />
                  A CALLBACK
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="mt-5 mr-5">
                <p class="content-text" style="color: white">
                  Make an inquiry and send us, we’ll call you back, Monday to
                  Friday 9am – 5pm, Easy
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="mt-16">
            <v-form ref="form" >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  placeholder="FULL NAME *"
                  v-model="contact.full_name"
                  solo
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contact.email"
                  placeholder="E-MAIL ADDRESS *"
                  solo
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-text-field
                  placeholder="PHONE *"
                  v-model="contact.phone"
                  solo
                  :rules="phoneRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-textarea
                  placeholder="MESSAGE *"
                  v-model="contact.message"
                  solo
                   :rules="messageRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-btn
                  @click="sendMessage"
                  color="#0674BA"
                  class="white--text"
                  large
                  block
                >
                  SEND MESSAGE
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Final Desktop End -->

    <!-- Final Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="mt-0 catsandstars"
      max-width="100%"
      color="#112548"
    >
      <v-container>
        <v-row class="mb-16">
          <v-col cols="12" md="6" class="mt-16">
            <v-row no-gutters>
              <v-col cols="auto" sm="2" class="mt-10 mr-5">
                <!-- <v-icon color="#F7941D" size="100">mdi-headset</v-icon> -->
                <v-img
                  lazy-src="@/assets/image/Home/request-callback-icon.svg"
                  max-height="auto"
                  max-width="100%"
                  style="background-position: center"
                  src="@/assets/image/Home/request-callback-icon.svg"
                ></v-img>
                <br />
                <!-- <v-icon size="50" color="#0674BA" class="ml-5"
                  >mdi-color-helper</v-icon
                > -->
                <v-img
                  lazy-src="@/assets/image/Home/request-callback-bar.svg"
                  max-height="auto"
                  max-width="100%"
                  style="background-position: center"
                  src="@/assets/image/Home/request-callback-bar.svg"
                ></v-img>
              </v-col>
              <v-col cols="auto" sm="6" class="mt-10">
                <p
                  class="text-h3 font-weight-bold float-left"
                  style="color: #ffffff"
                >
                  REQUEST <br />
                  A CALLBACK
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="mt-5 mr-5">
                <p class="content-text" style="color: white">
                  Make an inquiry and send us, we’ll call you back, <br />
                  Monday to Friday 9am – 5pm, Easy
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="mt-5">
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="contact.full_name"
                  placeholder="FULL NAME *"
                  solo
                   :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  placeholder="E-MAIL ADDRESS *"
                  solo
                  v-model="contact.email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="contact.phone"
                  placeholder="PHONE *"
                  solo
                  :rules="phoneRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="contact.message"
                  placeholder="MESSAGE *"
                  solo
                  :rules="messageRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-btn
                  @click="sendMessage"
                  color="#0674BA"
                  class="white--text"
                  x-large
                  block
                >
                  SEND MESSAGE
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Final Mobile End -->

    <FooterView />
    <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <span class="mdi mdi-close-circle"></span>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorSnack" :timeout="timeout" color="red">
      {{ snackbar_text }}
      
    </v-snackbar>
  </div>
</template>

<script>
//
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AppBar from "./AppBar.vue";
import CarouselView from "./CarouselView.vue";
import FooterView from "./FooterView.vue";
import axios from "axios";

export default {
  name: "PublicHome",
  components: {
    AppBar,
    CarouselView,
    VueSlickCarousel,
    FooterView,
    // AdminDashboard
  },

  data() {
    return {
      imageHover1: false,
      imageHover2: false,
      imageHover3: false,
      imageHover4: false,
      imageHover5: false,
      imageHover6: false,
      carouselSettings: {
        arrows: false,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        centerPadding: "20px",
      },
      carouselMobileSettings: {
        arrows: false,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerPadding: "20px",
      },
      model: null,
      windowWidth: window.innerWidth,
      userNav: null,
      track_order_code: null,
      contact: {
        full_name: "",
        email: "",
        phone: "",
        message: "",
      },
      errors: {
        full_name: "",
        email: "",
        phone: "",
        message: "",
      },
      snackbar: false,
      errorSnack: false,
      snackbar_text: "",
      timeout: 2000,
      nameRules: [
  v => !!v || 'Name is required',
],
      emailRules: [
  v => !!v || 'Email is required',
],
      phoneRules: [
  v => !!v || 'Phone is required',
],
      messageRules: [
  v => !!v || 'Message is required',
],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleEnterPress(){
      this.orderTrack();
    },
    sendMessage() {
      if(!this.$refs.form.validate()) return;
         this.snackbar_text = "";
      this.errorSnack = false;
      this.snackbar = false;
      axios
        .post(this.$hostname + "/api/client/contact-request", this.contact)
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            this.snackbar = true;
            this.snackbar_text = response.data.message;
            this.clearContactForm();
          } else {
            this.errorSnack = true;
          }
        })
        .catch((error) => {
          this.errorSnack = true;
          this.snackbar_text = error.response.data.message;
        });
    },
    changed(e) {
      console.log(e);
    },
    clearContactForm() {
      this.contact.full_name = null;
      this.contact.email = null;
      this.contact.phone = null;
      this.contact.message = null;
    },
    changeHoverEffect(number) {
      switch (number) {
        case 1:
          this.imageHover1 = true;
          break;

        default:
          break;
      }
    },
    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showBack() {
      this.$refs.carousel.prev();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    redirectToAboutUsPage() {
      this.$router.push({ name: "AboutUs" });
    },
    redirectToOurBranchPage() {
      this.$router.push({ name: "BranchNetwork" });
    },
    bookMyParcel() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        this.$router.push({ name: "AddPackages" });
      } else {
        this.$router.push({ name: "LoginScreen" });
      }
    },

    orderTrack() {
      this.$router.push({
        name: "OrderTrack",
        query: { track_code: this.track_order_code },
      });
    },
    // logOut() {
    //   let user = localStorage.getItem("userDetails");
    //   user = JSON.parse(user);
    //   if (user) {
    //     localStorage.removeItem("userDetails");
    //     this.$router.push({ name: "home" });
    //   } else {
    //     this.$router.push({ name: "LoginScreen" });
    //   }
    // },
  },
};
</script>

<style>
.catsandstars {
  background-image: url("@/assets/image/Home/request-callback-mask.png");
  background-color: transparent;
  background-size: cover;
}
.clientSaidAboutUsImg {
  background-image: url("@/assets/image/Home/client-said-aboutus-img.png");
  background-color: transparent;
  background-size: cover;
}
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
.rubik-fw600-48 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  margin-top: 20px;
}
.rubik-fw600-16-grey {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: rgba(17, 37, 72, 0.5);
}
.rubik-fw600-16-ls {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  letter-spacing: 0.2em;
}

.hoverCardImg:hover {
  backdrop-filter: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 1)
  );
}

.slick-slider {
  margin: 0 -25px;
}

.slick-slide {
  padding: 0 25px;
}

.error-text {
  color: red;
}
</style>
