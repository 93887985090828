<template>
  <v-app id="inspire">
    <AppBar NavIcon="NavIcon"> </AppBar>
    <div style="background: #e6ebef; text-align: center">
      <v-container>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-card elevation="4">
              <v-row>
                <v-col cols="12">
                  <div class="card-title">
                    <p class="card-title-text">Barcode Ranges</p>
                    <v-btn depressed @click="openRequestModal()" color="primary"
                      >Add New Request</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="8">
              <v-card-title>Pending Requests</v-card-title>
              <v-data-table
                :headers="requests_headers"
                :items="requests"
                hide-default-footer
              disable-pagination

              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="red"
                    elevation="2"
                    @click="showDeletePrompt(item)"
                    outlined
                    rounded
                    x-small
                  >
                    Delete
                  </v-btn>
                  &nbsp;
                  <v-btn
                    color="blue"
                    elevation="2"
                    @click="openEditModal(item)"
                    rounded
                    outlined
                    x-small
                  >
                    Edit
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="8">
              <v-card-title>Assigned Ranges</v-card-title>
              <v-data-table
                :headers="ranges_headers"
                :items="ranges"
                hide-default-footer
              disable-pagination

              >
                <template v-slot:[`item.range`]="{ item }">
                  {{ item.from + " - " + item.to }}
                </template>
                <template v-slot:[`item.remaining`]="{ item }">
                  {{ item.used_maximum > 0 ? item.to - item.used_maximum : item.to - item.from }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="item.to <= item.used_maximum"
                    class="ma-2"
                    color="red"
                    text-color="white"
                  >
                    Finished
                  </v-chip>
                  <v-chip v-else class="ma-2" color="green" text-color="white">
                    Available
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!--view modal open-->
        <v-row justify="center">
          <v-dialog v-model="requestModalShow" persistent max-width="50%">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  form.id ? "Edit Request" : "Create New Request"
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="form.description"
                        label="Your Description"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeModal">
                  Close
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="form.id ? edit() : submit()"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <!--view modal close-->
      </v-container>
    </div>
    <FooterView />
  </v-app>
</template>

<!-- 1eslint-disable -->
<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
export default {
  name: "BarcodeComponent",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      form: {
        id: null,
        description: null,
      },
      requests_headers: [
        { text: "Description", value: "description" },
        { text: "Requested At", value: "created_at" },
        { text: "Action", value: "actions" },
      ],
      ranges_headers: [
        { text: "Range", value: "range" },
        { text: "Used Last NUmber", value: "used_maximum" },
        { text: "Remaining Count", value: "remaining" },
        { text: "Assigned At", value: "assigned_at" },
        { text: "Status", value: "status", align: "center" },
      ],
      requestModalShow: false,
      requests: [],
      ranges: [],
    };
  },
  created() {
    this.setToken();
    this.getRequests();
    this.getRanges();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async getRequests() {
      axios
        .get(this.$hostname + "/api/client/barcode-requests/get-all?status=1")
        .then((response) => {
          this.requests = response.data.data;
        })
        .catch((error) => {
          const message = this.getErrorMessage(error);
          this.showError(message);
        });
    },
    async getRanges() {
      axios
        .get(
          this.$hostname +
            "/api/client/barcode-ranges/get-all?assign_type=manual"
        )
        .then((response) => {
          this.ranges = response.data.data;
        });
    },
    formClear() {
      this.form.id = null;
      this.form.description = null;
    },
    async submit() {
      if(this.form.description == null) return;
      axios
        .post(this.$hostname + "/api/client/barcode-requests/store", this.form)
        .then(() => {
          this.closeModal();
          this.formClear();
          this.getRequests();
          this.showSuccess("Your request has been submitted.", "Submitted!");
        })
        .catch((error) => {
          const message = this.getErrorMessage(error);
          this.showError(message);
        });
    },
    async edit() {
      axios
        .put(
          this.$hostname +
            "/api/client/barcode-requests/update/" +
            this.form.id,
          this.form
        )
        .then(() => {
          this.getRequests();
          this.closeModal();
          this.formClear();
          this.showSuccess("Your request has been updated.", "Updated!");
        })
        .catch((error) => {
          const message = this.getErrorMessage(error);
          this.showError(message);
        });
    },
    showDeletePrompt(item) {
      this.$swal
        .fire({
          title: "Do you want to delete this request?",
          showDenyButton: true,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Delete",
          denyButtonText: `Cancel`,
          focusDeny: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteRequest(item);
          }
        });
    },
    async deleteRequest(item) {
      axios
        .delete(
          this.$hostname + "/api/client/barcode-requests/destroy/" + item.id
        )
        .then(() => {
          this.showSuccess("Your request has been deleted.", "Deleted!");
          this.getRequests();
        })
        .catch((error) => {
          const message = this.getErrorMessage(error);
          this.showError(message);
        });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    openEditModal(item) {
      this.form.description = item.description;
      this.form.id = item.id;
      this.requestModalShow = true;
    },
    openRequestModal() {
      this.formClear();
      this.requestModalShow = true;
    },
    closeModal() {
      this.requestModalShow = false;
      setTimeout(() => {
        this.formClear();
      }, 200);
    },
    getErrorMessage(error) {
      if (error?.response?.data?.message) return error?.response?.data?.message;
      return "Something wrong";
    },
  },
};
</script>
<style scoped>
.card-title {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.card-title-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}
</style>
