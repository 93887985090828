<template>
  <div>
    <AppBar />
    <div style="background: #e6ebef; text-align: center;">
      <v-container>
        <v-card
          elevation="0"
          style="margin-top: 70px; margin-bottom: 70px; padding-bottom: 20px;"
          rounded
          class="rounded-xl"
        >
          <v-row style="padding-top: 10px;" align="center">
            <v-col cols="12" md="12" class="text-center logo-section">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-img
                  lazy-src="@/assets/image/falcon-icon.png"
                  style="margin: 25px auto;"
                  max-height="auto"
                  max-width="200px"
                  src="@/assets/image/falcon-icon.png"
                ></v-img>
              </div>

              <p
                style="
                  color: #0674ba;
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 38px;
                  text-transform: uppercase;
                "
              >
                Pay as Receiver
              </p>
            </v-col>
            <v-col v-if="errorAlertText == '' && successAlertText == '' && !loading"  cols="12" md="12" class="text-center success-section">
              <p class="payment-description">
                You are going to make the payment for following package as the
                receiver.
              </p>
              <p class="package-barcode">
                Barcode : {{ order_package.barcode }}
              </p>
              <p class="payment-sub">
                <v-checkbox
                  v-model="verified"
                  label="Barcode double checked and verified"
                  color="blue"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </p>
              <v-btn
                :disabled="loading || !verified"
                @click="makePayment()"
                color="#0674BA"
                class="white--text rounded-lg"
                x-large
                style="
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 14px;
                  text-transform: uppercase;
                  padding-left: 70px;
                  padding-right: 70px;
                "
              >
                <span>Pay Now </span>

                <span v-if="loading"
                  >&nbsp;<i class="fa-sharp fa-spin fa-solid fa-arrows-spin"></i
                ></span>
              </v-btn>
            </v-col>
            <v-col v-if="errorAlertText != '' && successAlertText == '' && !loading" cols="12" md="12" class="text-center success-section">
              <v-icon style="font-size: 130px;color: #d95050;" class="error-icon" large>mdi-car-brake-alert </v-icon>
              <h4 class="error-heading">Error</h4>
              <p class="error-text">
                {{errorAlertText}}
              </p>
            </v-col>
            <v-col  v-if="successAlertText != '' && !loading" cols="12" md="12" class="text-center success-section">
              <v-icon style="font-size: 130px;color: #3daa42ee;" class="error-icon" large>mdi-cash-check  </v-icon>
              <h4 class="success-heading">Paid</h4>
              <p class="success-text">
                {{successAlertText}}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <form
              v-show="false"
              ref="paymentForm"
              method="post"
              :action="getPayHereUrl"
            >
              <input
                type="hidden"
                name="merchant_id"
                v-model="order_package.merchant"
              />
              <!-- Replace your Merchant ID -->
              <input type="hidden" name="hash" v-model="order_package.hash" />

              <input
                type="hidden"
                name="return_url"
                value="https://falcon.lk"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="https://falcon.lk"
              />
              <input
                type="hidden"
                name="notify_url"
                value="https://backend.falcon.lk/payment-gateway/notify-receiver-pay"
              />

              <input
                type="text"
                name="order_id"
                v-model="order_package.barcode"
              />
              <input type="text" name="items" v-model="order_package.barcode" />
              <input type="text" name="custom_1" value="receiver_page" />
              <input type="text" name="currency" value="LKR" />
              <input
                type="text"
                name="amount"
                v-model="order_package.total_amount"
              />

              <input
                type="text"
                name="first_name"
                v-model="order_package.receiver_name"
              />
              <input
                type="text"
                name="last_name"
                v-model="order_package.barcode"
              />
              <input
                type="text"
                name="email"
                :value="order_package.receiver_email"
              />
              <input
                type="text"
                name="phone"
                :value="order_package.receiver_phone"
              />
              <input
                type="text"
                name="address"
                :value="order_package.receiver_address"
              />
              <input
                type="text"
                name="city"
                :value="order_package.delivery_city_name"
              />
              <input type="hidden" name="country" value="Sri Lanka" />
            </form>
          </v-row>
        </v-card>
      </v-container>
    </div>

    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
import setAuthHeader from "@/utils/setAuthHeader";
import axios from "axios";

export default {
  name: "OrderTrack",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      loading: false,
      verified: false,
      errorAlertText: "",
      successAlertText: "",
      barcode: this.$route.query?.barcode,
      order_package: {},
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
  },
  computed: {
    getPayHereUrl() {
      return "https://www.payhere.lk/pay/checkout";
      // return "https://sandbox.payhere.lk/pay/checkout";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log(this.barcode);
    if (this.barcode) {
      this.loadPackage();
    }
  },
  methods: {
    clear() {},
    makePayment() {
      this.$refs.paymentForm.submit();
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },

    loadPackage() {
      this.loading = true;
      axios
        .get(
          this.$hostname + "/api/client/package/get-by-barcode/" + this.barcode
        )
        .then((response) => {
          if (!response.data.package) {
            return this.errorAlertText = "Invalid barcode";
          }
          if (response.data.package.payment_exists) {
            return this.successAlertText = "Payment completed";
          }
          if (response.data.package.pay_by_text != 'receiver') {
            return this.errorAlertText = "Payment type is invalid";
          }
          this.order_package = response.data.package;
        })
        .catch(() => {
          this.clear();
          this.errorAlertText = "Invalid package";
          this.scrollToTop();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.payment-description {
  font-size: 16px;
  font-weight: 300;
}
.payment-sub {
  font-weight: 500;
  margin-top: 8px;
  line-height: 10px;
  padding: 0;
}
.package-barcode {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-input--selection-controls {
  margin: 0;
}

.logo-section{
  padding: 0 ;
}

.success-section{
  padding: 0 0 20px 0;
}

.error-heading {
  color: #d95050;
  font-size: 25px;
}

.error-text {
  color: #d95050;
}

.success-heading {
  color: #008f18b8;
  font-size: 25px;
}

.success-text {
  color: #008f18b8;
}

</style>
