<template>
  <div>
    <v-card rounded dark class="rounded-xl pb-5" color="#212529">
      <v-card-title class="font-weight-bold text-h5 ml-1"
        >Our Main Services</v-card-title
      >
      <v-sheet
        rounded
        :class="
          selectService == 1 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 1 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(1)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Documents & Packages Delivery
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <v-icon
                :color="selectService == 1 ? 'white' : 'black'"
                class="float-right mt-5"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          selectService == 2 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 2 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(2)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Same day Pick up & Delivery Services
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <v-icon
                class="float-right mt-5"
                :color="selectService == 2 ? 'white' : 'black'"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          selectService == 3 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 3 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(3)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Management of Mail Room
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <v-icon
                class="float-right mt-5"
                :color="selectService == 3 ? 'white' : 'black'"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          selectService == 4 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 4 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(4)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="9">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Cash on Delivery (COD)
              </p>
            </v-col>
            <v-col cols="6" md="3">
              <v-icon
                class="float-right mt-5"
                :color="selectService == 4 ? 'white' : 'black'"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          selectService == 5 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 5 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(5)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Confidential Documents Delivery
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <v-icon
                class="float-right mt-5"
                :color="selectService == 5 ? 'white' : 'black'"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          selectService == 6 ? 'ml-2 mr-2 mb-2' : 'ml-2 mr-2 mb-2 black--text'
        "
        :color="selectService == 6 ? '#0674BA' : 'white'"
        @click="redirectToServicePage(6)"
        style="cursor: pointer"
      >
        <v-card-title class="text-caption">
          <v-row no-gutters dense>
            <v-col cols="6" md="8">
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  letter-spacing: 0.8px;
                  word-break: break-word;
                "
              >
                Confidential Parcel Delivery
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <v-icon
                class="float-right mt-5"
                :color="selectService == 6 ? 'white' : 'black'"
                >mdi-arrow-right-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import social_links from '@/config';

export default {
  name: "OurMainServiceSidePanelMobile",

  data() {
    return {
      windowWidth: window.innerWidth,
      selectService: 0,
      servicesList: [
        { title: "Documents & Packages Delivery" },
        { title: "Same day Pick up & Delivery Services" },
        { title: "Management of Mail Room" },
        { title: "Cash on Delivery (COD)" },
        { title: "Confidential Documents Delivery" },
        { title: "Confidential Parcel Delivery" },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.selectServicePage(this.$route.name);
  },
  methods: {
    selectServicePage(name) {
      switch (name) {
        case "DocumentPackageDelivery":
          this.selectService = 1;
          break;
        case "SamedayPickupDeliveryServices":
          this.selectService = 2;
          break;
        case "ManagementofMailRoom":
          this.selectService = 3;
          break;
        case "CashOnDelivery":
          this.selectService = 4;
          break;
        case "ConfidentialDocumentsDelivery":
          this.selectService = 5;
          break;
        case "ConfidentialParcelDelivery":
          this.selectService = 6;
          break;

        default:
          break;
      }
    },

    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },

    redirectToHomePage() {
      this.$router.push({ name: "home" });
    },

    redirectToContactUsPage() {
      this.$router.push({ name: "ContactUs" });
    },

    redirectToBranchNetworkPage() {
      this.$router.push({ name: "BranchNetwork" });
    },

    redirectToAboutUsPage() {
      this.$router.push({ name: "AboutUs" });
    },
    redirectToFAQPage() {
      this.$router.push({ name: "FAQDetails" });
    },
    redirectToOrderTrack() {
      this.$router.push({ name: "OrderTrack" });
    },

    redirectToServicePage1() {
      this.$router.push({ name: "DocumentPackageDelivery" });
    },

    redirectToHome() {
      window.location.href = "/";
    },
    onResize(e) {
      this.windowWidth = window.innerWidth;
      console.log(e);
    },
    redirectSocialMedia(number) {
      switch (number) {
        case 1:
          window.open(social_links.facebook, "_blank");
          break;
        case 2:
          window.open("https://www.instagram.com/?hl=en", "_blank");
          break;
        case 3:
          window.open("https://lk.linkedin.com/", "_blank");
          break;
        case 4:
          window.open("https://www.youtube.com/", "_blank");
          break;

        default:
          break;
      }
    },
    redirectMailTo() {
      window.location.href = "mailto:info@falcondelivery.com";
    },
    redirectTel() {
      window.location.href = "tel:0123526589";
    },
  },
};
</script>

<style scoped>
.rubik-fw600-15 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.my-menu {
  margin-top: 30px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 90%;
  left: 2%;
  transform: translateY(-80%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.listItem:hover {
  background-color: #0674ba;
  color: white !important;
}
</style>
