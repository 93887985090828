<template>
  <div>
    <span
      >{{ barcode }}
      <HasExchange :show="has_exchange" />
      <ExchangedPackage :show="child_exchange" />
      <SenderReturn :show="sender_return" />
    </span>
  </div>
</template>
<script>
import SenderReturn from "./SenderReturn.vue";
import HasExchange from "./HasExchange.vue";
import ExchangedPackage from "./ExchangedPackage.vue";

export default {
  props: ["barcode", "stage_return", "sender_return","has_exchange","child_exchange"],
  name: "CodeColumn",
  components: { SenderReturn,HasExchange,ExchangedPackage },
};
</script>
<style>
.badge {
  border-radius: 4px;
  padding: 0 10px;
  margin: 0 5px 0 0;
  white-space: nowrap;
}
</style>
