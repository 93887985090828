<template>
  <div style="overflow-x: hidden">
    <AppBar />
    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="aboutUsBackgroundImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">About Us</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> About US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="aboutUsBackgroundMobileImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">About Us</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> About US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16">
      <v-row style="padding-top: 50px; padding-bottom: 50px" align="center">
        <v-col cols="12" md="6">
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 13px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            COMPANY
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            Provide a Reliable
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 20px;
            "
          >
            Services <span style="color: #0674ba">Since 2021</span>
          </p>
          <p class="content-text" style="padding-right: 50px">
            Falcon Express Ceylon (Pvt) Ltd has been incorporated as a private
            company with limited liability having complied with the requirements
            of the Companies Act No. 07 of 2007 on 31st of August 2021.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/AboutUs/img-1.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/AboutUs/img-1.jpg"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-16 mb-16">
      <v-row style="padding-top: 10px; padding-bottom: 10px" align="center">
        <v-col cols="12" md="6">
          <p
            style="
              color: #f7941d;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 15px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            COMPANY
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            Provide a Reliable
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 10px;
            "
          >
            Services
            <span style="color: #0674ba">
              <br />
              Since 2021</span
            >
          </p>
          <p
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 15px;
              letter-spacing: 0.8px;
              padding-right: 50px;
            "
          >
            Falcon Express Ceylon (Pvt) Ltd has been incorporated as a private
            company with limited liability having complied with the requirements
            of the Companies Act No. 07 of 2007 on 31st of August 2021.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-card rounded class="rounded-xl">
            <v-img
              lazy-src="@/assets/image/AboutUs/img-1.jpg"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/AboutUs/img-1.jpg"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="aboutUsBackgroundImgCover2"
      max-width="100%"
      max-height="auto"
    >
      <v-container class="mt-16 mb-16">
        <v-row style="padding-top: 50px" align="center">
          <v-col cols="12" md="6">
            <v-img
              lazy-src="@/assets/image/AboutUs/cover-2-img.png"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/AboutUs/cover-2-img.png"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6" style="padding-left: 50px">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: -10px;
              "
            >
              Moving Your
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: -10px;
              "
            >
              Products Across
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              <span style="color: #0674ba">All Borders</span>
            </p>
            <p class="content-text" style="padding-right: 50px">
              Our foremost duty is to deliver your packages to its specified
              destination on time. We are consist of strengthen transport &
              logistic facilities and with experienced team of professionals to
              provide a remarkable service to our customers.
            </p>
            <v-btn
              color="#0674BA"
              class="white--text rounded-lg"
              x-large
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-size: 14px;
                text-transform: uppercase;
              "
              @click="redirectServicePage1()"
            >
              Our main Services
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="aboutUsBackgroundImgCover2"
      max-width="100%"
      max-height="auto"
    >
      <v-container class="mt-16 mb-16">
        <v-row style="padding-top: 50px; padding-bottom: 50px">
          <v-col cols="12" md="6">
            <v-img
              lazy-src="@/assets/image/AboutUs/cover-2-img.png"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/AboutUs/cover-2-img.png"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: -10px;
              "
            >
              Moving
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: -10px;
              "
            >
              Your Products Across
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              <span style="color: #0674ba">All Borders</span>
            </p>
            <p
              style="
                color: black;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                letter-spacing: 0.8px;
                padding-right: 50px;
              "
            >
              Our foremost duty is to deliver your packages to its specified
              destination on time. We are consist of strengthen transport &
              logistic facilities and with experienced team of professionals to
              provide a remarkable service to our customers.
            </p>
            <v-btn
              color="#0674BA"
              class="white--text rounded-lg"
              x-large
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-size: 14px;
                text-transform: uppercase;
              "
              @click="redirectServicePage1()"
            >
              Our main Services
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16">
      <v-row style="padding-top: 50px" align="center">
        <v-col cols="12" md="6">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            The line between
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            disorder and
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            <span style="color: #0674ba">order lies in</span>
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 20px;
            "
          >
            <span style="color: #0674ba">logistics…</span>
          </p>
          <p class="content-text" style="padding-right: 50px">
            As a courier company our main objective is to satisfy our customers
            by collecting and delivering their packages to specified locations
            on time.
          </p>
          <p class="content-text" style="padding-right: 50px">
            As one of the leading results oriented organization in the industry
            of courier, we believe that managing the logistic resources in an
            appropriate manner will allow us to achieve these goals easily and
            satisfy our customers by providing super service optimistically.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            lazy-src="@/assets/image/AboutUs/img-3.png"
            max-height="auto"
            max-width="100%"
            style="background-position: center"
            src="@/assets/image/AboutUs/img-3.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-2 mb-16">
      <v-row style="padding-top: 5px">
        <v-col cols="12" md="6">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            The line between
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            disorder and
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: -10px;
            "
          >
            <span style="color: #0674ba">order lies in</span>
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 20px;
            "
          >
            <span style="color: #0674ba">logistics…</span>
          </p>
          <p
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 15px;
              letter-spacing: 0.8px;
              padding-right: 50px;
            "
          >
            As a courier company our main objective is to satisfy our customers
            by collecting and delivering their packages to specified locations
            on time.
          </p>
          <p
            style="
              color: black;
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 800;
              font-size: 15px;
              letter-spacing: 0.8px;
              padding-right: 50px;
            "
          >
            As one of the leading results oriented organization in the industry
            of courier, we believe that managing the logistic resources in an
            appropriate manner will allow us to achieve these goals easily and
            satisfy our customers by providing super service optimistically.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            lazy-src="@/assets/image/AboutUs/img-3.png"
            max-height="auto"
            max-width="100%"
            style="background-position: center"
            src="@/assets/image/AboutUs/img-3.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      style="padding: 20px; background-color: #eaebed"
    >
      <v-container class="mt-16 mb-16">
        <v-row style="padding-top: 30px" align="center">
          <v-col cols="12" md="6">
            <v-card rounded class="rounded-xl" style="width: 95%">
              <v-img
                lazy-src="@/assets/image/AboutUs/img-4.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/img-4.jpg"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" style="padding-left: 50px">
            <p
              style="
                color: #f7941d;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 13px;
                line-height: 120%;
                letter-spacing: 3px;
              "
            >
              STATEMENTS
            </p>
            <p
              v-if="showCompanyValue"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Value</span>
            </p>
            <p
              v-if="showCompanyMission"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Mission</span>
            </p>
            <p
              v-if="showCompanyVision"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Vision</span>
            </p>
            <p
              v-if="showCompanyValue"
              class="content-text"
              style="padding-right: 50px"
            >
              Our Core values are integrity, reliability, accuracy and fair
              business practice. Values are believes, Philosophies and
              principles that would drive our company. These Values will
              indicate what is important, what is to be prioritized and how it
              conducts within the organization.
            </p>

            <p
              v-if="showCompanyVision"
              class="content-text"
              style="padding-right: 50px"
            >
              Our vision is to be the pioneer among courier service providers in
              Sri Lanka. We are about to extend our service globally while
              utilizing our own network to deliver an exceptional service to our
              customers. We maintain our core values of honesty, reliability,
              accuracy, integrity and fair business practice.
            </p>

            <p
              v-if="showCompanyMission"
              class="content-text"
              style="padding-right: 50px"
            >
              Our mission is to offer an exceptional courier service to
              customers that surpass their expectations through development of
              an IT solution to track and review the status of each and every
              delivery in every second, clear identification from branch
              network, with talented and committed staff, the provision of good
              working environment for our staff that they are proud to be a part
              and where honesty, reliability, accuracy, integrity and service
              excellence were practiced.
            </p>

            <v-row v-if="windowWidth >= 600 && windowWidth <= 1024">
              <v-col cols="12" md="4">
                <v-btn
                  v-if="showCompanyMission"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Mission</v-btn
                >
                <v-btn
                  v-if="!showCompanyMission"
                  large
                  outlined
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Mission</v-btn
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  v-if="showCompanyVision"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Vision</v-btn
                >
                <v-btn
                  v-if="!showCompanyVision"
                  large
                  outlined
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Vision</v-btn
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  v-if="showCompanyValue"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Value</v-btn
                >
                <v-btn
                  v-if="!showCompanyValue"
                  large
                  outlined
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Value</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="windowWidth >= 600 && windowWidth > 1025">
              <v-col cols="12" md="4" xl="3">
                <v-btn
                  v-if="showCompanyMission"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Mission</v-btn
                >
                <v-btn
                  v-if="!showCompanyMission"
                  large
                  outlined
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Mission</v-btn
                >
              </v-col>
              <v-col cols="12" md="4" xl="3">
                <v-btn
                  v-if="showCompanyVision"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Vision</v-btn
                >
                <v-btn
                  v-if="!showCompanyVision"
                  large
                  outlined
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Vision</v-btn
                >
              </v-col>
              <v-col cols="12" md="4" xl="3">
                <v-btn
                  v-if="showCompanyValue"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Value</v-btn
                >
                <v-btn
                  v-if="!showCompanyValue"
                  large
                  outlined
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Value</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      style="padding: 20px; background-color: #eaebed"
    >
      <v-container class="mt-5 mb-16">
        <v-row style="padding-top: 5px" align="center">
          <v-col cols="12" md="6">
            <v-card rounded class="rounded-xl" style="width: 100%">
              <v-img
                lazy-src="@/assets/image/AboutUs/img-4.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/img-4.jpg"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <p
              style="
                color: #f7941d;
                font-family: 'Rubik';
                font-style: normal;
                font-size: 15px;
                line-height: 120%;
                letter-spacing: 3px;
                margin-top: 20px;
              "
            >
              STATEMENTS
            </p>
            <p
              v-if="showCompanyValue"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Value</span>
            </p>
            <p
              v-if="showCompanyMission"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Mission</span>
            </p>
            <p
              v-if="showCompanyVision"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
              "
            >
              Company <span style="color: #0674ba">Vision</span>
            </p>
            <p
              v-if="showCompanyValue"
              style="
                color: black;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                letter-spacing: 0.8px;
                padding-right: 50px;
              "
            >
              Our Core values are integrity, reliability, accuracy and fair
              business practice. Values are believes, Philosophies and
              principles that would drive our company. These Values will
              indicate what is important, what is to be prioritized and how it
              conducts within the organization.
            </p>

            <p
              v-if="showCompanyMission"
              style="
                color: black;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                letter-spacing: 0.8px;
                padding-right: 50px;
              "
            >
              Our mission is to offer an exceptional courier service to
              customers that surpass their expectations through development of
              an IT solution to track and review the status of each and every
              delivery in every second, clear identification from branch
              network, with talented and committed staff, the provision of good
              working environment for our staff that they are proud to be a part
              and where honesty, reliability, accuracy, integrity and service
              excellence were practiced.
            </p>

            <p
              v-if="showCompanyVision"
              style="
                color: black;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 800;
                font-size: 15px;
                letter-spacing: 0.8px;
                padding-right: 50px;
              "
            >
              Our vision is to be the pioneer among courier service providers in
              Sri Lanka. We are about to extend our service globally while
              utilizing our own network to deliver an exceptional service to our
              customers. We maintain our core values of honesty, reliability,
              accuracy, integrity and fair business practice.
            </p>
            <v-row>
              <v-col cols="12" sm="3">
                <v-btn
                  style="width: 100%"
                  v-if="showCompanyMission"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Mission</v-btn
                >
                <v-btn
                  style="width: 100%"
                  v-if="!showCompanyMission"
                  large
                  outlined
                  @click="clickCompanyValue(1)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Mission</v-btn
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  style="width: 100%"
                  v-if="showCompanyVision"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Vision</v-btn
                >
                <v-btn
                  style="width: 100%"
                  v-if="!showCompanyVision"
                  large
                  outlined
                  @click="clickCompanyValue(2)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Vision</v-btn
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  style="width: 100%"
                  v-if="showCompanyValue"
                  large
                  color="#0674ba"
                  class="white--text"
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-right</v-icon> &nbsp; Value</v-btn
                >
                <v-btn
                  style="width: 100%"
                  v-if="!showCompanyValue"
                  large
                  outlined
                  @click="clickCompanyValue(3)"
                  ><v-icon small>mdi-arrow-bottom-right</v-icon> &nbsp;
                  Value</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="text-center"
      style="padding: 20px; background-color: #0674ba"
    >
      <v-container class="mt-16 mb-16">
        <v-row style="padding-top: 10px" align="center">
          <v-col cols="12" md="12">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
                color: white;
              "
            >
              Our Delivery Process
            </p>
            <p
              class="content-text"
              style="color: white !important; margin-bottom: 50px"
            >
              Our delivery process will be originated with collection
              confirmation of the customer package. <br />
              Main points of these processes could be demonstrated as below.
            </p>
            <v-img
              lazy-src="@/assets/image/AboutUs/img-5.png"
              max-height="auto"
              max-width="100%"
              style="background-position: center"
              src="@/assets/image/AboutUs/img-5.png"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="text-center"
      style="padding: 20px; background-color: #0674ba"
    >
      <v-container class="mt-8 mb-16">
        <v-row style="padding-top: 5px" align="center">
          <v-col cols="12" md="12">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 10px;
                color: white;
              "
            >
              Our Delivery Process
            </p>
            <p
              class="content-text"
              style="color: white !important; margin-bottom: 50px"
            >
              Our delivery process will be originated with collection
              confirmation of the customer package. <br />
              Main points of these processes could be demonstrated as below.
            </p>
            <v-img
              lazy-src="@/assets/image/AboutUs/i1.png"
              max-height="auto"
              max-width="100%"
              style="
                background-position: center;
                margin-left: 50px;
                margin-right: 50px;
              "
              src="@/assets/image/AboutUs/i1.png"
            ></v-img>

            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 45px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: white;
              "
            >
              01
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 50px;
                color: white;
              "
            >
              Collecting Package
            </p>

            <v-img
              lazy-src="@/assets/image/AboutUs/i2.png"
              max-height="auto"
              max-width="100%"
              style="
                background-position: center;
                margin-left: 50px;
                margin-right: 50px;
              "
              src="@/assets/image/AboutUs/i2.png"
            ></v-img>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 45px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: white;
              "
            >
              02
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 50px;
                color: white;
              "
            >
              Sorting & Organizing
            </p>
            <v-img
              lazy-src="@/assets/image/AboutUs/i3.png"
              max-height="auto"
              max-width="100%"
              style="
                background-position: center;
                margin-left: 50px;
                margin-right: 50px;
              "
              src="@/assets/image/AboutUs/i3.png"
            ></v-img>

            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 45px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: white;
              "
            >
              03
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 50px;
                color: white;
              "
            >
              Transferring & Shipping
            </p>

            <v-img
              lazy-src="@/assets/image/AboutUs/i4.png"
              max-height="auto"
              max-width="100%"
              style="
                background-position: center;
                margin-left: 50px;
                margin-right: 50px;
              "
              src="@/assets/image/AboutUs/i4.png"
            ></v-img>

            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 45px;
                margin-top: 10px;
                margin-bottom: 10px;
                color: white;
              "
            >
              04
            </p>
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 10px;
                color: white;
              "
            >
              Delivering
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 20px;
            "
          >
            Our <span style="color: #0674ba">Clients</span>
          </p>
          <v-slide-group multiple show-arrows>
            <v-slide-item v-for="n in 25" :key="n" v-slot="{ active, toggle }">
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
                :input-value="active"
                @click="toggle"
              ></v-img>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-16 mb-16 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 48px;
              margin-bottom: 20px;
            "
          >
            Our <span style="color: #0674ba">Clients</span>
          </p>
          <VueSlickCarousel v-bind="carouselMobileSettings" ref="carousel">
            <div>
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
              ></v-img>
            </div>

            <div>
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
              ></v-img>
            </div>
            <div>
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
              ></v-img>
            </div>
            <div>
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
              ></v-img>
            </div>
            <div>
              <v-img
                lazy-src="@/assets/image/AboutUs/client-slider-1.png"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/AboutUs/client-slider-1.png"
              ></v-img>
            </div>
          </VueSlickCarousel>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <FooterView />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
export default {
  name: "AboutUs",
  components: {
    AppBar,
    FooterView,
    VueSlickCarousel,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      showCompanyMission: false,
      showCompanyVision: false,
      showCompanyValue: true,
      carouselMobileSettings: {
        arrows: false,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerPadding: "10px",
        autoplay: true,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    // this.$router.go();
    window.scrollTo(0, 0);
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showBack() {
      this.$refs.carousel.prev();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    redirectServicePage1() {
      this.$router.push({ name: "DocumentPackageDelivery" });
    },

    clickCompanyValue(number) {
      switch (number) {
        case 1:
          this.showCompanyMission = true;
          this.showCompanyVision = false;
          this.showCompanyValue = false;
          break;
        case 2:
          this.showCompanyMission = false;
          this.showCompanyVision = true;
          this.showCompanyValue = false;
          break;
        case 3:
          this.showCompanyMission = false;
          this.showCompanyVision = false;
          this.showCompanyValue = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}
.aboutUsBackgroundImg {
  background-image: url("@/assets/image/AboutUs/cover.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.aboutUsBackgroundMobileImg {
  background-image: url("@/assets/image/AboutUs/mobile-cover.png");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.aboutUsBackgroundImgCover2 {
  background-image: url("@/assets/image/AboutUs/cover-2.png");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
</style>
