<template>
  <div>
    <AppBar v-if="windowWidth <= 600" />
    <v-sheet
      v-if="windowWidth >= 600"
      class="mt-0"
      max-width="100%"
      color="#E6EBEF"
      style="height: 100vh"
    >
      <v-container fluid style="max-width: 100% !important">
        <v-row>
          <v-col cols="12" md="12" style="margin-top: 100px">
            <div style="position: relative; left: 35%">
              <v-card
                style="border-radius: 16px; padding: 60px"
                max-width="35%"
              >
                <v-img
                  lazy-src="@/assets/image/falcon-icon.png"
                  max-height="auto"
                  max-width="28%"
                  src="@/assets/image/falcon-icon.png"
                  style="
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 50%;
                  "
                ></v-img>
                <div class="text-center">
                  <p
                    style="
                      font-family: 'Rubik';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 25px;
                      color: #0674ba;
                      margin-top: 30px;
                    "
                  >
                    Password Reset
                  </p>
                </div>

                <p
                  style="
                    font-family: 'SF Pro Display';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    color: black;
                    letter-spacing: 0.8px;
                    margin-top: 40px;
                  "
                >
                  Phone Number
                </p>

                <v-text-field
                  v-model="mobileNumber"
                  dense
                  placeholder="eg : +94 717275539"
                  solo
                  flat
                  background-color="#F3F4F6"
                  style="border-radius: 10px"
                ></v-text-field>
                <v-alert
                  type="error"
                  style="margin-top: 5px"
                  dense
                  v-if="isErrorAlert"
                >
                  {{ errorAlertText }}
                </v-alert>
                <v-btn
                  color="#0674BA"
                  @click="resetPassword()"
                  class="white--text rounded-lg"
                  large
                  style="
                    font-family: 'Rubik-Regular';
                    font-style: normal;
                    font-size: 13px;
                    font-weight: 600;
                    padding-left: 50px;
                    padding-right: 50px;
                    display: block;
                    width: 100%;
                  "
                >
                  Send OTP
                </v-btn>
                <br />
                <v-btn
                  color="#0674BA"
                  @click="redirectToLoginPage()"
                  class="white--text rounded-lg cancel-btn"
                  large
                >
                  Go Back
                </v-btn>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet
      v-if="windowWidth <= 600"
      class="mt-0"
      max-width="100%"
      color="#E6EBEF"
    >
      <v-container fluid style="max-width: 100% !important">
        <v-row>
          <v-col cols="12" md="12" style="background-color: #ffffff">
            <v-card style="border-radius: 28px; padding: 20px">
              <v-img
                lazy-src="@/assets/image/falcon-icon.png"
                max-height="auto"
                max-width="70%"
                src="@/assets/image/falcon-icon.png"
                style="
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  width: 800%;
                "
              ></v-img>
              <div class="text-center">
                <p
                  style="
                    font-family: 'Rubik';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25px;
                    color: #0674ba;
                    margin-top: 20px;
                  "
                >
                  Password Reset
                </p>
              </div>

              <p
                style="
                  font-family: 'SF Pro Display';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  color: black;
                  letter-spacing: 0.8px;
                  margin-top: 50px;
                "
              >
                Phone Number
              </p>

              <v-text-field
                v-model="mobileNumber"
                placeholder="eg : +94 717275539"
                solo
                flat
                background-color="#F3F4F6"
                style="border-radius: 10px"
              ></v-text-field>
              <v-alert
                type="error"
                style="margin-top: 5px"
                dense
                v-if="isErrorAlert"
              >
                {{ errorAlertText }}
              </v-alert>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    color="#0674BA"
                    @click="resetPassword()"
                    class="white--text rounded-lg"
                    x-large
                    style="
                      font-family: 'Rubik-Regular';
                      font-style: normal;
                      font-size: 18px;
                      font-weight: 600;
                      padding-left: 50px;
                      padding-right: 50px;
                      display: block;
                      width: 100%;
                    "
                  >
                    Send OTP
                  </v-btn>
                  <v-btn
                    color="#0674BA"
                    @click="redirectToLoginPage()"
                    class="white--text rounded-lg cancel-btn"
                    large
                  >
                    Go Back
                  </v-btn>
                </v-col>
              </v-row>

              <div class="text-center">
                <p
                  style="
                    font-family: 'SF Pro Display';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    color: black;
                    margin-top: 25px;
                    letter-spacing: 0.8px;
                    margin-bottom: 25px;
                  "
                >
                  © 2022 - Falcon Express Delivery <br />
                  All rights reserved.
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-overlay :value="resetPasswordOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import axios from "axios";
export default {
  name: "PasswordReset",
  components: {
    AppBar,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mobileNumber: "",
      resetPasswordOverlay: false,
      errorAlertText: "",
      isErrorAlert: false,
    };
  },
  mounted() {},
  created() {
    window.addEventListener("resize", this.onResize);
    console.log(this.windowHeight);
  },
  methods: {
    redirectToLoginPage() {
      this.$router.push({ name: "LoginScreen" });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    resetPassword() {
      this.isErrorAlert = false;
      this.errorAlertText = "";
      if (this.mobileNumber != "") {
        this.resetPasswordOverlay = true;
        axios
          .post(
            this.$hostname +
              "/api/client/password-reset/send/" +
              this.mobileNumber.toString()
          )
          .then((response) => {
            console.log(response);
            this.$router.push({
              name: "NewPassword",
              params: { phone: this.mobileNumber },
            });
            this.resetPasswordOverlay = false;
            this.errorAlertText = "";
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.isErrorAlert = true;
            this.errorAlertText = error.response.data.message;
            this.resetPasswordOverlay = false;
          })
          .finally(() => {
            this.resetPasswordOverlay = false;
          });
      } else {
        this.isErrorAlert = true;
        this.errorAlertText = "Enter Your Phone Number.";
      }
    },
  },
};
</script>
<style scoped>
.cancel-btn {
  font-family: "Rubik-Regular";
  font-style: normal;
  background-color: rgb(26, 187, 131) !important;
  font-size: 13px;
  font-weight: 600;
  padding-left: 50px;
  padding-right: 50px;
  display: block;
  width: 100%;
}
</style>
