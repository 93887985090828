<template>
  <div>
    <v-tooltip v-if="show" top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"  class="text-white badge bg-danger"> Exchange </span>
        </template>
        <span
          >This is a exchange package </span
        >
      </v-tooltip>
    
  </div>
</template>
<script>
export default {
  name: "ExchangedPackage",
  props:['show']
};
</script>
<style scoped>
.bg-danger {
  background-color: rgba(233, 233, 233, 0.774);
  color: rgb(236, 18, 18)
}</style>
