var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"rounded-xl pb-5",attrs:{"rounded":"","dark":"","color":"#212529"}},[_c('v-card-title',{staticClass:"font-weight-bold text-h5 ml-10"},[_vm._v("Our Main Services")]),_c('v-sheet',{class:_vm.selectService == 1
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 1 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(1)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Documents & Packages Delivery ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 1 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1),_c('v-sheet',{class:_vm.selectService == 2
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 2 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(2)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Same day Pick up & Delivery Services ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 2 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1),_c('v-sheet',{class:_vm.selectService == 3
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 3 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(3)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Management of Mail Room ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 3 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1),_c('v-sheet',{class:_vm.selectService == 4
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 4 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(4)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Cash on Delivery (COD) ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 4 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1),_c('v-sheet',{class:_vm.selectService == 5
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 5 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(5)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Confidential Documents Delivery ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 5 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1),_c('v-sheet',{class:_vm.selectService == 6
          ? 'ml-10 mr-10 mb-3'
          : 'ml-10 mr-10 mb-3 black--text',staticStyle:{"cursor":"pointer"},attrs:{"rounded":"","color":_vm.selectService == 6 ? '#0674BA' : 'white'},on:{"click":function($event){return _vm.redirectToServicePage(6)}}},[_c('v-card-title',{staticClass:"text-caption"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v(" Confidential Parcel Delivery ")]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.selectService == 6 ? 'white' : 'black'}},[_vm._v("mdi-arrow-right-circle")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }