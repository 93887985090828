<template>
  <div>
    <AppBar />
    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="contactUsBackgroundImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Contact Us</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> Contact US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="contactUsBackgroundMobileImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Contact Us</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> Contact US
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16">
      <v-row>
        <v-col cols="12" md="6">
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 13px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            CONTACT US
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-top: -10px;
            "
          >
            Get In <span style="color: #0674ba">Touch</span>
          </p>
          <p class="content-text">
            Need to get in touch with us? Please fill out the form for any
            inquiry or else contact our representative for more clarifications.
          </p>
        </v-col>

        <v-col cols="12" md="2">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: 100%;
            "
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/call-icon.png"
              max-width="30%"
              src="@/assets/image/ContactUs/call-icon.png"
            ></v-img>
            <p
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 16px;
                letter-spacing: 0.8px;
                margin-top: 10px;
              "
            >
              Phone Number
            </p>
            <p class="content-text">
              <a class="redirect-links" href="tel:0112878524">0112 878 524</a>
            </p>
            <p class="content-text">
              <a class="redirect-links" href="tel:0114425177">0114 425 177</a>
            </p>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="2">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: 100%;
            "
            max-width="100%"
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/email-icon.png"
              max-height="auto"
              max-width="30%"
              src="@/assets/image/ContactUs/email-icon.png"
            ></v-img>
            <p
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 16px;
                letter-spacing: 0.8px;
                margin-top: 10px;
              "
            >
              Email Address
            </p>
            <p class="content-text">
              <a class="redirect-links" href="mailto:info@falcon.lk"
                >info@falcon.lk</a
              >
              <br />
              <a class="redirect-links" href="mailto:contact@falcon.lk"
                >contact@falcon.lk</a
              >
            </p>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="2">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: 100%;
            "
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/marker-icon.png"
              max-height="auto"
              max-width="30%"
              src="@/assets/image/ContactUs/marker-icon.png"
            ></v-img>
            <p
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 16px;
                letter-spacing: 0.8px;
                margin-top: 10px;
              "
            >
              Address
            </p>
            <p class="content-text">
              25/13 (339), Wedagewatta RD, Pelengahawatta, Kotuwegoda,
              Rajagiriya, Sri Lanka
            </p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-10 mb-16">
      <v-row>
        <v-col cols="12" md="6">
          <p
            style="
              color: #0674ba;
              font-family: 'Rubik';
              font-style: normal;
              font-size: 15px;
              line-height: 120%;
              letter-spacing: 3px;
            "
          >
            CONTACT US
          </p>
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-top: -10px;
            "
          >
            Get In <span style="color: #0674ba">Touch</span>
          </p>
          <p class="content-text">
            Need to get in touch with us? Please fill out the form for any
            inquiry or else contact our representative for more clarifications.
          </p>
        </v-col>

        <v-col cols="12" md="12">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: auto;
              margin-top: 80px;
            "
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/call-icon.png"
              max-height="auto"
              max-width="100%"
              style="margin-left: 130px; margin-right: 130px"
              src="@/assets/image/ContactUs/call-icon.png"
            ></v-img>
            <p
              class="text-center"
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 18px;
                letter-spacing: 0.8px;
                margin-top: 20px;
              "
            >
              Phone Number
            </p>
            <p class="text-center content-text">
              <a class="redirect-links" href="tel:0112878524">0112 878 524</a>
            </p>
            <p class="text-center content-text">
              <a class="redirect-links" href="tel:0114425177">0114 425 177</a>
            </p>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="12">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: auto;
              margin-top: 60px;
            "
            max-width="100%"
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/email-icon.png"
              max-height="auto"
              max-width="100%"
              style="margin-left: 130px; margin-right: 130px"
              src="@/assets/image/ContactUs/email-icon.png"
            ></v-img>
            <p
              class="text-center"
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 18px;
                letter-spacing: 0.8px;
                margin-top: 20px;
              "
            >
              Email Address
            </p>
            <p class="text-center content-text">
              <a class="redirect-links" href="mailto:info@falcon.lk"
                >info@falcon.lk</a
              >
              <br />
              <a class="redirect-links" href="mailto:contact@falcon.lk"
                >contact@falcon.lk</a
              >
            </p>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="12">
          <v-sheet
            style="
              background: #f8f9fb;
              padding: 10px;
              border-radius: 16px;
              height: auto;
              margin-top: 80px;
            "
          >
            <v-img
              lazy-src="@/assets/image/ContactUs/marker-icon.png"
              max-height="auto"
              max-width="100%"
              style="margin-left: 130px; margin-right: 130px"
              src="@/assets/image/ContactUs/marker-icon.png"
            ></v-img>
            <p
              class="text-center"
              style="
                color: black;
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-size: 18px;
                letter-spacing: 0.8px;
                margin-top: 20px;
              "
            >
              Address
            </p>
            <p class="text-center content-text">
              25/13 (339), Wedagewatta RD, Pelengahawatta, Kotuwegoda,
              Rajagiriya, Sri Lanka
            </p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container
      v-if="windowWidth >= 600"
      style="padding-bottom: 50px; padding-top: 100px"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-sheet style="min-height: 560px">
            <img
              src="@/assets/image/ContactUs/map.png"
              width="100%"
              height="auto"
            />
            <v-img
              lazy-src="@/assets/image/ContactUs/Location-Pin.png"
              max-height="auto"
              max-width="8%"
              src="@/assets/image/ContactUs/Location-Pin.png"
              style="top: -290px; right: -320px"
            ></v-img>
          </v-sheet>
          <v-card
            rounded
            class="rounded-lg"
            elevation="3"
            style="
              padding: 30px;
              width: 30%;
              top: -690px;
              right: -680px;
              margin-bottom: -600px;
            "
          >
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                color: #0674ba;
                align: center;
              "
            >
              Send a message
            </p>
            <v-form ref="form">
              <v-text-field
                label="Full Name"
                v-model="contact.full_name"
                outlined
                :rules="nameRules"
              ></v-text-field>
              <v-text-field
                :rules="emailRules"
                v-model="contact.email"
                style="margin-top: -20px"
                label="Email Address"
                outlined
              ></v-text-field>
              <v-text-field
                :rules="phoneRules"
                v-model="contact.phone"
                style="margin-top: -20px"
                label="Phone Number"
                outlined
              ></v-text-field>
              <v-textarea
              v-model="contact.message"
                :rules="messageRules"
                style="margin-top: -20px"
                outlined
                name="input-7-4"
                label="Your Message"
              ></v-textarea>

              <v-btn
                @click="sendMessage"
                color="#0674BA"
                class="white--text rounded-lg"
                x-large
                style="
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 14px;
                "
              >
                SEND MESSAGE
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container
      v-if="windowWidth <= 600"
      style="padding-bottom: 50px; padding-top: 100px"
    >
      <v-row>
        <v-col cols="12" md="12">
          <v-sheet>
            <v-img
              lazy-src="@/assets/image/ContactUs/mobile-map.png"
              max-height="auto"
              max-width="100%"
              src="@/assets/image/ContactUs/mobile-map.png"
            ></v-img>
          </v-sheet>
          <v-card
            rounded
            class="rounded-lg"
            elevation="3"
            style="padding: 30px; width: 100%; margin-top: 50px"
          >
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                color: #0674ba;
                align: center;
              "
            >
              Send a message
            </p>
            <v-form ref="form">
              <v-text-field
                label="Full Name"
                outlined
                v-model="contact.full_name"
                :rules="nameRules"
              ></v-text-field>
              <v-text-field
               v-model="contact.email"
                :rules="emailRules"
                style="margin-top: -20px"
                label="Email Address"
                outlined
              ></v-text-field>
              <v-text-field
              v-model="contact.phone"
                :rules="phoneRules"
                style="margin-top: -20px"
                label="Phone Number"
                outlined
              ></v-text-field>
              <v-textarea
              v-model="contact.message"
                :rules="messageRules"
                style="margin-top: -20px"
                outlined
                name="input-7-4"
                label="Your Message"
              ></v-textarea>
              <v-btn
                @click="sendMessage"
                color="#0674BA"
                class="white--text rounded-lg"
                x-large
                style="
                  font-family: 'Rubik';
                  font-style: normal;
                  font-size: 14px;
                "
              >
                SEND MESSAGE
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->
    <FooterView />
    <v-snackbar v-model="snackbar" :timeout="timeout" color="green">
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <span class="mdi mdi-close-circle"></span>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorSnack" :timeout="timeout" color="red">
      {{ snackbar_text }}
      
    </v-snackbar>
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
import axios from "axios";

export default {
  name: "ContactUs",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      contact: {
        full_name: "",
        email: "",
        phone: "",
        message: "",
      },
      snackbar: false,
      errorSnack: false,
      snackbar_text: "",
      timeout: 2000,
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [(v) => !!v || "Email is required"],
      phoneRules: [(v) => !!v || "Phone is required"],
      messageRules: [(v) => !!v || "Message is required"],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    sendMessage() {
      if(!this.$refs.form.validate()) return;
      this.snackbar_text = "";
      this.errorSnack = false;
      this.snackbar = false;
      axios
        .post(this.$hostname + "/api/client/contact-request", this.contact)
        .then((response) => {
          if (response.data.message) {
            this.snackbar = true;
            this.snackbar_text = response.data.message;
            this.clearContactForm();
          } else {
            this.errorSnack = true;
          }
        })
        .catch((error) => {
          this.errorSnack = true;
          this.snackbar_text = error.response.data.message;
        });
    },

    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}
.contactUsBackgroundImg {
  background-image: url("@/assets/image/ContactUs/main.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.contactUsBackgroundMobileImg {
  background-image: url("@/assets/image/ContactUs/mobile-cover.png");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}

.redirect-links {
  text-decoration: none;
  color: #323232 !important;
}
</style>
