<template>
  <div>
    <v-carousel
      cycle
      show-arrows-on-hover
      delimiter-icon="mdi-minus"
      hide-delimiter-background
      class="carousel-wrap"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          class="rounded-lg"
          style="padding-top: 30px; padding-bottom: 30px"
          color="#0674BA"
          v-bind="attrs"
          v-on="on"
          ><v-icon color="white">mdi-arrow-left</v-icon></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          rounded
          class="rounded-lg"
          style="padding-top: 30px; padding-bottom: 30px"
          color="#F7941D"
          v-bind="attrs"
          v-on="on"
          ><v-icon color="white">mdi-arrow-right</v-icon></v-btn
        >
      </template>
      <v-carousel-item>
        <v-img
          lazy-src="@/assets/image/Home/slider-img-1.jpg"
          max-height="100%"
          max-width="100%"
          src="@/assets/image/Home/slider-img-1.jpg"
        >
          <v-container>
            <v-row class="carousel-wrap-item" align="center">
              <v-col cols="12" md="12">
                <p
                  v-if="windowWidth >= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 50px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>

                <p
                  v-if="windowWidth <= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 32px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'SF Pro Display';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 120%;
                    letter-spacing: 0.8px;
                  "
                >
                  Our foremost priority is to accommodate the customer’s
                  requirements <br />
                  while maintaining integrity and accuracy consistently.
                </p>
                <v-btn
                  color="#0674BA"
                  @click="redirectServicePage1()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik-Regular';
                    font-style: normal;
                    font-size: 13px;
                    font-weight: 600;
                    margin-right: 30px;
                  "
                >
                  Our Services
                </v-btn>

                <v-btn
                  outlined
                  @click="redirectAboutUs()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 14px;
                  "
                >
                  About us
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img
          lazy-src="@/assets/image/Home/slider-img-2.jpg"
          max-height="100%"
          max-width="100%"
          src="@/assets/image/Home/slider-img-2.jpg"
        >
          <v-container>
            <v-row class="carousel-wrap-item" align="center">
              <v-col cols="12" md="12">
                <p
                  v-if="windowWidth >= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 50px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>

                <p
                  v-if="windowWidth <= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 32px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'SF Pro Display';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 120%;
                    letter-spacing: 0.8px;
                  "
                >
                  Our foremost priority is to accommodate the customer’s
                  requirements <br />
                  while maintaining integrity and accuracy consistently.
                </p>
                <v-btn
                  color="#0674BA"
                  @click="redirectServicePage1()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik-Regular';
                    font-style: normal;
                    font-size: 13px;
                    font-weight: 600;
                    margin-right: 30px;
                  "
                >
                  Our Services
                </v-btn>

                <v-btn
                  outlined
                  @click="redirectAboutUs()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 14px;
                  "
                >
                  About us
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-carousel-item>

      <v-carousel-item>
        <v-img
          lazy-src="@/assets/image/Home/slider-img-3.jpg"
          max-height="100%"
          max-width="100%"
          src="@/assets/image/Home/slider-img-3.jpg"
        >
          <v-container>
            <v-row class="carousel-wrap-item" align="center">
              <v-col cols="12" md="12">
                <p
                  v-if="windowWidth >= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 50px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>

                <p
                  v-if="windowWidth <= 600"
                  style="
                    color: white;
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 32px;
                    line-height: 120%;
                    text-transform: uppercase;
                  "
                >
                  Fast, Reliable, <br />
                  Accountable and <br />
                  Secure service
                </p>
                <p
                  style="
                    color: white;
                    font-family: 'SF Pro Display';
                    font-style: normal;
                    font-size: 14px;
                    line-height: 120%;
                    letter-spacing: 0.8px;
                  "
                >
                  Our foremost priority is to accommodate the customer’s
                  requirements <br />
                  while maintaining integrity and accuracy consistently.
                </p>
                <v-btn
                  color="#0674BA"
                  @click="redirectServicePage1()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik-Regular';
                    font-style: normal;
                    font-size: 13px;
                    font-weight: 600;
                    margin-right: 30px;
                  "
                >
                  Our Services
                </v-btn>

                <v-btn
                  outlined
                  @click="redirectAboutUs()"
                  class="white--text rounded-lg"
                  x-large
                  style="
                    font-family: 'Rubik';
                    font-style: normal;
                    font-size: 14px;
                  "
                >
                  About us
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "CarouselView",

  data() {
    return {
      windowWidth: window.innerWidth,
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third"],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {},
  methods: {
    onResize(e) {
      this.windowWidth = window.innerWidth;
      console.log(e);
    },
    redirectAboutUs() {
      this.$router.push({ name: "AboutUs" });
    },
    redirectServicePage1() {
      this.$router.push({ name: "DocumentPackageDelivery" });
    },
  },
};
</script>
<style>
.v-carousel__controls {
  align-items: flex-start !important;
  bottom: 12px !important;
}
.carousel-wrap {
  min-height: 420px;
  height: calc(100vh - 240px) !important;
}

.carousel-wrap .v-carousel__item {
  min-height: 420px;
  height: calc(100vh - 240px) !important;
}

.carousel-wrap .carousel-wrap-item {
  min-height: 420px;
  height: calc(100vh - 240px) !important;
}
</style>
