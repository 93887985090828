<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-alert type="error" dense v-if="errorAlertText">
        {{ errorAlertText }}
      </v-alert>
      <v-alert type="success" dense v-if="successAlertText">
        {{ successAlertText }}
      </v-alert>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="backToList"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Back To List</v-btn
          >
        </v-col>
      </v-row>
      <v-form @submit.prevent lazy-validation autocomplete="off" ref="form">
        <v-card>
          <v-row>
            <v-col cols="12" md="6">
              <v-card elevation="2">
                <v-card-title class="card-title">
                  <span>Upload Package Details</span>
                  <v-btn
                    :disabled="loading"
                    depressed
                    @click="downloadFormat()"
                    color="success"
                  >
                    <v-icon color="white" small> mdi-file-excel </v-icon>
                    &nbsp;Download Format</v-btn
                  >
                </v-card-title>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <label for="pickup-city"
                        >Pickup City <span class="text-danger">*</span></label
                      >
                      <v-combobox
                        :disabled="loading"
                        id="pickup-city"
                        v-model="pickup_city"
                        autocomplete="nope"
                        :items="pickupCities"
                        item-text="name"
                        item-value="id"
                        label="PickUp City ( required )"
                        solo
                        dense
                        @change="setPickCityId()"
                        :rules="[(v) => !!v || 'Pick up city is required']"
                      ></v-combobox>
                    </v-col>

                    <v-col cols="12" sm="6" class="pb-0">
                      <label for="pickup-date"
                        >Pickup Date <span class="text-danger">*</span></label
                      >
                      <v-text-field
                        :disabled="loading"
                        id="pickup-date"
                        label="Date ( required )"
                        type="date"
                        v-model="form.pickup_date"
                        clearable
                        solo
                        :rules="[(v) => !!v || 'Date is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex align-right mb-2" cols="12" sm="12">
                      <v-file-input
                        prepend-icon="mdi-file-excel"
                        :disabled="loading"
                        @change="uploadFile"
                        show-size
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        clearable
                        label="Upload Bulk"
                        variant="outlined"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :disabled="loading"
                      cols="12"
                      sm="12"
                      class="d-flex justify-content-end mb-2"
                    >
                      <v-btn depressed @click="submit" color="success">
                        Upload
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-if="validation_errors.length > 0">
              <v-card elevation="2">
                <v-container fluid>
                  <v-row>
                    <v-col class="mb-2" cols="12" sm="12">
                      <h4 class="text-danger">Validation Errors</h4>
                      <small class="text-danger"
                        >Please fix these errors before uploading again</small
                      >
                      <v-data-table
                        :headers="table_headers"
                        :items="validation_errors"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.errors`]="{ item }">
                          <span v-for="(value, key) in item.errors" :key="key">
                            {{ value }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";

export default {
  name: "UploadBulkPackage",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      id: null,
      file: null,
      loading: false,
      errorAlertText: "",
      successAlertText: "",
      table_headers: [
        { text: "Row", value: "row", sortable: false },
        { text: "Attribute", value: "attribute", sortable: false },
        { text: "Errors", value: "errors", sortable: false },
      ],
      validation_errors: [],
      pickupCities: [],
      pickup_city: "",
      form: {
        pickup_city_id: "",
        pickup_date: "",
        file: null,
      },
    };
  },
  created() {
    this.setToken();
  },
  mounted() {
    this.getCity();
  },
  methods: {
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    getCity() {
      axios
        .get(this.$hostname + "/api/client/get-cities")
        .then((response) => {
          this.allCities = response.data.data;
          this.setPickupCities();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPickupCities() {
      let array = [];
      this.allCities.forEach((item) => {
        if (item.area_type_id < 3) {
          array.push(item);
        }
      });
      this.pickupCities = array;
      this.clearForm();
    },
    backToList() {
      return this.$router.push({ name: "CustomerPackages" });
    },
    setPickCityId() {
      this.form.pickup_city_id = this.pickup_city.id;
    },
    downloadFormat() {
      let url = this.$hostname + "/api/client/download-format";
      axios
        .get(url, {
          headers: {
            "Content-Disposition": "attachment; filename=template.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "bulk_format.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.printing = false;
        });
    },
    submit() {
      this.loading = true;
      this.validation_errors = [];
      axios
        .post(this.$hostname + "/api/client/upload-excel", this.form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          if (
            response.data.validation_errors &&
            response.data.validation_errors.length > 0
          ) {
            this.validation_errors = response.data.validation_errors;
          }
          if (response.data.message) {
            let updateCount = response.data.records_count.update_records ?? 0;
            let newCount = response.data.records_count.new_records ?? 0;

            let html =
              newCount +
              " new" +
              (newCount != 1 ? " records" : " record") +
              " created <br />" +
              updateCount +
              (updateCount != 1 ? " records" : " record") +
              " updated";
            this.showSuccess(null, response.data.message, html);
            this.clear();
          }
        })
        .catch((error) => {
          console.log(error);
          this.showError(
            error?.response?.data?.message ?? "Something went wrong"
          );
        })
        .finally(() => {
          this.file = null;
          this.form.file = null;
          this.loading = false;
        });
    },
    uploadFile(file) {
      this.validation_errors = [];
      this.form.file = file;
    },
    clear() {
      this.file = null;
      this.pickup_city = null;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.form.file = null;
        this.form.pickup_city_id = null;
        this.form.pickup_date = null;
      });
    },
  },
};
</script>
<style scoped>
.card-title {
  display: flex;
  justify-content: space-between;
}

.text-danger {
  color: red;
}
</style>
