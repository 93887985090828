<template>
<v-card rounded dark class="rounded-xl pb-5 mt-10" color="#212529">
            <v-card-title class="font-weight-bold text-h5 ml-5"
              >Contacts</v-card-title
            >
            <p class="text-caption ml-10 mr-2">
              Let’s get in touch and explore <br />
              business oppertunities.
            </p>
            <p
              class="text-caption ml-10 mr-2"
              style="cursor: pointer"
              @click="redirectTel()"
            >
              <v-icon>mdi-whatsapp</v-icon> &nbsp; WhatsApp &nbsp; &nbsp; :
              &nbsp; &nbsp; &nbsp; +94 77 257 6600
            </p>
            <p
              class="text-caption ml-10 mr-2"
              style="cursor: pointer"
              @click="redirectMailTo()"
            >
              <v-icon>mdi-email</v-icon> &nbsp; Email Address &nbsp; &nbsp; :
              &nbsp; &nbsp; &nbsp; info@falcon.lk
            </p>
            <v-btn icon class="ml-8">
              <v-icon color="white">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="white">mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="white">mdi-linkedin</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon color="white">mdi-whatsapp</v-icon>
            </v-btn>
          </v-card>

</template>
<script>
import social_links from '@/config';


    export default {
      name: "ContactInfo",
      methods: {
        redirectToServicePage(pageNo) {
          switch (pageNo) {
            case 1:
              this.$router.push({ name: "DocumentPackageDelivery" });
              break;
            case 2:
              this.$router.push({ name: "SamedayPickupDeliveryServices" });
              break;
            case 3:
              this.$router.push({ name: "ManagementofMailRoom" });
              break;
            case 4:
              this.$router.push({ name: "CashOnDelivery" });
              break;
            case 5:
              this.$router.push({ name: "ConfidentialDocumentsDelivery" });
              break;
            case 6:
              this.$router.push({ name: "ConfidentialParcelDelivery" });
              break;
    
            default:
              break;
          }
        },
        redirectSocialMedia(number) {
          switch (number) {
            case 1:
              window.open(social_links.facebook, "_blank");
              break;
            case 2:
              window.open("https://www.instagram.com/?hl=en", "_blank");
              break;
            case 3:
              window.open("https://lk.linkedin.com/", "_blank");
              break;
            case 4:
              window.open("https://www.youtube.com/", "_blank");
              break;
    
            default:
              break;
          }
        },
        onResize() {
          this.windowWidth = window.innerWidth;
        },
        redirectMailTo() {
          window.location.href = "mailto:info@falcon.lk";
        },
        redirectTel() {
          window.location.href = "tel:+94772576600";
        },
      },
    };
    </script>