<template>
    <v-card rounded dark class="rounded-xl pb-5 mt-10" color="#212529">
            <v-card-title
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                padding-top: 25px;
                color: white;
              "
              >Contacts</v-card-title
            >
            <p
              class="ml-5 mr-2"
              style="
                font-family: 'SF-Pro-Display-Semibold';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 0.8px;
              "
            >
              Let’s get in touch and explore <br />
              business oppertunities.
            </p>
            <p
              class="ml-5 mr-2"
              @click="redirectTel()"
              style="
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 15px;
                letter-spacing: 0.8px;
                cursor: pointer;
              "
            >
              <v-icon>mdi-whatsapp</v-icon> &nbsp; WhatsApp &nbsp; &nbsp; :
              &nbsp; &nbsp; &nbsp; +94 77 257 6600
            </p>
            <p
              class="ml-5 mr-2"
              @click="redirectMailTo()"
              style="
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 15px;
                letter-spacing: 0.8px;
                cursor: pointer;
              "
            >
              <v-icon>mdi-email</v-icon> &nbsp; Email Address &nbsp; &nbsp; :
              &nbsp; &nbsp; &nbsp; info@falcon.lk
            </p>
            <v-btn icon class="ml-5" @click="redirectSocialMedia(1)">
              <v-img
                class="text-center"
                lazy-src="@/assets/image/social-media-icon/fb.png"
                max-height="auto"
                max-width="13%"
                style="
                  background-position: center;
                  margin-left: 60px;
                  margin-right: 60px;
                "
                src="@/assets/image/social-media-icon/fb.png"
              ></v-img>
            </v-btn>
            <v-btn icon @click="redirectSocialMedia(2)">
              <v-img
                class="text-center"
                lazy-src="@/assets/image/social-media-icon/insta.png"
                max-height="auto"
                max-width="13%"
                style="
                  background-position: center;
                  margin-left: 60px;
                  margin-right: 60px;
                "
                src="@/assets/image/social-media-icon/insta.png"
              ></v-img>
            </v-btn>
            <v-btn icon @click="redirectSocialMedia(3)">
              <v-img
                class="text-center"
                lazy-src="@/assets/image/social-media-icon/in.png"
                max-height="auto"
                max-width="13%"
                style="
                  background-position: center;
                  margin-left: 60px;
                  margin-right: 60px;
                "
                src="@/assets/image/social-media-icon/in.png"
              ></v-img>
            </v-btn>
            <v-btn icon @click="redirectSocialMedia(4)">
              <v-img
                class="text-center"
                lazy-src="@/assets/image/social-media-icon/tube.png"
                max-height="auto"
                max-width="13%"
                style="
                  background-position: center;
                  margin-left: 60px;
                  margin-right: 60px;
                "
                src="@/assets/image/social-media-icon/tube.png"
              ></v-img>
            </v-btn>
          </v-card>
</template>
<script>
import social_links from '@/config';


    export default {
      name: "ContactInfo",
      methods: {
        redirectToServicePage(pageNo) {
          switch (pageNo) {
            case 1:
              this.$router.push({ name: "DocumentPackageDelivery" });
              break;
            case 2:
              this.$router.push({ name: "SamedayPickupDeliveryServices" });
              break;
            case 3:
              this.$router.push({ name: "ManagementofMailRoom" });
              break;
            case 4:
              this.$router.push({ name: "CashOnDelivery" });
              break;
            case 5:
              this.$router.push({ name: "ConfidentialDocumentsDelivery" });
              break;
            case 6:
              this.$router.push({ name: "ConfidentialParcelDelivery" });
              break;
    
            default:
              break;
          }
        },
        redirectSocialMedia(number) {
          switch (number) {
            case 1:
              window.open(social_links.facebook, "_blank");
              break;
            case 2:
              window.open("https://www.instagram.com/?hl=en", "_blank");
              break;
            case 3:
              window.open("https://lk.linkedin.com/", "_blank");
              break;
            case 4:
              window.open("https://www.youtube.com/", "_blank");
              break;
    
            default:
              break;
          }
        },
        onResize() {
          this.windowWidth = window.innerWidth;
        },
        redirectMailTo() {
          window.location.href = "mailto:info@falcon.lk";
        },
        redirectTel() {
          window.location.href = "tel:+94772576600";
        },
      },
    };
    </script>