<template>
  <div>
    <v-tooltip v-if="show" top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"  class="text-white badge bg-orange"> Has Exchange </span>
        </template>
        <span
          >This package has a exchanged package </span
        >
      </v-tooltip>
    
  </div>
</template>
<script>
export default {
  name: "HasExchange",
  props:['show']
};
</script>
<style scoped>
.bg-orange {
  background-color: rgb(255, 128, 9);
  color: white;
}</style>
