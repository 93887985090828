<template>
  <div>
    <AppBar />
    <!-- Desktop Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="branchNetworkBackgroundImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Branch Network</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> BRANCH
              NETWORK
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="branchNetworkBackgroundMobileImg"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Branch Network</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> BRANCH
              NETWORK
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->

    <!-- Desktop (Large Size) Start -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="branchNetworkContentBackgroundImg"
      max-width="100%"
      max-height="100%"
    >
      <v-container style="padding-top: 120px; padding-bottom: 150px">
        <v-row class="text-center mb-5">
          <v-col cols="12" md="12">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 50px;
              "
            >
              Our Branch <span style="color: #0674ba">Network</span>
            </p>
            <p
              class="content-text"
              style="margin: 0 auto; text-align: center; width: 50%"
            >
              Our hubs are placed in strategic locations and our extensive
              agents are equipped with the best technology to offer our
              customers a better service.
            </p>
          </v-col>
        </v-row>

        <v-row justify="space-between">
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/BranchNetwork/sl-map.png"
              max-height="auto"
              max-width="100%"
              src="@/assets/image/BranchNetwork/sl-map.png"
            ></v-img>
          </v-col>
          <v-col cols="12" md="auto">
            <v-img
              lazy-src="@/assets/image/BranchNetwork/compass.png"
              max-height="auto"
              max-width="80%"
              src="@/assets/image/BranchNetwork/compass.png"
            ></v-img>
          </v-col>
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
          <v-col cols="12" md="5">
            <v-card
              style="
                padding: 20px;
                border: 1.5px solid #0674ba;
                border-radius: 16px;
              "
              outlined
            >
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  color: #0674ba;
                  align: center;
                "
              >
                Search Location
              </p>
              <div class="d-flex" style="border-radius: 8px">
                <!-- <v-text-field label="Enter a location.." outlined clearable style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"></v-text-field> -->
                <v-text-field
                  v-model="searchLocationName"
                  label="Enter a location.."
                  single-line
                  outlined
                  style="
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                  "
                ></v-text-field>
                <v-btn
                  @click="searchBranchFromLocationName()"
                  dark
                  color="#0674BA"
                  tile
                  style="
                    padding-top: 27px;
                    padding-bottom: 27px;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                  "
                >
                  <img
                    src="@/assets/image/BranchNetwork/Vector.png"
                    width="25"
                    height="25"
                /></v-btn>
              </div>

              <v-virtual-scroll
                :items="items"
                :item-height="160"
                height="320"
                max-width="100%"
                class="flexcroll"
              >
                <template v-slot:default="{ item }">
                  <v-list-item class="pa-1">
                    <v-list-item-content>
                      <v-card
                        max-width="100%"
                        style="
                          border: 1px solid #c1dcee;
                          border-radius: 8px;
                          padding: 10px;
                        "
                        elevation="0"
                      >
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-img
                              lazy-src="@/assets/image/falcon-icon.png"
                              max-height="auto"
                              max-width="100%"
                              style="margin-top: 20px"
                              src="@/assets/image/falcon-icon.png"
                            ></v-img>
                          </v-col>
                          <v-col cols="12" md="8">
                            <p
                              style="
                                font-family: 'Rubik';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                color: #0674ba;
                                margin-top: 10px;
                              "
                            >
                              {{ item.name }}
                            </p>
                            <v-row no-gutters>
                              <v-col cols="12" md="3">
                                <v-img
                                  lazy-src="@/assets/image/location-icon.svg"
                                  max-height="auto"
                                  max-width="20%"
                                  src="@/assets/image/location-icon.svg"
                                ></v-img>
                              </v-col>
                              <v-col cols="12" md="9">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    letter-spacing: 0.8px;
                                    margin-left: -25px;
                                    margin-bottom: 8px;
                                  "
                                >
                                  {{ item.address }}
                                </p>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" md="3">
                                <v-img
                                  lazy-src="@/assets/image/call-icon.svg"
                                  max-height="auto"
                                  max-width="20%"
                                  src="@/assets/image/call-icon.svg"
                                ></v-img>
                              </v-col>
                              <v-col cols="12" md="9">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    letter-spacing: 0.8px;
                                    margin-left: -25px;
                                    margin-bottom: 8px;
                                  "
                                >
                                  {{ item.phone }}
                                </p>
                              </v-col>
                            </v-row>

                            <v-row no-gutters>
                              <v-col cols="12" md="3">
                                <v-img
                                  lazy-src="@/assets/image/calender.png"
                                  max-height="auto"
                                  max-width="20%"
                                  src="@/assets/image/calender.png"
                                ></v-img>
                              </v-col>
                              <v-col cols="12" md="9">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    letter-spacing: 0.8px;
                                    margin-left: -25px;
                                  "
                                >
                                  {{ item.opening_time }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop (Large Size) End -->

    <!-- Mobile Start -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="branchNetworkContentBackgroundImg"
      max-width="100%"
      max-height="100%"
    >
      <v-container style="padding-top: 80px; padding-bottom: 100px">
        <v-row class="text-center mb-5">
          <v-col cols="12" md="12">
            <p
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 600;
                font-size: 50px;
              "
            >
              Our Branch <span style="color: #0674ba">Network</span>
            </p>
            <p
              class="content-text"
              style="margin: 0 auto; text-align: center; width: 80%"
            >
              Our hubs are placed in strategic locations and our extensive
              agents are equipped with the best technology to offer our
              customers a better service.
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-img
              lazy-src="@/assets/image/BranchNetwork/sl-map-with-compass.png"
              max-height="auto"
              max-width="100%"
              src="@/assets/image/BranchNetwork/sl-map-with-compass.png"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <v-card
              style="
                padding: 20px;
                border: 1.5px solid #0674ba;
                border-radius: 16px;
              "
              outlined
              :loading="loadingBranchNetwork"
            >
              <p
                style="
                  font-family: 'SF-Pro-Display-Semibold';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 22px;
                  color: #0674ba;
                  align: center;
                "
              >
                Search Location
              </p>
              <div class="d-flex">
                <v-text-field
                  v-model="searchLocationName"
                  label="Enter a location.."
                  single-line
                  outlined
                  style="
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                  "
                ></v-text-field>
                <!-- <input type="text" style="border-top-left-radius: 8px; border-bottom-left-radius: 8px; width:90%; border-style: solid;" id="SearchLocation"> -->
                <v-btn
                  @click="searchBranchFromLocationName()"
                  dark
                  color="#0674BA"
                  tile
                  style="
                    padding-top: 27px;
                    padding-bottom: 27px;
                    padding-top: 27px;
                    padding-bottom: 27px;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                  "
                >
                  <img
                    src="@/assets/image/BranchNetwork/Vector.png"
                    width="25"
                    height="25"
                  />
                </v-btn>
              </div>

              <v-virtual-scroll
                :items="items"
                :item-height="170"
                height="350"
                max-width="100%"
              >
                <template v-slot:default="{ item }">
                  <v-list-item class="pa-1">
                    <v-list-item-content>
                      <v-card
                        max-width="100%"
                        style="border: 1px solid #c1dcee; border-radius: 8px"
                        elevation="0"
                      >
                        <v-row>
                          <v-col cols="5" sm="1">
                            <v-img
                              lazy-src="@/assets/image/falcon-icon.png"
                              max-height="auto"
                              max-width="100%"
                              style="margin-top: 50px"
                              src="@/assets/image/falcon-icon.png"
                            ></v-img>
                          </v-col>
                          <v-col cols="6" sm="5">
                            <p
                              style="
                                font-family: 'Rubik';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: #0674ba;
                                margin-top: 15px;
                              "
                            >
                              {{ item.name }}
                            </p>
                            <v-row no-gutters>
                              <v-col cols="3" md="3">
                                <v-img
                                  lazy-src="@/assets/image/location-icon.svg"
                                  max-height="auto"
                                  max-width="25%"
                                  src="@/assets/image/location-icon.svg"
                                ></v-img>
                              </v-col>
                              <v-col cols="9" md="3">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 10px;
                                    letter-spacing: 0.8px;
                                    margin-left: -20px;
                                  "
                                >
                                  {{ item.address }}
                                </p>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="3" md="3">
                                <v-img
                                  lazy-src="@/assets/image/call-icon.svg"
                                  max-height="auto"
                                  max-width="25%"
                                  src="@/assets/image/call-icon.svg"
                                ></v-img>
                              </v-col>
                              <v-col cols="9" md="3">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 10px;
                                    letter-spacing: 0.8px;
                                    margin-left: -20px;
                                  "
                                >
                                  {{ item.phone }}
                                </p>
                              </v-col>
                            </v-row>

                            <v-row no-gutters>
                              <v-col cols="3" md="3">
                                <v-img
                                  lazy-src="@/assets/image/calender.png"
                                  max-height="auto"
                                  max-width="25%"
                                  src="@/assets/image/calender.png"
                                ></v-img>
                              </v-col>
                              <v-col cols="9" md="3">
                                <p
                                  style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 10px;
                                    letter-spacing: 0.8px;
                                    margin-left: -20px;
                                  "
                                >
                                  {{ item.opening_time }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End -->
    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
import axios from "axios";
export default {
  name: "BranchNetwork",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      loadingBranchNetwork: false,
      searchLocationName: "",
      items: [],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.getAllBranchNetwork();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    searchLocationName() {
      if (this.searchLocationName == "") {
        this.getAllBranchNetwork();
      }
    },
  },
  methods: {
    getAllBranchNetwork() {
      /* eslint-disable */
      this.loadingBranchNetwork = true;
      axios
        .get(this.$hostname + "/api/client/branch/get-all")
        .then((response) => {
          this.items = response.data.data;
          this.loadingBranchNetwork = false;
        })
        .catch((error) => {
          this.loadingBranchNetwork = false;
        });
    },
    searchBranchFromLocationName() {
      this.items = this.items.filter((branch) => {
        return branch.name
          .toLowerCase()
          .includes(this.searchLocationName.toLowerCase());
      });
    },
    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}
.branchNetworkBackgroundImg {
  background-image: url("@/assets/image/BranchNetwork/cover-img.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.branchNetworkBackgroundMobileImg {
  background-image: url("@/assets/image/BranchNetwork/mobile-cover.png");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.branchNetworkContentBackgroundImg {
  background-image: url("@/assets/image/BranchNetwork/mask-content.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
.flexcroll {
  scrollbar-face-color: #0674ba;
  scrollbar-shadow-color: #0674ba;
  scrollbar-highlight-color: #ffffff;
  scrollbar-3dlight-color: #ffffff;
  scrollbar-darkshadow-color: #ffffff;
  scrollbar-track-color: #ffffff;
  scrollbar-arrow-color: #ffffff;
}

/* Let's get this party started */
.flexcroll::-webkit-scrollbar {
  width: 10px;
}

.flexcroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.923);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
.flexcroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #0674ba;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
</style>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border: 1px solid #c1dcee;
}
</style>
