<template>
    <div>
        <AppBar v-if="windowWidth <= 600" />

        <v-sheet v-if="windowWidth >= 600" class="mt-0" max-width="100%" color="#E6EBEF" style="height: 100vh">
            <v-container fluid style="max-width: 100% !important">
                <v-row>
                    
                    <v-col cols="12" md="7" style="height: 100vh">
                        <div class="text-center">
                            <p
                                class="text-uppercase"
                                style="font-family: 'Rubik'; font-style: normal; font-weight: 600; font-size: 39px; color: #0674ba; margin-top: 90px"
                            >
                                Fast, Reliable, <br />
                                Accountable <br />
                                and Secure service
                            </p>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    color: #0674ba;
                                    margin-top: 25px;
                                    letter-spacing: 0.8px;
                                "
                            >
                                Our foremost priority is to accommodate the customer’s requirements <br />
                                while maintaining integrity and accuracy consistently.
                            </p>

                            <v-img
                                lazy-src="@/assets/image/Login/login.png"
                                max-height="auto"
                                max-width="70%"
                                src="@/assets/image/Login/login.png"
                                style="margin-left: auto; margin-right: auto; display: block"
                            ></v-img>
                        </div>
                    </v-col>
                   

                    <v-col cols="12" md="5" style="background-color: #ffffff; height: 100vh">
                        <v-img
                            @click="redirectHome()"
                            lazy-src="@/assets/image/falcon-icon.png"
                            max-height="auto"
                            max-width="28%"
                            src="@/assets/image/falcon-icon.png"
                            style="margin-left: 50px"
                        ></v-img>
                        <p
                            style="
                                font-family: 'Rubik';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 25px;
                                color: #0674ba;
                                margin-top: 20px;
                                margin-left: 50px;
                            "
                        >
                            Login to your Account
                        </p>
                        <v-img
                            lazy-src="@/assets/image/Login/blue-bar.png"
                            max-height="auto"
                            max-width="6%"
                            style="background-position: center; margin-bottom: 15px; margin-left: 50px"
                            src="@/assets/image/Login/blue-bar.png"
                        ></v-img>

                        <v-alert v-if="successAlert != null" style="margin-left: 50px; margin-right: 50px" type="success" dense>
                            {{ successAlert }}
                        </v-alert>
                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                margin-top: 25px;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                                margin-top: 50px;
                            "
                        >
                            Mobile Number
                        </p>
                        

                        <v-text-field
                            v-model="username"
                            dense
                            placeholder="eg : +94 717275539"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <p
                            style="
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                color: black;
                                margin-top: 25px;
                                letter-spacing: 0.8px;
                                margin-left: 50px;
                                margin-top: 20px;
                            "
                        >
                            Type Password
                        </p>

                        <v-text-field
                            v-model="password"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            value="Abcdef@123"
                            @click:append="show2 = !show2"
                            dense
                            placeholder="********"
                            solo
                            flat
                            background-color="#F3F4F6"
                            style="margin-left: 50px; margin-right: 50px; border-radius: 10px"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" md="6">
                                <input type="checkbox" id="rememberPassword" name="rememberPassword" value="Bike" style="margin-left: 50px" />
                                <label
                                    for="rememberPassword"
                                    style="
                                        font-family: 'SF-Pro-Display-Semibold';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 12px;
                                        color: black;
                                        letter-spacing: 0.8px;
                                    "
                                >
                                    Remember Password</label
                                >
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="6" class="float-right">
                                <p
                                    style="
                                        font-family: 'SF-Pro-Display-Semibold';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 12px;
                                        color: black;
                                        letter-spacing: 0.8px;
                                        margin-right: 50px;
                                        float: right;
                                        color: #0674ba;
                                        cursor: pointer;
                                    "
                                    @click="redirectPasswordReset()"
                                >
                                    Forgot Password
                                </p>
                            </v-col>
                        </v-row>

                        <v-alert style="margin-left: 50px; margin-right: 50px" type="error" dense v-if="errorAlertText != ''">
                            {{ errorAlertText }}
                        </v-alert>

                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                    color="#0674BA"
                                    @click="checkLoginDetails()"
                                    class="white--text rounded-lg"
                                    large
                                    style="
                                        font-family: 'Rubik-Regular';
                                        font-style: normal;
                                        font-size: 13px;
                                        font-weight: 600;
                                        margin-left: 50px;
                                        padding-left: 50px;
                                        padding-right: 50px;
                                    "
                                >
                                    Sign In
                                </v-btn>
                                <v-btn @click="redirectHome()" class="white--text rounded-lg cancel-btn" large> Go To Home </v-btn>
                                <p
                                    style="
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 12px;
                                        color: black;
                                        letter-spacing: 0.8px;
                                        margin-right: 50px;
                                        float: right;
                                        margin-top: 20px;
                                    "
                                >
                                    Doesn't have account?
                                    <span style="color: #0674ba; font-family: 'SF-Pro-Display-Semibold'; cursor: pointer" @click="redirectToRegisterPage()">
                                        Sign Up here
                                    </span>
                                </p>
                            </v-col>
                        </v-row>

                        <div class="text-center">
                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 11px;
                                    color: black;
                                    margin-top: 25px;
                                    letter-spacing: 0.8px;
                                    margin-top: 70px;
                                "
                            >
                                © 2022 - Falcon Express Delivery All rights reserved.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-sheet v-if="windowWidth <= 600" class="mt-0" max-width="100%" color="#E6EBEF" elevation="0">
            <v-container fluid style="max-width: 100% !important">
                <v-row>
                        
                    <v-col cols="12" md="12" style="background-color: #ffffff">
                        <v-card style="border-radius: 28px; padding: 20px">
                            <v-img
                                lazy-src="@/assets/image/falcon-icon.png"
                                max-height="auto"
                                max-width="70%"
                                src="@/assets/image/falcon-icon.png"
                                style="display: block; margin-left: auto; margin-right: auto; width: 800%"
                            ></v-img>
                            <p style="font-family: 'Rubik'; font-style: normal; font-weight: 600; font-size: 25px; color: #0674ba; margin-top: 20px">
                                Login to your Account
                            </p>
                            <v-img
                                lazy-src="@/assets/image/Login/blue-bar.png"
                                max-height="auto"
                                max-width="18%"
                                style="background-position: center; margin-bottom: 15px"
                                src="@/assets/image/Login/blue-bar.png"
                            ></v-img>
                            <v-alert v-if="successAlert != null" style="margin-left: 50px; margin-right: 50px" type="success" dense>
                            {{ successAlert }}
                        </v-alert>
                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 50px;
                                "
                            >
                                Mobile Number
                            </p>

                            <v-text-field
                                v-model="username"
                                placeholder="eg : +94 717275539"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <p
                                style="
                                    font-family: 'SF Pro Display';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: black;
                                    letter-spacing: 0.8px;
                                    margin-top: 20px;
                                "
                            >
                                Type Password
                            </p>

                            <v-text-field
                                v-model="password"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show2 ? 'text' : 'password'"
                                value="Abcdef@123"
                                @click:append="show2 = !show2"
                                placeholder="********"
                                solo
                                flat
                                background-color="#F3F4F6"
                                style="border-radius: 10px"
                            ></v-text-field>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <input type="checkbox" id="rememberPassword" name="rememberPassword" value="Bike" />
                                    <label
                                        for="rememberPassword"
                                        style="
                                            font-family: 'SF-Pro-Display-Semibold';
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            color: black;
                                            letter-spacing: 0.8px;
                                        "
                                    >
                                        Remember Password</label
                                    >
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="6" class="float-right">
                                    <p
                                        style="
                                            font-family: 'SF-Pro-Display-Semibold';
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            color: black;
                                            letter-spacing: 0.8px;
                                            float: right;
                                            color: #0674ba;
                                            cursor: pointer;
                                        "
                                        @click="redirectPasswordReset()"
                                    >
                                        Forgot Password
                                    </p>
                                </v-col>
                            </v-row>

                            <v-alert type="error" dense v-if="errorAlertText != ''">
                                {{ errorAlertText }}
                            </v-alert>

                            <v-row>
                                <v-col cols="12">
                                    <v-btn color="#0674BA" @click="checkLoginDetails()" class="white--text rounded-lg" large> Sign In </v-btn>
                                    <v-btn @click="redirectHome()" class="white--text rounded-lg cancel-btn" large> Go To Home </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="text-center">
                                        <p
                                            style="
                                                font-family: 'SF Pro Display';
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 14px;
                                                color: black;
                                                letter-spacing: 0.8px;
                                                margin-top: 20px;
                                            "
                                        >
                                            Doesn't have account?
                                            <span
                                                style="color: #0674ba; font-family: 'SF-Pro-Display-Semibold'; cursor: pointer"
                                                @click="redirectToRegisterPage()"
                                            >
                                                Sign Up here
                                            </span>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>

                            <div class="text-center">
                                <p
                                    style="
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 13px;
                                        color: black;
                                        margin-top: 25px;
                                        letter-spacing: 0.8px;
                                        margin-bottom: 25px;
                                    "
                                >
                                    © 2022 - Falcon Express Delivery <br />
                                    All rights reserved.
                                </p>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-overlay :value="loginOverlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import axios from "axios";
import setAuthHeader from "../utils/setAuthHeader";
export default {
    name: "LoginScreen",
    components: {
        AppBar,
    },

    data() {
        return {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            loginOverlay: false,
            show2: false,
            username: "",
            password: "",
            errorAlertText: "",
            successAlert: null,
            rules: {
                required: (value) => !!value || "Required.",
                min: (v) => v.length >= 8 || "Min 8 characters",
                emailMatch: () => `The email and password you entered don't match`,
            },
        };
    },
    mounted() {
        if(this.$route.params.successMessage && this.$route.params.successMessage != null){
            this.showSuccess(this.$route.params.successMessage);
        }
    },
    created() {
        window.addEventListener("resize", this.onResize);
        
    },
    methods: {
        showSuccess(message){
            this.successAlert = message;
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        redirectHome() {
            this.$router.push({ name: "home" });
        },
        redirectToRegisterPage() {
            this.$router.push({ name: "RegisterScreen" });
        },
        redirectPasswordReset() {
            this.$router.push({ name: "PasswordReset" });
        },
        checkLoginDetails() {
            /* eslint-disable */
            this.loginOverlay = true;
            axios
                .post(this.$hostname + "/api/client/login", {
                    username: this.username,
                    password: this.password,
                })
                .then((response) => {
                    this.errorAlertText = "";
                    this.successAlert = null;
                    localStorage.setItem("userDetails", JSON.stringify(response.data.data));
                    setAuthHeader(response.data.data.token);
                    let user = localStorage.getItem("userDetails");
                    user = JSON.parse(user);
                    if (user.role == "CUSTOMER") {
                        this.$router.push({ name: "CustomerDashboard" });
                    } else {
                        this.$router.back();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorAlertText = "";
                    this.successAlert = null;
                    if(error.response.data){
                        
                        this.errorAlertText = error.response.data.message;
                    }
                    else{
                    this.errorAlertText = "Unexpected error";
                    this.successAlert = null;
                    }
                })
                .finally(() => {
                    this.loginOverlay = false;
                });
        },
    },
};
</script>
<style scoped>
.cancel-btn {
    font-family: "Rubik-Regular";
    font-style: normal;
    background-color: rgb(26, 187, 131) !important;
    font-size: 13px;
    font-weight: 600;
    margin-left: 25px;
    padding-left: 50px;
    padding-right: 50px;
}
</style>
