<template>
  <v-app id="inspire">
    <AppBar NavIcon="NavIcon"> </AppBar>
    <div style="background: #e6ebef; text-align: center">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card elevation="4">
              <v-card-title>
                Received Payments
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent lazy-validation ref="payment">
              <v-card elevation="8">
                <v-card-title>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Transaction Number"
                      clearable
                      solo
                      dense
                      v-model="form.cod_transaction_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Invoice Number"
                      clearable
                      solo
                      dense
                      v-model="form.invoice_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="form.cod_transaction_start"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3">
                    <v-text-field
                      label="Date"
                      type="date"
                      clearable
                      solo
                      dense
                      v-model="form.cod_transaction_end"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="3" sm="3">
                    <v-btn depressed @click="search()" color="primary"
                      >Search</v-btn
                    >
                    <v-btn
                      class="ml-5 white--text"
                      depressed
                      @click="clearSearch()"
                      color="red"
                      >Clear</v-btn
                    >
                  </v-col>
                </v-card-title>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="8">
              <v-data-table
                :headers="payments_headers"
                :items="payments"
                hide-default-footer
                :items-per-page="pagination.meta.per_page"
              >
              <template v-slot:[`item.barcode`]="{ item }">
                  <CodeColumn
                    :barcode="item.barcode"
                    :sender_return="item.returned"
                    :child_exchange="item.exchange"
                  />
                </template>

                <template v-slot:[`item.cod_amount`]="{ item }">
                 {{formatNumbers(item.cod_amount)}}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="blue"
                    elevation="2"
                    @click="openViewModal(item)"
                    outlined
                    rounded
                    x-small
                  >
                    View
                  </v-btn>
                </template>
              </v-data-table>
              <v-pagination
                v-if="getPagesCount > 1"
                class="mt-4"
                v-model="pagination.meta.current_page"
                :length="getPagesCount"
                :total-visible="7"
                @input="paginationChanged"
                circle
              ></v-pagination>
            </v-card>
          </v-col>
        </v-row>
        <!--view modal open-->
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Payment Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span>Barcode : {{ this.barcode }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span
                        >Package Status :
                        <v-chip
                          color="green"
                          dark
                          x-small
                          v-if="this.status == '1'"
                        >
                          Payment Success
                        </v-chip>
                        <v-chip color="red" dark x-small v-else>
                          Payment Failed
                        </v-chip>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span
                        >Paid Amount : {{ this.paid_amount_formatted }}</span
                      >
                    </v-col>
                    <v-col cols="12">
                      <span>Package Status : {{ this.status_message }}</span>
                    </v-col>
                    <v-col cols="12">
                      <span>Date : {{ this.date }}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
       <!-- view modal close -->
      </v-container>
    </div>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
import CodeColumn from '../CodeColumn/Index.vue';

export default {
  name: "CustomerPayments",
  components: {
    AppBar,
    FooterView,
    CodeColumn
  },

  data() {
    return {
      payments_headers: [
        { text: "Barcode", value: "barcode" },
        { text: "Transaction Number", value: "transaction_number" },
        { text: "Invoice Number", value: "invoice_number" },
        { text: "Amount", align: "right", value: "cod_amount" },
        { text: "Paid At", value: "transaction_date" },
        // { text: "Action", value: "actions" },
      ],
      payments: [],
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      form: {
        cod_transaction_number: null,
        invoice_number: null,
        cod_transaction_start: null,
        cod_transaction_end: null,
      },
      dialog: false,
      type_text: null,
      date: null
    };
  },
  created() {
    this.setToken();
  },
  computed: {
    getPagesCount() {
      const count = this.pagination.meta.total / this.pagination.meta.per_page;
      return Math.ceil(count);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.search();
  },
  methods: {
    paginationChanged(page) {
      this.search(page);
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    search() {
      axios
        .get(this.$hostname + "/api/client/payment/get-paginate", {
          params: this.form,
        })
        .then((response) => {
          console.log(response);
          this.payments = response.data.data;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearSearch() {
      this.form.cod_transaction_number = null;
      this.form.invoice_number = null;
      this.form.cod_transaction_start = null;
      this.form.cod_transaction_end = null;
      this.search();
    },

    openViewModal(item) {
      this.dialog = true;
      this.barcode = item.barcode;
      this.status = item.status;
      this.paid_amount_formatted = item.paid_amount_formatted;
      this.status_message = item.status_message;
      this.date = item.date;
    },
  },
};
</script>
