<template>
  <div>
    <AppBar />
    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-5 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: -15px;
            "
          >
            Terms & Conditions
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-10 mb-5 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: -15px;
            "
          >
            Terms & Conditions
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mb-16" style="width: 60%">
      <v-row>
        <v-col cols="12" md="12">
          <v-list-item v-for="(term, index1) in terms" :key="index1">
            <v-list-item-content>
              <!-- <v-list-item-title> {{ index }}.</v-list-item-title> -->
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">{{index1 + 1}}. {{ term.description }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mb-16" style="width: 100%">
      <v-row>
        <v-col cols="12" md="12">
          <v-list-item v-for="(term,index2) in terms" :key="index2">
            <v-list-item-content>
              <!-- <v-list-item-title> {{ index }}.</v-list-item-title> -->
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">{{index2 + 1}}. {{ term.description }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->

    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
export default {
  name: "TermAndCondition",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      terms: [
        {
          title: "",
          description:
            "In this contract; ‘Courier’ shall mean and include the Falcon Express Ceylon (Pvt) Ltd, its proprietors, employees and agents",
        },
        {
          title: "",
          description:
            "‘Customer’ shall mean and include any individual, firm or company who engages or enters into a contract with the Courier.",
        },
        {
          title: "",
          description:
            "The Courier is not a common carrier. All goods are transported or stored, and all services are performed by the Courier subject to these conditions of contract.",
        },
        {
          title: "",
          description:
            "The Courier reserves the right to refuse to transport any goods for any person, firm or company at its discretion and the Courier shall not be bound to give any reason for such refusal.",
        },
        {
          title: "",
          description:
            "The customer hereby authorizes the courier to arrange any independent carrier(s) for the transportation of any such goods under this contract.",
        },
        {
          title: "",
          description:
            "The Independent Carrier shall be entitled to the full benefit of these terms and conditions to the same extent as the Courier, and the Courier shall be deemed to have entered into this contract for its own benefit, and also as agent for the Independent Carrier(s).",
        },
        {
          title: "",
          description:
            "Any individual who is delivering the goods to the Courier shall be deemed to be the agent of the Customer for the purposes of this contract, and the Customer hereby warrants that such a person is authorized to enter into the contract and sign the consignment note as agent for the Customer.",
        },
        {
          title: "",
          description:
            "The Customer expressly warrants to the Courier that the Customer is either the owner of or an authorized agent for the owner of any goods under this contract.",
        },
        {
          title: "",
          description:
            "The Customer hereby authorizes the Courier to handle, store or carry the goods by any method or route which the Courier in its discretion shall think fit",
        },
        {
          title: "",
          description:
            "The Courier is authorized to deliver the goods to the address with the contact details given by the Customer",
        },
        {
          title: "",
          description:
            "The goods (Document / Parcel) are always at risk of the customer not the courier, and the courier is not responsible for any damage or loss or of the goods which are not been covered by the insurance,",
        },
        {
          title: "",
          description:
            "If the courier was unable to deliver the goods due to wrong details provided by the Customer, the good will be returned to the customer while charging for Delivery and adding reasonable charge for returning it.",
        },
        {
          title: "",
          description:
            "The Customer is not authorized to send any goods which are dangerous, drugs, explosive goods, flammable goods, weapons, cash, gems, jewelries, poison, liquids and any goods that prohibited by the law of the country.",
        },
        {
          title: "",
          description:
            "The customer is responsible to pay all the charges which incurred during the transportation of goods to receiver prescribed by the courier.",
        },
        {
          title: "",
          description:
            "The customer could enter to an insurance policy through courier if there a necessity for protection of the delivering goods and the cost will be charged to customer. ",
        },
        {
          title: "",
          description:
            "The charges will be calculated according to the weight and size of the package",
        },
        {
          title: "",
          description:
            "The courier is entitled to pay maximum Rs.5000/= for any loss or damage good by the courier ",
        },
        {
          title: "",
          description:
            "The customer’s will get the opportunity to pay the courier charges through cash, bank transfer and credit cards and must bear the additional service charges incurred to provide such services.",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}

.t-and-condition.v-list-item__subtitle {
  text-overflow: initial !important;
  white-space: inherit !important;
  margin-top: 10px !important;
}

.content-text{
  font-weight: 700;
  color: black;
}
</style>
