<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <h4>Welcome {{ authUser.user.display_name }} !</h4>
        </v-col>
        <v-col cols="3" class="text-end">
          <span
            class="profile-link"
            @click="$router.push({ name: 'CustomerProfile' })"
            >Update My Profile</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(card, index) in cards"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-card outlined elevation="2">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h6 mb-1">
                  <span class="name-link" @click="redirectToPage()">{{
                    card.name
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="text-h5 mb-1">
                  {{ card.count }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="white">
                <v-img
                  :lazy-src="card.icon"
                  max-height="auto"
                  max-width="120px"
                  :src="card.icon"
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row class="action-row">
        <v-col cols="4">
          <v-btn class="action-btn" text @click="deletePackage()"
            >Barcode</v-btn
          >
        </v-col>
      </v-row> -->
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import setAuthHeader from "@/utils/setAuthHeader";
import axios from "axios";
import image_pending from "@/assets/image/pending.png";
import image_rejected from "@/assets/image/rejected.png";
import image_accept from "@/assets/image/accept.png";
import image_process from "@/assets/image/process.png";
import image_shipped from "@/assets/image/shipped.png";
import image_all from "@/assets/image/all.png";

export default {
  name: "CustomerDashboard",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      pending_orders_count: null,
      rejected_orders_count: null,
      accepted_orders_count: null,
      processing_orders_count: null,
      delivered_orders_count: null,
      total_orders_count: null,
      cards: [
        {
          name: "Pending Orders Count",
          icon: image_pending,
          count: this.pending_orders_count,
        },
        {
          name: "Rejected Orders Count",
          icon: image_rejected,
          count: this.rejected_orders_count,
        },
        {
          name: "Accepted Orders Count",
          icon: image_accept,
          count: this.accepted_orders_count,
        },
        {
          name: "Processing orders Count",
          icon: image_process,
          count: this.processing_orders_count,
        },
        {
          name: "Delivered Orders Count",
          icon: image_shipped,
          count: this.delivered_orders_count,
        },
        {
          name: "Total Orders Count",
          icon: image_all,
          count: this.total_orders_count,
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
    this.dashboardCount();
  },
  watch: {
    windowWidth(newW, oldW) {
      console.log("new", newW, " | old", oldW);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    authUser() {
      return JSON.parse(localStorage.getItem("userDetails"));
    },
  },
  methods: {
    redirectToPage() {
      this.$router.push({ name: "CustomerPackages" });
    },
    setToken() {
      let user = this.authUser;
      console.log(user);

      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    dashboardCount() {
      axios
        .get(this.$hostname + "/api/client/dashboard/get-data")
        .then((response) => {
          this.pending_orders_count = this.cards[0].count =
            response.data.data.pending_orders_count;
          this.rejected_orders_count = this.cards[1].count =
            response.data.data.rejected_orders_count;
          this.accepted_orders_count = this.cards[2].count =
            response.data.data.accepted_orders_count;
          this.processing_orders_count = this.cards[3].count =
            response.data.data.processing_orders_count;
          this.delivered_orders_count = this.cards[4].count =
            response.data.data.delivered_orders_count;
          this.total_orders_count = this.cards[5].count =
            response.data.data.total_orders_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.name-link {
  cursor: pointer;
}

.name-link:hover {
  color: #0674ba;
  transition: all 0.5s;
}
.profile-link {
  color: #0674ba;
  cursor: pointer;
}

.action-row {
  background-color: #43aef027;
  border-radius: 5px;
  margin-bottom: 1px;
}

.action-btn {
    background-color: #0674ba;
    color: white;
}
</style>
