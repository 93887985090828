<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card elevation="4">
            <v-card-title class="card__title">
              Your Packages
              <div>
                <v-btn
                  @click="addBulk()"
                  depressed
                  class="white--text mr-2"
                  color="rgb(10 69 151)"
                  >Add Bulk</v-btn
                >
                <v-btn
                  @click="addPackages()"
                  depressed
                  class="white--text"
                  color="#17A2B8"
                  >Add Package</v-btn
                >
              </div>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="3" xl="3">
          <v-text-field
            label="Enter Barcode"
            clearable
            solo
            dense
            v-model="barcode"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3" xl="3">
          <v-select
            v-model="stage"
            :items="stageItems"
            label="Select Stage"
            item-text="name"
            item-value="id"
            dense
            solo
            @change="selectStage()"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            v-model="start"
            type="date"
            label="Date"
            clearable
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Date"
            type="date"
            clearable
            solo
            dense
            v-model="end"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn depressed @click="search()" class="mt-2" color="primary"
            >Search</v-btn
          >
          <v-btn
            class="ml-5 mt-2 white--text"
            depressed
            @click="clearSearch()"
            color="red"
            >Clear</v-btn
          >

          <v-btn
            :disabled="printing"
            depressed
            @click="exportExcel"
            class="mt-2 ml-5"
            :color="printing ? '#C9C9C9' : 'success'"
            ><v-icon class="mr-3"> mdi-file-excel </v-icon> Export</v-btn
          >
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <v-btn
            depressed
            :title="
              selectedPackages.length == 0
                ? 'Please select one or more packages'
                : ''
            "
            @click="printMultiple"
            class="mt-2 ml-5"
            :color="selectedPackages.length > 0 ? 'success' : 'muted'"
            size="small"
            ><v-icon class="mr-3"> mdi-cloud-print </v-icon> Print Bulk ({{
              selectedPackages.length
            }})</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card elevation="2">
            <!-- MTM0MTc0MjgzNTI2MDI4MTczMDIxODk0OTc3MzU3MjM3NzQ0OTYzOA -->
            <form
              v-show="false"
              ref="paymentForm"
              method="post"
              :action="getPayHereUrl"
            >
              <input
                type="hidden"
                name="merchant_id"
                v-model="selectedPaymentItem.merchant"
              />
              <!-- Replace your Merchant ID -->
              <input
                type="hidden"
                name="hash"
                v-model="selectedPaymentItem.hash"
              />

              <input
                type="hidden"
                name="return_url"
                value="https://falcon.lk/customer/packages"
              />
              <input
                type="hidden"
                name="cancel_url"
                value="https://falcon.lk/customer/packages"
              />
              <input
                type="hidden"
                name="notify_url"
                value="https://backend.falcon.lk/payment-gateway/notify"
              />

              <input
                type="text"
                name="order_id"
                v-model="selectedPaymentItem.barcode"
              />
              <input
                type="text"
                name="items"
                v-model="selectedPaymentItem.barcode"
              />
              <input type="text" name="custom_1" value="This is custom 1" />
              <input type="text" name="currency" value="LKR" />
              <input
                type="text"
                name="amount"
                v-model="selectedPaymentItem.total_amount"
              />

              <input
                type="text"
                name="first_name"
                v-model="selectedPaymentItem.barcode"
              />
              <input
                type="text"
                name="last_name"
                v-model="selectedPaymentItem.barcode"
              />
              <input
                type="text"
                name="email"
                :value="selectedPaymentItem.sender_email"
              />
              <input
                type="text"
                name="phone"
                :value="selectedPaymentItem.sender_phone"
              />
              <input
                type="text"
                name="address"
                :value="selectedPaymentItem.pickup_city_name"
              />
              <input
                type="text"
                name="city"
                :value="selectedPaymentItem.pickup_city_name"
              />
              <input type="hidden" name="country" value="Sri Lanka" />
            </form>
            <v-data-table
              :headers="packages_headers"
              :items="getCustomerPackages"
              hide-default-footer
            >
              <template v-slot:[`item.barcode`]="{ item }">
                <span>{{ item.barcode }}</span>
                &nbsp;
                <v-chip
                  :title="'Exchange with ' + item.exchange.barcode"
                  color="red"
                  dark
                  x-small
                  v-if="item.exchange"
                >
                  Exchange
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip color="red" dark x-small v-if="item.status == '0'">
                  Cancelled
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.status == '1'"
                >
                  Active
                </v-chip>
                <v-chip
                  color="yellow"
                  dark
                  x-small
                  v-else-if="item.status == '2'"
                >
                  Pending
                </v-chip>
                <v-chip color="red" dark x-small v-else-if="item.status == '3'">
                  Rejected
                </v-chip>
                <v-chip color="brown" dark x-small v-else> unknown </v-chip>
              </template>
              <template v-slot:[`item.public_status_text`]="{ item }">
                <v-chip
                  color="ash"
                  dark
                  x-small
                  v-if="item.public_status_text == 'Order Placed'"
                >
                  Order Placed
                </v-chip>
                <v-chip
                  color="blue"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Processing'"
                >
                  Processing
                </v-chip>
                <v-chip
                  color="orange"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Shipped'"
                >
                  Shipped
                </v-chip>
                <v-chip
                  color="green"
                  dark
                  x-small
                  v-else-if="item.public_status_text == 'Delivered'"
                >
                  Delivered
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div style="display: inline-block;">
                  <v-menu left bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="viewPackage(item.id)">
                        <v-list-item-title>
                          <v-icon class="mr-3">mdi-eye</v-icon>
                          View
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="!item.editable"
                        @click="openChangeRequest(item)"
                      >
                        <v-list-item-title>
                          <v-icon class="mr-3">mdi-atom-variant</v-icon>
                          Edit Request
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          item.payment_type == 2 &&
                          item.pay_by == 1 &&
                          item.courier_confirm &&
                          item.total_amount > item.paid_amount
                        "
                        @click="pay(item)"
                      >
                        <v-list-item-title>
                          <v-icon class="mr-3">mdi-credit-card</v-icon>
                          Card Payment
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="EditPackage(item.id)"
                        v-if="item.editable == true && item.status != 3"
                      >
                        <v-list-item-title>
                          <v-icon class="mr-3"> mdi-pencil </v-icon>
                          Edit
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="openDeleteDialog(item)"
                        v-if="item.editable == true"
                      >
                        <v-list-item-title>
                          <v-icon class="mr-3"> mdi-delete </v-icon>
                          Delete
                        </v-list-item-title>
                      </v-list-item>

                      <!-- <v-list-item
                      @click="OpenDeleteDialog(item.id)"
                      v-else
                      disabled
                    >
                      <v-list-item-title>
                        <v-icon class="mr-3"> mdi-delete </v-icon>
                        Cancle
                      </v-list-item-title>
                    </v-list-item> -->
                    </v-list>
                  </v-menu>
                  <v-checkbox
                    :disabled="isPrintDisabled(item)"
                    v-model="selectedPackages"
                    :value="item.id"
                  ></v-checkbox>
                </div>
              </template>
            </v-data-table>
            <v-pagination
              v-if="getPagesCount > 1"
              class="mt-4"
              v-model="pagination.meta.current_page"
              :length="getPagesCount"
              :total-visible="7"
              @input="paginationChanged"
              circle
            ></v-pagination>
          </v-card>
        </v-col>
      </v-row>
      <!--delete modal close-->

      <!--change request modal open-->
      <v-row justify="center">
        <v-dialog v-model="changeRequest.open" persistent max-width="50%">
          <v-card>
            <v-card-title>
              <span class="text-h5">Edit Request</span>
              <p class="change-request-sub">
                You can submit a request here if you have some preferences or
                modifications you'd like to make to the information provided.
                Our staff will review your request and reach out to you
                accordingly.
              </p>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="changeRequest.description"
                      label="Your Description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeChangeRequest">
                Close
              </v-btn>
              <v-btn color="green darken-1" text @click="submitChangeRequest">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!--change request modal close-->
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import axios from "axios";
import setAuthHeader from "@/utils/setAuthHeader";
export default {
  name: "CustomerPackages",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      getCustomerPackages: [],
      pagination: {
        meta: {
          current_page: 1,
        },
      },
      printing: false,
      selectedPackages: [],
      packages_headers: [
        { text: "Barcode", value: "barcode", sortable: false },
        { text: "Pickup City", value: "pickup_city_name", sortable: false },
        { text: "Delivery City", value: "delivery_city_name", sortable: false },
        {
          text: "Total Amount",
          align: "right",
          value: "total_amount_formatted",
          sortable: false,
        },
        {
          text: "Courier Charge",
          value: "courier_charge_formatted",
          align: "right",
          sortable: false,
        },
        {
          text: "Paid Amount",
          align: "right",
          value: "paid_amount",
          sortable: false,
        },
        { text: "Pickup Date", value: "package_date", sortable: false },
        { text: "Created at", value: "created_at", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Current Stage", value: "public_status_text", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      barcode: null,
      stage: null,
      start: null,
      end: null,
      stage_change: null,
      dialog: false,
      stageItems: [
        { id: "1", name: "Order Placed" },
        { id: "2", name: "Processing" },
        { id: "3", name: "Shipped" },
        { id: "4", name: "Delivered" },
      ],
      pickup_city: null,
      delivery_city: null,
      weight: null,
      lenght: null,
      height: null,
      width: null,
      descripition: null,
      date: null,
      package_id: null,
      receiver_name: null,
      receiver_contact: null,
      receiver_fax: null,
      receiver_email: null,
      receiver_address: null,
      receiver_landmark: null,
      sender_name: null,
      sender_contact: null,
      sender_fax: null,
      sender_email: null,
      sender_address: null,
      sender_landmark: null,
      page: 1,
      changeRequest: {
        open: false,
        package: null,
        description: null,
      },
      selectedPaymentItem: {
        barcode: "",
        formatted_total: "",
        hash: "",
        merchant: "",
        sender_email: "",
        sender_phone: "",
        pickup_city_name: "",
      },
    };
  },
  computed: {
    getPayHereUrl() {
      return "https://www.payhere.lk/pay/checkout";
      // return "https://sandbox.payhere.lk/pay/checkout";
    },
    getPagesCount() {
      const count = this.pagination.meta.total / this.pagination.meta.per_page;
      return Math.ceil(count);
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.setToken();
    this.getPackages();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    isPrintDisabled(item) {
      return item.status == 3; //disable print if package is rejected by staff
    },
    paginationChanged(page) {
      this.getPackages(page);
    },
    pay(item) {
      this.selectedPaymentItem = item;
      setTimeout(() => {
        this.$refs.paymentForm.submit();
      }, 1000);
    },
    openChangeRequest(item) {
      this.changeRequest.open = true;
      this.changeRequest.package = item;
    },
    closeChangeRequest() {
      this.changeRequest.open = false;
      this.changeRequest.package = null;
      this.changeRequest.description = null;
    },
    submitChangeRequest() {
      if (this.changeRequest.description == null) return;
      axios
        .post(this.$hostname + "/api/client/change-requests/store", {
          package_id: this.changeRequest.package.id,
          description: this.changeRequest.description,
        })
        .then(() => {
          this.closeChangeRequest();
          this.showSuccess("Your request has been submitted.", "Submitted!");
        })
        .catch((error) => {
          console.log(error);
          const message = this.getErrorMessage(error);
          this.showError(message);
        });
    },
    getErrorMessage(error) {
      if (error?.response?.data?.message) return error?.response?.data?.message;
      return "Something wrong";
    },
    printMultiple() {
      if (this.selectedPackages.length == 0) return;
      axios
        .post(
          this.$hostname + "/api/client/print",
          {
            packages: this.selectedPackages,
          },
          {
            responseType: "blob",
            Accept: "application/pdf",
          }
        )
        .then((response) => {
          console.log("success");
          if (response.status == 200) {
            this.convertBlobToPDF(response.data);
          } else {
            //   alert("Not Authenticated");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    convertBlobToPDF(blobData) {
      const url = window.URL.createObjectURL(
        new Blob([blobData], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      console.log(link);
      link.href = url;
      // link.setAttribute("download", "staffs.pdf"); //or any other extension
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    addPackages() {
      //     this.$swal('Temporary Unavailable',
      // 'Please note we have temporary stopped collecting new orders <b>untill 8th of May </b>',
      // 'info');
      this.$router.push({ name: "AddPackages" });
    },
    addBulk() {
      this.$router.push({ name: "AddBulkPackages" });
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    getPackages(page = 1) {
      axios
        .get(this.$hostname + "/api/client/package/get-paginate?page=" + page)
        .then((response) => {
          this.getCustomerPackages = response.data.package;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDeleteDialog(pkg) {
      let message = "Package will be deleted permanently";
      let title = "Delete?";
      if (pkg.child_exchanges.length > 0) {
        title = "Delete Both?";
        message =
          "Packages will be deleted permanently.<br />  This package has an exchanged package,<br /><b> BOTH PACKAGES WILL BE DELETED </b>";
      }
      this.$swal
        .fire({
          title: title,
          html: message,
          showDenyButton: true,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Delete",
          denyButtonText: `Cancel`,
          focusDeny: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deletePackage(pkg.id);
          }
        });
    },
    deletePackage(id) {
      axios
        .delete(this.$hostname + "/api/client/package/" + id)
        .then(() => {
          this.getPackages();
          this.showSuccess("Package has been deleted.", "Deleted!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      axios
        .get(this.$hostname + "/api/client/package/get-paginate", {
          params: {
            barcode: this.barcode,
            public_status: this.stage_change,
            start: this.start,
            end: this.end,
          },
        })
        .then((response) => {
          this.getCustomerPackages = response.data.package;
          this.pagination.meta = response.data.pagination;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel() {
      let url = this.$hostname + "/api/client/package/export?";
      if (this.barcode != null) {
        url += "barcode=" + this.barcode;
      }
      if (this.stage_change != null) {
        url += "public_status=" + this.stage_change;
      }
      if (this.start != null) {
        url += "start=" + this.start;
      }
      if (this.end != null) {
        url += "end=" + this.end;
      }
      this.printing = true;

      axios
        .get(url, {
          headers: {
            "Content-Disposition": "attachment; filename=template.xlsx",
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          // if (response.data.status == "success") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          console.log(link);
          link.href = url;
          link.setAttribute("download", "customer_packages.xlsx"); //or any other extension
          // link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          // } else {
          //   alert("Not Authenticated");
          // }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.printing = false;
        });
    },
    selectStage() {
      let stage_id = this.stage;
      this.stage_change = stage_id;
    },
    Clear() {
      this.barcode = null;
      this.start = null;
      this.end = null;
      this.status = null;
      (this.stage = null), (this.stage_change = null);
    },
    clearSearch() {
      this.getPackages();
      this.Clear();
    },
    viewPackage(id) {
      this.$router.push({
        name: "ViewPackage",
        query: { id: id },
      });
    },
    EditPackage(id) {
      this.$router.push({
        name: "EditPackages",
        query: { id: id },
      });
    },
  },
};
</script>
<style scoped>
.card__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.danger {
  background-color: red;
}

.change-request-sub {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  opacity: 0.8;
}
</style>
