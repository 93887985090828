<template>
  <div>
    <AppBar />
    <!-- Desktop Start  -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="serviceBackgroundImg1"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">
              Documents & Packages Delivery
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> DOCUMENTS &
              PACKAGES DELIVERY
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End  -->

    <!-- Mobile Start  -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="serviceBackgroundImg1"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">
              Documents & Packages Delivery
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> DOCUMENTS &
              PACKAGES DELIVERY
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End  -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16">
      <v-row>
        <v-col cols="12" md="4">
          <OurMainServicesSidePanelDesktop />

          <DownloadsDesktop />

          <ContactInfo />
        </v-col>
        <v-col cols="12" md="8">
          <v-img
            class="rounded-xl"
            lazy-src="@/assets/image/service-1-img.jpg"
            max-height="auto"
            max-width="100%"
            style="background-position: center"
            src="@/assets/image/service-1-img.jpg"
          ></v-img>

          <p class="font-weight-bold text-h4 mt-10" style="color: #112548">
            Documents & Packages Delivery
          </p>

          <p class="content-text mt-10">
            Document and package delivery is the most common service which is
            provided by us. In this service the customer could make a request
            through our online platform to collect their packages from the
            specified location. After we go through the package details that you
            have updated we’ll send you a notice of acceptance for collection
            and a suitable vehicle and an agent will be assigned by us to
            collect it from your doorstep. <br /><br />We do not charge any
            amount until we collect the package so the charges calculation will
            commence from the collection point and end from its destination
            point with the consideration of the package weight. There’s a fixed
            minimum charge for document and package delivery while considering
            its distance. The whole charges could be seen at the point when
            entering the package details to our online system by you.<br /><br />
            In this service type we are assured to complete a delivery within 24
            hours from the time of its collection. For each and every delivery
            we do update the status of the delivery to the online system until
            it reaches the destination.
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-sub-img-1.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-sub-img-1.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-sub-img-2.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-sub-img-2.jpg"
              ></v-img>
            </v-col>
          </v-row>

          <IconsPanel />
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-16 mb-16">
      <v-row>
        <v-col cols="12" md="8">
          <v-img
            class="rounded-xl"
            lazy-src="@/assets/image/service-1-img.jpg"
            style="background-position: center; height: 415px"
            src="@/assets/image/service-1-img.jpg"
          ></v-img>

          <p class="font-weight-bold text-h4 mt-10" style="color: #112548">
            Documents & <br />
            Packages Delivery
          </p>

          <p class="mt-10 content-text">
            Document and package delivery is the most common service which is
            provided by us. In this service the customer could make a request
            through our online platform to collect their packages from the
            specified location. After we go through the package details that you
            have updated we’ll send you a notice of acceptance for collection
            and a suitable vehicle and an agent will be assigned by us to
            collect it from your doorstep.<br /><br />
            We do not charge any amount until we collect the package so the
            charges calculation will commence from the collection point and end
            from its destination point with the consideration of the package
            weight. There’s a fixed minimum charge for document and package
            delivery while considering its distance. The whole charges could be
            seen at the point when entering the package details to our online
            system by you.<br /><br />
            In this service type we are assured to complete a delivery within 24
            hours from the time of its collection. For each and every delivery
            we do update the status of the delivery to the online system until
            it reaches the destination.
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-sub-img-1.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-sub-img-1.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-sub-img-2.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-sub-img-2.jpg"
              ></v-img>
            </v-col>
          </v-row>
          <br />
          <IconsPanel />
        </v-col>
        <v-col cols="12" md="4">
          <OurMainServiceSidePanelMobile />

          <DownloadsMobile />

          <ContactInfoMobile />
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->
    <FooterView />
  </div>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import OurMainServiceSidePanelMobile from "./OurMainServiceSidePanelMobile.vue";
import OurMainServicesSidePanelDesktop from "./OurMainServicesSidePanelDesktop.vue";
import DownloadsDesktop from "./DownloadsDesktop.vue";
import DownloadsMobile from "./DownloadsMobile.vue";
import IconsPanel from "./IconsPanel.vue";
import ContactInfo from "./ContactInfo.vue";
import ContactInfoMobile from "./ContactInfo.vue";
import social_links from "@/config";
export default {
  name: "DocumentPackageDelivery",
  components: {
    AppBar,
    FooterView,
    OurMainServiceSidePanelMobile,
    OurMainServicesSidePanelDesktop,
    DownloadsDesktop,
    DownloadsMobile,
    IconsPanel,
    ContactInfo,
    ContactInfoMobile
},

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },
    redirectSocialMedia(number) {
      switch (number) {
        case 1:
          window.open(social_links.facebook, "_blank");
          break;
        case 2:
          window.open("https://www.instagram.com/?hl=en", "_blank");
          break;
        case 3:
          window.open("https://lk.linkedin.com/", "_blank");
          break;
        case 4:
          window.open("https://www.youtube.com/", "_blank");
          break;

        default:
          break;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    redirectMailTo() {
      window.location.href = "mailto:info@falcondelivery.com";
    },
    redirectTel() {
      window.location.href = "tel:0123526589";
    },
  },
};
</script>
<style>
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}
.serviceBackgroundImg1 {
  background-image: url("@/assets/image/Mask-service-1.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
</style>
