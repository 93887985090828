<template>
  <v-app id="inspire">
    <AppBar> </AppBar>
    <v-container fluid>
      <v-alert type="error" dense v-if="errorAlertText">
        {{ errorAlertText }}
      </v-alert>
      <v-alert type="success" dense v-if="successAlertText">
        {{ successAlertText }}
      </v-alert>
      <v-row>
        <v-col cols="12" class="mb-2">
          <v-btn
            @click="backToList"
            depressed
            class="white--text"
            color="primary"
          >
            <v-icon>mdi-chevron-double-left</v-icon>Back To Dashboard</v-btn
          >
        </v-col>
      </v-row>
      <v-form @submit.prevent lazy-validation autocomplete="off" ref="form">
        <v-card>
          <v-row>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Profile Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            @input="nameChange"
                            label="Name ( required )"
                            v-model="form.name"
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            @input="changeBusinessName"
                            label="Business Name ( optional )"
                            v-model="form.business_name"
                            clearable
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                          <v-text-field
                            label="E-mail ( optional )"
                            v-model="form.email"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                          <v-text-field
                            v-model="form.phone"
                            label="Phone number ( required )"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                          <v-text-field
                            @input="whatsAppChange"
                            v-model="form.whatsapp"
                            label="Whatsapp number  ( optional )"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            name="address"
                            v-model="form.address"
                            label="Address"
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            type="password"
                            v-model="form.password"
                            label="Password"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-text-field
                            type="password"
                            v-model="form.password_confirmation"
                            label="Password Confirmation"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Configurations</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex flex-column" cols="12" sm="12">
                          <v-switch
                            class="switch-component"
                            :disabled="nameSwitchDisabled"
                            @change="changeSwitch"
                            v-model="switch_business_name"
                            label="Select business name as display name"
                            inset
                          ></v-switch>
                          <span>
                            Display name will be : {{ form.display_name }}</span
                          >
                        </v-col>

                        <v-col class="d-flex flex-row" cols="12" sm="12">
                          <v-radio-group
                            v-model="form.message_method"
                            inline
                            label="Preferred SMS receiving method"
                          >
                            <v-radio label="SMS" value="SMS"></v-radio>
                            <v-radio
                              :disabled="form.whatsapp == ''"
                              label="Whatsapp"
                              value="WHATSAPP"
                            ></v-radio>
                            <v-radio label="Both" value="BOTH"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="2">
                    <v-card-title>
                      <span>Bank Details</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Name ( required )"
                            v-model="form.banks[0].name"
                          ></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Bank name ( required )"
                            v-model="form.banks[0].bank"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Branch name ( required )"
                            v-model="form.banks[0].branch"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                          <v-text-field
                            label="Account number ( required )"
                            v-model="form.banks[0].acc"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-right mb-2" cols="12" sm="12">
              <v-btn
                depressed
                :disabled="loading"
                @click="updateProfile()"
                color="primary"
                >Update Profile</v-btn
              >
              <v-btn
                color="#ff0000"
                class="ml-4 cancel__btn"
                depressed
                @click="backToList()"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-container>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <FooterView />
  </v-app>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import Form from "vform";
import setAuthHeader from "@/utils/setAuthHeader";

export default {
  name: "CustomerProfile",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      id: null,
      loading: false,
      form: new Form({
        switch_business_name: false,
        id: "",
        name: "",
        business_name: "",
        display_name: "",
        phone: "",
        whatsapp: "",
        message_method: "BOTH",
        email: "",
        address: "",
        password: null,
        password_confirmation: null,
        banks: [
          {
            name: null,
            bank: null,
            branch: null,
            acc: null,
          },
        ],
      }),
      errorAlertText: "",
      successAlertText: "",
    };
  },
  computed: {
    nameSwitchDisabled() {
      const disabled = !this.form.name || !this.form.business_name;
      if (disabled) {
        this.changeSwitch(false);
      }
      return disabled;
    },
  },
  created() {
    this.setToken();
  },
  mounted() {
    this.getCurrentUserDetails();
  },
  methods: {
    changeBusinessName() {
      if (!this.form.business_name) this.form.switch_business_name = false;
      if (this.form.switch_business_name)
        this.form.display_name = this.form.business_name;
    },
    nameChange() {
      if (!this.form.switch_business_name) this.form.display_name = this.form.name;
    },
    whatsAppChange() {
      if (this.form.whatsapp == "" && this.form.message_method == "WHATSAPP") {
        this.form.message_method = "BOTH";
      }
    },
    changeSwitch(event) {
      this.form.switch_business_name = event;
      if (event) {
        this.form.display_name = this.form.business_name;
      } else {
        this.form.display_name = this.form.name;
      }
    },
    setSwitch() {
      if (
        this.form.business_name &&
        this.form.business_name != "" &&
        this.form.display_name == this.form.business_name
      ) {
        this.form.switch_business_name = true;
      } else {
        this.form.switch_business_name = false;
      }
      this.changeSwitch(this.form.switch_business_name);
    },
    setToken() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      if (user) {
        setAuthHeader(user.token);
      } else {
        setAuthHeader(false);
      }
    },
    backToList() {
      return this.$router.push({ name: "CustomerDashboard" });
    },
    updateProfile() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.form
          .put(this.$hostname + "/api/client/profile/update", this.form)
          .then((response) => {
            let existingUserData = localStorage.getItem("userDetails");
            existingUserData = JSON.parse(existingUserData);
            existingUserData.user.name = response.data.user.name;
            existingUserData.user.switch_business_name = response.data.user.switch_business_name;
            existingUserData.user.display_name = response.data.user.display_name;
            existingUserData.user.whatsapp = response.data.user.whatsapp;
            existingUserData.user.business_name =
              response.data.user.business_name;
            existingUserData.user.name = response.data.user.name;
            existingUserData.user.message_method =
              response.data.user.message_method;
            existingUserData.user.email = response.data.user.email;
            existingUserData.user.address = response.data.user.address;
            existingUserData.user.phone = response.data.user.phone;
            existingUserData.user.banks = response.data.user.banks;
            localStorage.setItem(
              "userDetails",
              JSON.stringify(existingUserData)
            );

            this.showSuccess("Profile updated.", "Updated!");
            this.$router.push({ name: "CustomerDashboard" });
          })
          .catch((error) => {
            this.errorAlertText = "";
            this.errorAlertText = error.response.data.message;
            this.scrollToTop();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getCurrentUserDetails() {
      let user = localStorage.getItem("userDetails");
      user = JSON.parse(user);
      console.log(user);
      if (user) {
        this.form.id = user.user.id;
        this.form.name = user.user.name;
        this.form.email = user.user.email;
        this.form.switch_business_name = user.user.switch_business_name;
        this.form.address = user.user.address ?? "";
        this.form.phone = user.user.phone;
        this.form.whatsapp = user.user.whatsapp;
        this.form.display_name = user.user.display_name ?? "";
        this.form.business_name = user.user.business_name;
        this.form.message_method = user.user.message_method ?? "BOTH";
        if (user.user.banks.length > 0) {
          this.form.banks = user.user.banks;
        }
        this.setSwitch();
      } else {
        this.$router.push({ name: "CustomerDashboard" });
      }
    },
  },
};
</script>
<style scoped>
.cancel__btn {
  color: white;
}

.address_line {
  margin-top: 10px;
  line-height: 5px;
}

.switch-component > .v-label {
  color: black;
  font-weight: 500;
}

.display-name {
  color: rgb(126, 126, 126);
}
</style>
