<template>
    <div>
        <!-- Desktop Start -->
        <v-sheet v-if="windowWidth >= 600" class="mt-0" max-width="100%" color="#212529">
            <v-container>
                <v-row class="mt-10 mb-16">
                    <v-col cols="12" md="3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-img lazy-src="@/assets/image/logo-white.svg" max-height="auto" max-width="70%" src="@/assets/image/logo-white.svg"></v-img>
                            </v-col>
                            <v-col cols="12" md="12">
                                <p class="text-caption ml-5 content-text" >
                                    We are courier company that provides fast and reliable package transport.
                                </p>
                                <v-avatar size="30" class="mr-3 ml-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/facebook-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/facebook-blue.png"
                                        @click="redirectSocialMedia(1)"
                                        style="cursor: pointer"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/insta-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/insta-blue.png"
                                        @click="redirectSocialMedia(2)"
                                        style="cursor: pointer"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/linked-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/linked-blue.png"
                                        @click="redirectSocialMedia(3)"
                                        style="cursor: pointer"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/wa.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/wa.png"
                                        @click="redirectSocialMedia(4)"
                                        style="cursor: pointer"
                                    ></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row class="mt-5">
                            <v-col cols="12" md="12">
                                <p class="text-subtitle-2 font-weight-bold" style="color: #0674ba">SERVICES</p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage1()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Documents & Packages Delivery
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage2()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Same day Pick up & Delivery Services
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage3()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Management of Mail Room
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage4()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Cash on Delivery
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage5()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Confidential Documents Delivery
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectServicePage6()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Confidential Parcel Delivery
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row class="mt-5">
                            <v-col cols="12" md="12">
                                <p class="text-subtitle-2 font-weight-bold" style="color: #0674ba">CUSTOMER CARE</p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectHome()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Home
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectOrderTracking()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Order Tracking
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectBranchNetwork()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Branch Network
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectAboutUs()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> About Us
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectContactUs()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Contact Us
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectFAQ()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> FAQ
                                </p>
                                <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectTermCond()">
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Terms and Conditions
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row class="mt-5">
                            <v-col cols="12" md="12">
                                <p class="text-subtitle-2 font-weight-bold" style="color: #0674ba">CONTACT</p>

                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-img
                                            lazy-src="@/assets/image/location-icon.svg"
                                            max-height="auto"
                                            max-width="60%"
                                            src="@/assets/image/location-icon.svg"
                                        ></v-img>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <p class="text-caption" style="color: #ffffff">
                                            25/13 (339), Wedagewatta Road,Pelengahawatta, Kotuwegoda, Rajagiriya, Sri Lanka
                                        </p>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" md="2">
                                        <v-img
                                            style="padding: 2px"
                                            lazy-src="@/assets/image/call-icon.svg"
                                            max-height="auto"
                                            max-width="40%"
                                            src="@/assets/image/call-icon.svg"
                                        ></v-img>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <p class="text-caption" style="color: #ffffff; cursor: pointer" @click="redirectTel()">0112 878524</p>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" md="2">
                                        <v-img
                                            style="padding: 2px; margin-top: 10px"
                                            lazy-src="@/assets/image/email-icon.svg"
                                            max-height="auto"
                                            max-width="40%"
                                            src="@/assets/image/email-icon.svg"
                                        ></v-img>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <p class="text-caption" style="color: #ffffff; margin-top: 10px; cursor: pointer" @click="redirectMailTo()">
                                            info@falcon.lk
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-sheet v-if="windowWidth >= 600" class="mt-0" max-width="100%" color="#0674BA">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <p class="text-caption mt-5 float-left" style="color: #ffffff">
                            Falcon Express Delivery © 2022. All Rights Reserved | Design & Developed By <a target="_blank" class="outside-link" href="https://mindcode.lk/">MindCode</a>
                        </p>
                    </v-col>
                    <v-col cols="12" md="6">
                        <p class="text-caption mt-5 float-right" style="color: #ffffff">Terms of use | Privacy Policy</p>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <!-- Desktop End -->

        <!-- Mobile Start -->
        <v-sheet v-if="windowWidth <= 600" class="mt-0" max-width="100%" color="#212529">
            <v-container>
                <v-row class="mt-10 mb-16">
                    <v-col cols="12" md="3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-img lazy-src="@/assets/image/logo-white.svg" max-height="auto" max-width="50%" src="@/assets/image/logo-white.svg"></v-img>
                            </v-col>
                            <v-col cols="12" md="12">
                                <p
                                    class="ml-5 content-text"
                                   >
                                    We are courier company that provides <br />
                                    fast and reliable package transport.
                                </p>
                                <v-avatar size="30" class="mr-3 ml-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/facebook-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/facebook-blue.png"
                                        @click="redirectSocialMedia(1)"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/insta-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/insta-blue.png"
                                        @click="redirectSocialMedia(2)"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/linked-blue.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/linked-blue.png"
                                        @click="redirectSocialMedia(3)"
                                    ></v-img>
                                </v-avatar>
                                <v-avatar size="30" class="mr-3">
                                    <v-img
                                        lazy-src="@/assets/image/Home/Icon/wa.png"
                                        max-height="auto"
                                        max-width="100%"
                                        src="@/assets/image/Home/Icon/wa.png"
                                        @click="redirectSocialMedia(4)"
                                    ></v-img>
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row class="mt-5">
                            <v-col cols="12" md="12">
                                <p class="ml-2" style="font-family: 'Rubik-regular'; font-style: normal; font-weight: 600; font-size: 18px; color: #0674ba">
                                    SERVICES
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage1()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Documents & Packages Delivery
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage2()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Same day Pick up & Delivery Services
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage3()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Management of Mail Room
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage4()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Cash on Delivery
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage5()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Confidential Documents Delivery
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectServicePage6()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Confidential Parcel Delivery
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <p class="ml-2" style="font-family: 'Rubik-regular'; font-style: normal; font-weight: 600; font-size: 18px; color: #0674ba">
                                    CUSTOMER CARE
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectOrderTracking()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Order Tracking
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectBranchNetwork()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Branch Network
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectAboutUs()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> About Us
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectContactUs()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> Contact Us
                                </p>
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                    @click="redirectFAQ()"
                                >
                                    <v-icon small color="#0674BA">mdi-circle-medium</v-icon> FAQ
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row style="margin-top: -60px">
                    <v-col cols="12" sm="12">
                        <p class="ml-2" style="font-family: 'Rubik-regular'; font-style: normal; font-weight: 600; font-size: 18px; color: #0674ba">CONTACT</p>

                        <v-row>
                            <v-col cols="auto" sm="2">
                                <v-img
                                    style="margin-left: 10px"
                                    lazy-src="@/assets/image/location-icon.svg"
                                    max-height="auto"
                                    max-width="100%"
                                    src="@/assets/image/location-icon.svg"
                                ></v-img>
                            </v-col>
                            <v-col cols="auto" sm="8">
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                    "
                                >
                                    25/13 (339), Wedagewatta Road, <br />
                                    Pelengahawatta, Kotuwegoda, Rajagiriya, <br />
                                    Sri Lanka
                                </p>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="auto" md="2">
                                <v-img
                                    style="margin-left: 10px"
                                    lazy-src="@/assets/image/call-icon.svg"
                                    max-height="auto"
                                    max-width="100%"
                                    src="@/assets/image/call-icon.svg"
                                ></v-img>
                            </v-col>
                            <v-col cols="auto" md="10">
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                        cursor: pointer;
                                    "
                                    @click="redirectTel()"
                                >
                                    0112 878524
                                </p>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="auto" md="2">
                                <v-img
                                    style="margin-left: 10px"
                                    lazy-src="@/assets/image/email-icon.svg"
                                    max-height="auto"
                                    max-width="100%"
                                    src="@/assets/image/email-icon.svg"
                                ></v-img>
                            </v-col>
                            <v-col cols="auto" md="10">
                                <p
                                    style="
                                        color: #ffffff;
                                        cursor: pointer;
                                        font-family: 'SF Pro Display';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 15px;
                                        letter-spacing: 0.8px;
                                        cursor: pointer;
                                    "
                                    @click="redirectMailTo()"
                                >
                                    info@falcon.lk
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-sheet v-if="windowWidth <= 600" max-width="100%" color="#0674BA" class="text-center pt-10">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <p style="color: #ffffff; font-family: 'SF Pro Display'; font-style: normal; font-weight: 500; font-size: 15px; letter-spacing: 0.8px">
                            Falcon Express Delivery © 2022. <br />
                            All Rights Reserved <br />
                            Design & Developed By <a target="_blank" class="outside-link" href="https://mindcode.lk/">MindCode</a>
                        </p>
                    </v-col>
                    <v-col cols="12" md="12">
                        <hr class="ml-16 mr-16" />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <p style="color: #ffffff; font-family: 'SF Pro Display'; font-style: normal; font-weight: 500; font-size: 15px; letter-spacing: 0.8px">
                            Terms of use | Privacy Policy
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <!-- Mobile End -->
    </div>
</template>

<script>
import social_links from '@/config';

export default {
    name: "FooterView",

    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    mounted() {},
    created() {
        window.addEventListener("resize", this.onResize);
    },
    methods: {
        redirectServicePage1() {
            this.$router.push({ name: "DocumentPackageDelivery" });
        },

        redirectServicePage2() {
            this.$router.push({ name: "SamedayPickupDeliveryServices" });
        },

        redirectServicePage3() {
            this.$router.push({ name: "ManagementofMailRoom" });
        },

        redirectServicePage4() {
            this.$router.push({ name: "CashOnDelivery" });
        },

        redirectServicePage5() {
            this.$router.push({ name: "ConfidentialDocumentsDelivery" });
        },

        redirectServicePage6() {
            this.$router.push({ name: "ConfidentialParcelDelivery" });
        },

        redirectHome() {
            this.$router.push({ name: "home" });
        },
       
       redirectOrderTracking() {
            this.$router.push({ name: "OrderTrack" });
        },

        redirectBranchNetwork() {
            this.$router.push({ name: "BranchNetwork" });
        },

        redirectContactUs() {
            this.$router.push({ name: "ContactUs" });
        },

        redirectAboutUs() {
            this.$router.push({ name: "AboutUs" });
        },

        redirectFAQ() {
            this.$router.push({ name: "FAQDetails" });
        },

        redirectTermCond() {
            this.$router.push({ name: "TermAndCondition" });
        },
        redirectSocialMedia(number) {
            switch (number) {
                case 1:
                    window.open(social_links.facebook, "_blank");
                    break;
                case 2:
                    window.open("https://www.instagram.com/?hl=en", "_blank");
                    break;
                case 3:
                    window.open("https://lk.linkedin.com/", "_blank");
                    break;
                case 4:
                    window.open("https://wa.me/+94772576600/", "_blank");
                    break;

                default:
                    break;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        redirectMailTo() {
            window.location.href = "mailto:info@falcondelivery.com";
        },
        redirectTel() {
            window.location.href = "tel:0123526589";
        },
    },
};
</script>
<style scoped>

.content-text {
 font-family:'SF Pro Display'; 
 color:rgb(255, 255, 255);
 font-weight: 400;
 font-size: 16px; 
 letter-spacing: 0.8px;
}

.outside-link{
    color: rgb(255, 255, 255);
    text-decoration: none;
}
</style>