<template>
  <div>
    <AppBar />
    <v-container class="mt-16 mb-5 text-center">
      <v-row style="padding-top: 10px" align="center">
        <v-col cols="12" md="12">
          <p
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 50px;
              margin-bottom: -15px;
            "
          >
            Privacy & Policy
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mb-16" style="width: 100%">
      <v-row>
        <v-col cols="12" md="12">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title"> Introduction</v-list-item-title>
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  At Falcon Express Ceylon, We are committed to protect your
                  privacy. This policy explains that why we collect personal
                  information about people who visit our website, how we use it,
                  the conditions under which we may disclose it to others and
                  keep those information securely. <br /><br />
                  It applies to all the information that we collect:
                  <ul>
                    <li>Visitors to our website</li>
                    <li>
                      Complainants and other individuals in relation to and
                      inquiry
                    </li>
                    <li>People who use our services.</li>
                  </ul>
                  <br /><br />
                  We may change this policy from time to time so please make
                  sure to check the changes occasionally to ensure that you are
                  happy with the changes. By using our website you’re agreeing
                  to be bound by this policy.<br /><br />

                  Any questions regarding this policy and our privacy practices
                  should be send to the contact information provided at the end
                  of this policy.<br /><br />

                  Please be aware that, in all circumstances, we will never
                  collect sensitive information about you. The personal
                  information which we hold will be held securely in accordance
                  with our internal security policy and the law.
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                Visitors to our website</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  We do not attempt to find out the identities of those who
                  visiting our website
                  <a href="https://falcon.lk">falcon.lk</a>. We will make it
                  clear when we collect personal information and explain what
                  purpose that we collect.
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                People who email us</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  Any email sent us, any attachments, may be monitored and used by us for reasons of security and for monitoring compliance with office policy. Make sure that any email that you send to us is within the bound of law.
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                People who make a complaint and inquire with us</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  When we receive a complaint form a person we make up a file containing the detail of the complaint. We only use the personal information to process the complaint.
Similarly, where inquiries are submitted to us we will only use the information supplied to deal with inquiry and any subsequent issues.

                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
         
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                Who has access to your information?</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  We will not share your information with third parties. 
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                Security</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  You are eligible to create your password at the time you sign up to the website and you are responsible to keep this password confidential and not share with anyone.
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                How to contact us</v-list-item-title
              >
              <v-list-item-subtitle class="t-and-condition">
                <span class="content-text">
                  If you need more clarification or information about our privacy policy please email us to <a href="mailto:info@falcon.lk">info@falcon.lk</a>
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>


        </v-col>
      </v-row>
    </v-container>
    <FooterView />
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import FooterView from "./FooterView.vue";
export default {
  name: "TermAndCondition",
  components: {
    AppBar,
    FooterView,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>
<style>
@font-face {
  font-family: Rubik;
  src: url(../../public/fonts/Rubik/Rubik-SemiBold.ttf);
}
@font-face {
  font-family: Rubik-Regular;
  src: url(../../public/fonts/Rubik/Rubik-Regular.ttf);
}
@font-face {
  font-family: SF Pro Display;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Thin.otf);
}
@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../../public/fonts/SF Pro Display/SF-Pro-Display-Semibold.otf);
}

.t-and-condition.v-list-item__subtitle {
  text-overflow: initial !important;
  white-space: inherit !important;
  margin-top: 10px !important;
}

.title {
  color: #0674ba;
  font-size: 2rem;
}

.content-text {
  font-weight: 700;
  color: black;
}
</style>
