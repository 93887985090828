<template>
  <div>
    <AppBar />
    <!-- Desktop Start  -->
    <v-sheet
      v-if="windowWidth >= 600"
      class="serviceBackgroundImg3"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Management of Mail Room</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> Management
              of Mail Room
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Desktop End  -->

    <!-- Mobile Start  -->
    <v-sheet
      v-if="windowWidth <= 600"
      class="serviceBackgroundImg3"
      max-width="100%"
      max-height="800"
      color="#112548"
      style="padding-top: 120px; padding-bottom: 120px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <p class="text-uppercase section-title">Management of Mail Room</p>
          </v-col>
          <v-col cols="12" md="12">
            <p class="text-uppercase sub-title" style="margin-top: -20px">
              Home
              <v-icon color="white" small>mdi-chevron-right</v-icon> Management
              of Mail Room
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- Mobile End  -->

    <!-- Desktop Start -->
    <v-container v-if="windowWidth >= 600" class="mt-16 mb-16">
      <v-row>
        <v-col cols="12" md="4">
          <OurMainServicesSidePanelDesktop />

          <DownloadsDesktop />

          <ContactInfo />
        </v-col>
        <v-col cols="12" md="8">
          <v-img
            class="rounded-xl"
            lazy-src="@/assets/image/service-3-img.jpg"
            max-height="auto"
            max-width="100%"
            style="background-position: center"
            src="@/assets/image/service-3-img.jpg"
          ></v-img>

          <p class="font-weight-bold text-h4 mt-10" style="color: #112548">
            Management of Mail Room
          </p>

          <p class="content-text mt-10">
            Most of the organisations have day to day mail to be delivered but
            it is unnecessary to keep a separate unit or a person for their mail
            delivery operation and it is not their core business. Therefore they
            are always willing to take the service from a specialist in the
            industry and it’ll be cost effective for them. This will enable and
            ensure the efficiency and productivity of the mail management
            process of the organization. Since that customer has hired
            specialists for this the communication objectives of the
            organization will definitely improve.<br /><br />
            In this service we assign our agents for the requested location to
            manage the entire process as requested by the customer. All the
            documents and packages that the customer is willing to send will be
            collected by the agent and sorted and delivered by prioritizing the
            requirement of the customer.
            <br /><br />
            Here the charges will be different from the normal delivery system
            and the man power charges will be added as per the agreement. For
            each and every delivery we do update the status of the delivery to
            the online system until it reaches the destination.
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-3-sub-img-1.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-3-sub-img-1.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-3-sub-img-2.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-3-sub-img-2.jpg"
              ></v-img>
            </v-col>
          </v-row>

          <IconsPanel />
        </v-col>
      </v-row>
    </v-container>
    <!-- Desktop End -->

    <!-- Mobile Start -->
    <v-container v-if="windowWidth <= 600" class="mt-16 mb-16">
      <v-row>
        <v-col cols="12" md="8">
          <v-img
            class="rounded-xl"
            lazy-src="@/assets/image/service-3-img.jpg"
            style="background-position: center; height: 415px"
            src="@/assets/image/service-3-img.jpg"
          ></v-img>

          <p class="font-weight-bold text-h4 mt-10" style="color: #112548">
            Management of Mail Room
          </p>

          <p class="mt-10 content-text">
            Most of the organisations have day to day mail to be delivered but
            it is unnecessary to keep a separate unit or a person for their mail
            delivery operation and it is not their core business. Therefore they
            are always willing to take the service from a specialist in the
            industry and it’ll be cost effective for them. This will enable and
            ensure the efficiency and productivity of the mail management
            process of the organization. Since that customer has hired
            specialists for this the communication objectives of the
            organization will definitely improve. <br /><br />
            In this service we assign our agents for the requested location to
            manage the entire process as requested by the customer. All the
            documents and packages that the customer is willing to send will be
            collected by the agent and sorted and delivered by prioritizing the
            requirement of the customer. <br /><br />
            Here the charges will be different from the normal delivery system
            and the man power charges will be added as per the agreement. For
            each and every delivery we do update the status of the delivery to
            the online system until it reaches the destination.
          </p>
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-3-sub-img-1.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-3-sub-img-1.jpg"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                class="rounded-xl"
                lazy-src="@/assets/image/service-3-sub-img-2.jpg"
                max-height="auto"
                max-width="100%"
                style="background-position: center"
                src="@/assets/image/service-3-sub-img-2.jpg"
              ></v-img>
            </v-col>
          </v-row>
          <br />
          <IconsPanel />
        </v-col>
        <v-col cols="12" md="4">
          <OurMainServiceSidePanelMobile />

          <DownloadsMobile />

          <ContactInfoMobile />
        </v-col>
      </v-row>
    </v-container>
    <!-- Mobile End -->
    <FooterView />
  </div>
</template>

<script>
import AppBar from "../AppBar.vue";
import FooterView from "../FooterView.vue";
import OurMainServiceSidePanelMobile from "./OurMainServiceSidePanelMobile.vue";
import OurMainServicesSidePanelDesktop from "./OurMainServicesSidePanelDesktop.vue";
import DownloadsDesktop from "./DownloadsDesktop.vue";
import DownloadsMobile from "./DownloadsMobile.vue";
import IconsPanel from "./IconsPanel.vue";
import ContactInfo from "./ContactInfo.vue";
import ContactInfoMobile from "./ContactInfo.vue";
import social_links from "@/config";
export default {
  name: "ManagementofMailRoom",
  components: {
    AppBar,
    FooterView,
    OurMainServiceSidePanelMobile,
    OurMainServicesSidePanelDesktop,
    DownloadsDesktop,
    DownloadsMobile,
    IconsPanel,
    ContactInfo,
    ContactInfoMobile
},

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    redirectToServicePage(pageNo) {
      switch (pageNo) {
        case 1:
          this.$router.push({ name: "DocumentPackageDelivery" });
          break;
        case 2:
          this.$router.push({ name: "SamedayPickupDeliveryServices" });
          break;
        case 3:
          this.$router.push({ name: "ManagementofMailRoom" });
          break;
        case 4:
          this.$router.push({ name: "CashOnDelivery" });
          break;
        case 5:
          this.$router.push({ name: "ConfidentialDocumentsDelivery" });
          break;
        case 6:
          this.$router.push({ name: "ConfidentialParcelDelivery" });
          break;

        default:
          break;
      }
    },
    redirectSocialMedia(number) {
      switch (number) {
        case 1:
          window.open(social_links.facebook, "_blank");
          break;
        case 2:
          window.open("https://www.instagram.com/?hl=en", "_blank");
          break;
        case 3:
          window.open("https://lk.linkedin.com/", "_blank");
          break;
        case 4:
          window.open("https://www.youtube.com/", "_blank");
          break;

        default:
          break;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    redirectMailTo() {
      window.location.href = "mailto:info@falcondelivery.com";
    },
    redirectTel() {
      window.location.href = "tel:0123526589";
    },
  },
};
</script>
<style>
.serviceBackgroundImg3 {
  background-image: url("@/assets/image/Mask-service-3.jpg");
  background-color: transparent;
  background-position: center;
  justify-content: center;
  background-size: cover;
}
</style>
